const getColorByLabel = (variant) => {
  let statusTextColor;
  let statusBackgroundColor;
  switch (variant) {
    case "Error":
      statusTextColor = "#E01507";
      statusBackgroundColor = "#E015071A";
      break;
    case "Success":
      statusTextColor = "#13B619";
      statusBackgroundColor = "#13B6191A";
      break;
    case "Info":
      statusTextColor = "#0671E0";
      statusBackgroundColor = "#0671E01A";
      break;
    case "Warning":
      statusTextColor = "#E48900";
      statusBackgroundColor = "#E489001A";
      break;
    default:
      statusTextColor = "#03CA17";
      statusBackgroundColor = "#03CA171A";
  }
  return { statusTextColor, statusBackgroundColor };
};

export { getColorByLabel };
