import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
    Button,
    TextField as InputText,
    Step,
    StepLabel,
    Stepper
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    defaultButton: {
        textTransform: 'none',
        backgroundColor: "#f2f2f2",
        color: "#black",
        '&:hover': {
            backgroundColor: "#fbfdfe",
            color: "black",
        },
        marginRight: '1%',
        width: 'auto',
        height: '80%'
    },
    printButton: {
        textTransform: 'none',
        backgroundColor: "#5dcd78",
        color: "white",
        '&:hover': {
            backgroundColor: "#d5f2dc",
            color: "black",
        },
        marginRight: '1%',
        width: 'auto',
        height: '80%'
    },
    colorButton: {
        textTransform: 'none',
        backgroundColor: "#7fbaf0",
        color: "#fff",
        '&:hover': {
            backgroundColor: "#f2f2f2",
            color: "black",
        },
        marginRight: '1%',
        width: 'auto',
        height: '80%'
    },

}));

const MaterialStatus = [
    "Draft",
    "Submitted for Approval",
    "Submitted",
    "Reviewed",
    "Approved",
    "Rejected",
    "Closed"
];
const BOQstatus = [
    "Draft",
    "Submitted for Approval",
    "Submitted",
    "Reviewed",
    "Approved",
    "Rejected",
    "Closed"
];
const Orderstatus = [
    "Draft",
    "Submitted for Approval",
    "Approved",
    "Rejected",
    "Issued",
    "Acknowledged",
    "Partially Fulfilled",
    "Fully Fulfilled",
    "Closed",
    "Cancelled"
];
const Invoicestatus = [
    "Draft",
    "Submitted",
    "Under Review",
    "Approved for Payment",
    "Rejected",
    "Partially Paid",
    "Paid in Full",
    "Closed"
];


const BillStepper = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        opsTypes,
        activeOpsType,
        setActiveOpsType,
        handleMaterialRequest,
        handlePrint
    } = props;
    //stepper
    // const [Steps, setStep] = useState(types);
    const [activeStatus, setActiveStatus] = useState("");

    const handleChange = (status, type) => {
        setActiveStatus(status)
        if (activeOpsType === "Material Request") {
            if (status === "Approved") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 1];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
        }
        else if (activeOpsType === "Bill of Quantities") {
            if (status === "Purchase Order") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 1];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
            else if (status === "Work Order") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 2];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
        }
        else if (activeOpsType === "Purchase Order") {
            if (status === "Partially Fulfilled") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 2];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
            else if (status === "Fully Fulfilled") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 2];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
        }
        else if (activeOpsType === "Work Order") {
            if (status === "Partially Fulfilled") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 1];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
            else if (status === "Fully Fulfilled") {
                const currentIndex = opsTypes.indexOf(type);
                const nextOpsType = opsTypes[currentIndex + 1];
                setActiveOpsType(nextOpsType)
                setActiveStatus("")
            }
        }
    }

    useEffect(() => {

    }, [])
    //stepper
    return (
        <div className={classes.root}>
            {activeOpsType === "Material Request" && (
                <>
                    <Button
                        className={classes.printButton}
                    >
                        Print
                    </Button>

                    {activeStatus === "" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Draft", activeOpsType)}
                        >
                            Draft
                        </Button>
                    )}

                    {activeStatus === "Draft" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Submitted for Approval", activeOpsType)}
                        >
                            Submitted for Approval
                        </Button>
                    )}

                    {activeStatus === "Submitted for Approval" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Submit", activeOpsType)}
                        >
                            Submit
                        </Button>
                    )}

                    {activeStatus === "Submit" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Reviewed", activeOpsType)}
                        >
                            Reviewed
                        </Button>
                    )}

                    {activeStatus === "Reviewed" &&
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => { handleChange("Approved", activeOpsType) }}
                            >
                                Approved
                            </Button>

                            <Button
                                className={classes.defaultButton}
                                onClick={() => handleChange("Rejected", activeOpsType)}
                            >
                                Rejected
                            </Button>
                        </>
                    }
                </>
            )}

            {activeOpsType === "Bill of Quantities" && (
                <>
                    <Button
                        className={classes.printButton}
                    >
                        Print
                    </Button>

                    {activeStatus === "" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Draft", activeOpsType)}
                        >
                            Draft
                        </Button>
                    )}

                    {activeStatus === "Draft" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Submitted for Approval", activeOpsType)}
                        >
                            Submitted for Approval
                        </Button>
                    )}

                    {activeStatus === "Submitted for Approval" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Submit", activeOpsType)}
                        >
                            Submit
                        </Button>
                    )}

                    {activeStatus === "Submit" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Evaluated", activeOpsType)}
                        >
                            Evaluated
                        </Button>
                    )}

                    {activeStatus === "Evaluated" &&
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => { handleChange("Approved", activeOpsType) }}
                            >
                                Approved
                            </Button>

                            <Button
                                className={classes.defaultButton}
                                onClick={() => handleChange("Rejected", activeOpsType)}
                            >
                                Rejected
                            </Button>
                        </>
                    }

                    {activeStatus === "Approved" &&
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => { handleChange("Purchase Order", activeOpsType) }}
                            >
                                Purchase Order
                            </Button>

                            <Button
                                className={classes.defaultButton}
                                onClick={() => handleChange("Work Order", activeOpsType)}
                            >
                                Work Order
                            </Button>
                        </>
                    }

                    <Button
                        className={classes.defaultButton}
                        onClick={() => handleChange("Cancelled", activeOpsType)}
                    >
                        Cancel
                    </Button>

                </>
            )}


            {(activeOpsType === "Purchase Order" || activeOpsType === "Work Order") && (
                <>
                    <Button
                        className={classes.printButton}
                    >
                        Print
                    </Button>

                    {activeStatus === "" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Draft", activeOpsType)}
                        >
                            Draft
                        </Button>
                    )}

                    {activeStatus === "Draft" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Submitted for Approval", activeOpsType)}
                        >
                            Submitted for Approval
                        </Button>
                    )}

                    {activeStatus === "Submitted for Approval" &&
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => { handleChange("Approved", activeOpsType) }}
                            >
                                Approved
                            </Button>

                            <Button
                                className={classes.defaultButton}
                                onClick={() => handleChange("Rejected", activeOpsType)}
                            >
                                Rejected
                            </Button>
                        </>
                    }

                    {activeStatus === "Approved" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Issued", activeOpsType)}
                        >
                            Issued
                        </Button>
                    )}

                    {activeStatus === "Issued" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Acknowledged", activeOpsType)}
                        >
                            Acknowledged
                        </Button>
                    )}

                    {activeStatus === "Acknowledged" && (
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => handleChange("Partially Fulfilled", activeOpsType)}
                            >
                                Partially Fulfilled
                            </Button>

                            <Button
                                className={classes.colorButton}
                                onClick={() => handleChange("Fully Fulfilled", activeOpsType)}
                            >
                                Fully Fulfilled
                            </Button>
                        </>
                    )}

                    {activeStatus === "Partially Fulfilled" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Fully Fulfilled", activeOpsType)}
                        >
                            Fully Fulfilled
                        </Button>
                    )}

                    {activeStatus === "Fully Fulfilled" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Closed", activeOpsType)}
                        >
                            Closed
                        </Button>
                    )}

                </>
            )}

            {activeOpsType === "Invoice" && (
                <>
                    <Button
                        className={classes.printButton}
                    >
                        Print
                    </Button>

                    {activeStatus === "" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Draft", activeOpsType)}
                        >
                            Draft
                        </Button>
                    )}

                    {activeStatus === "Draft" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Submitted", activeOpsType)}
                        >
                            Submitted
                        </Button>
                    )}

                    {activeStatus === "Under Review" &&
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => { handleChange("Approved for Payment", activeOpsType) }}
                            >
                                Approved for Payment
                            </Button>

                            <Button
                                className={classes.defaultButton}
                                onClick={() => handleChange("Rejected", activeOpsType)}
                            >
                                Rejected
                            </Button>
                        </>
                    }

                    {activeStatus === "Approved for Payment" && (
                        <>
                            <Button
                                className={classes.colorButton}
                                onClick={() => handleChange("Partially Paid", activeOpsType)}
                            >
                                Partially Paid
                            </Button>

                            <Button
                                className={classes.colorButton}
                                onClick={() => handleChange("Paid in Full", activeOpsType)}
                            >
                                Paid in Full
                            </Button>
                        </>
                    )}

                    {activeStatus === "Partially Paid" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Paid in Full", activeOpsType)}
                        >
                            Paid in Full
                        </Button>
                    )}

                    {activeStatus === "Paid in Full" && (
                        <Button
                            className={classes.colorButton}
                            onClick={() => handleChange("Closed", activeOpsType)}
                        >
                            Closed
                        </Button>
                    )}

                </>
            )}
        </div>
    )
}
export default BillStepper;