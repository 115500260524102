import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Paper,
  Modal,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField as InputText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { DataGrid } from "@mui/x-data-grid";
import Api from "../../../helpers/Api";
import PropTypes from "prop-types";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ScreenShareOutlinedIcon from "@material-ui/icons/ScreenShareOutlined";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import BillListTable from "./BillListTable";
import {
  Archive,
  ChevronRight,
  Delete,
  Edit,
  KeyboardArrowDown,
  StarBorderOutlined,
  Close as CloseIcon,
  PlaylistAddCheck as PlayListIcon,
  Delete as RemoveIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";
import { Grid, Menu, MenuItem, TextField, alpha, styled } from "@mui/material";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import FinanceRelationDialog from "./FinanceRelationDialog";
import { updateTx } from "../../finance2o/commonComponent/transaction/api";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import BudgetPicker from "./BudgetPicker";
import MyAutocomplete from "../../styled/CommonComponents/MyAutoComplete"
import BillStepper from "./BillStepper";
import OpsOrderSearchDialog from "./ops.order.search.dialog";
import ReactToPrint from 'react-to-print';
import BudgetUpdate from "../../budgetCostAnalysis/BudgetUpdate";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import OpsOrderStatus from "./OpsOrderStatus"
import { getOpsOrderById } from "../../budgetCostAnalysis/api.call";
const typeArr = ["Product", "Service", "Package"];
const subTypeArr = [
  "Material",
  "Material + Labour",
  "Labour",
  "Services",
  "Subcontract",
];
const tableColumn = [
  "Item Type",
  "Taxes",
  "Tax incl.",
  "Tax excl."
];
const opsTypes = [
  "Material Request",
  "Bill Of Quantities",
  "Purchase Order",
  "Work Order",
  "Invoice"
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    padding: "0px 5px",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },

  bodyCont: {
    width: "100%",
    height: "calc(100vh - 130px)",
    overflow: "auto",
    padding: "15px",
    position: "relative",
  },
  Stepperdiv: {
    width: "auto",
    padding: "2vh 2vw",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      padding: '2vh 1vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    }
  },
  listTableSty: {
    width: "100%",
    border: "none",
    borderCollapse: "collapse",
    padding: "0px",
    fontSize: "0.9rem",
    color: "#27272a",
    fontWeight: 400,
    marginTop: "15px",
    "& tr": {
      height: "40px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
      textAlign: "left",
    },
  },
  loaderCont: {
    position: "fixed",
    top: "0px",
    right: "0px",
    width: "100vw",
    height: "100vh",
    zIndex: "100",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listCardCont: {
    marginTop: "0.7%",
    width: "100%",
    padding: "15px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  optionsCont: {
    padding: "5px",
  },
  dropDownbutton: {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  arrowIcon: {
    marginLeft: "1px",
  },
  singleOption: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    // "& img": {
    //   width: "20px",
    //   height: "auto",
    //   marginRight: "10px",
    // },
    // "& p": {
    //   fontSize: "15px",
    //   fontWeight: "500",
    // },
    // "&:hover": {
    //   backgroundColor: "#e2e2e2",
    // },
  },
  //Send mail
  // mailmodal: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //   overflow: 'auto', /* Add scroll for smaller screens */
  // },

  // sendMailCard: {
  //   width: '80%', /* Adjust the width */
  //   maxWidth: 1000, /* Set a maximum width for the card */
  //   position: 'relative', /* Allow z-index to work */
  // },

  // modalHeader: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  //   padding: '8px 16px', /* Add some padding */
  //   backgroundColor: '#f0f0f0', /* Background color for header */
  // },

  // modalTitle: {
  //   flexGrow: 1,
  // },

  // closeButton: {
  //   marginLeft: '8px',
  // },

  // inputContainer: {
  //   // display: 'flex',
  //   // alignItems: 'center',
  //   marginTop: '16px',

  // },

  // modalActions: {
  //   justifyContent: 'flex-start', /* Align actions to the right */
  //   padding: '16px',
  // },
  //send mail
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const OpsOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const componentRef = useRef();
  const BudgetUpdateRef = useRef(null);
  const { billListId, opsOrderId } = useParams();

  const { user } = useSelector((state) => state.auth);

  const { handlePrint } = props;
  const [getOpsOrderLoader, setGetOpsOrderLoader] = useState(false)
  const [opsOrderName, setOpsOrderName] = useState("Untitled");
  const [project, setProject] = useState();
  const [projectProfileId, setProjectProfileId] = useState();
  const [organization, setOrganization] = useState();
  const [opsOrder, setOpsOrder] = useState();
  const [showCreateVendorDialog, setShowCreateVendorDialog] = useState(false);
  const [txLoading, setTxLoading] = useState(false);
  const [tx, setTx] = useState();
  const [showBudgetPicker, setShowBudgetPicker] = useState(false);
  const [openUpdateBudget, setOpenUpdateBudget] = useState(false)
  const [loading, setLoading] = useState(false);
  const [orderDeadline, setOrderDeadline] = useState(new Date());
  const [expectedArrival, setExpectedArrival] = useState(new Date());
  const [activeOpsType, setActiveOpsType] = useState("");
  const [opsOrderStatus, setOpsOrderStatus] = useState("");
  const [showConfDialog, setShowConfDialog] = useState(false);
  const [allTotalBillDetails, setTotalBillDetails] = useState(null);
  const [OpsOrdersWithTypes, setOpsOrdersWithTypes] = useState([]);
  const [opsOrderSearchDialog, setOpsOrderSearchDialog] = useState(false);
  const [referenceOpsOrders, setReferenceOpsOrders] = useState([]);
  const [type, setType] = useState(typeArr[0]);
  const [subType, setSubType] = useState(subTypeArr[0]);
  const [subTypeText, setSubTypeText] = useState(subTypeArr[0]);
  const [showTableColumn, setTableColumn] = useState(tableColumn);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedBudgetId, setSelectedBudgetId] = useState([])
  const [filterToGetBudgetUpdateItems, setFilterToGetBudgetUpdateItems] = useState({})
  const [projectId, setProjectId] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [projectBlockId, setProjectBlockId] = useState(null)
  const [loadingBool, setLoadingBool] = useState(false);
  const [curParentStatus, setCurParentStatus] = useState("")

  const handleChangeOrderDeadline = (date) => {
    if (date < expectedArrival) {
      setOrderDeadline(date);
    } else {
      alert("Order Deadline must be earlier than Expected Arrival.");
    }
  };

  const handleChangeExpectedArrival = (date) => {
    if (date > orderDeadline) {
      setExpectedArrival(date);
    } else {
      alert("Expected Arrival must be later than Order Deadline.");
    }
  };

  const getOpsOrder = async () => {
    setGetOpsOrderLoader(true)
    await getOpsOrderById({ opsOrderId: opsOrderId })
      .then(async (data) => {
        console.log(data)
        setOpsOrder(data);
        setActiveOpsType(data?.type)
        setOpsOrderStatus(data?.status)
        setOpsOrderName(data.name || "Untitled");
        setProject(data.project);
        setProjectProfileId(data.project.profile);
        setOrganization(data.organization);
        setSelectedBudgetId(data?.selectedBudgetIds || []);
        setGetOpsOrderLoader(false)
        setFilterToGetBudgetUpdateItems({
          opsOrder: opsOrderId
        })
        setCurParentStatus(data?.status || "Draft")
        setProjectId(data?.project?._id)
        setOrganizationId(opsOrder?.organization?._id)
        setProjectBlockId(opsOrder?.projectBlock?._id)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to fetch ops order",
          },
        });
      })
  };

  useEffect(() => {
    if (opsOrderId) {
      getOpsOrder();
    }
  }, [opsOrderId]);

  const updateOpsOrder = async () => {
    try {
      if (!project || !project._id) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please choose a project",
          },
        });
        return;
      }
      if (!organization || !organization._id) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please choose a project",
          },
        });
        return;
      }

      const { data } = await Api.post("/procurement/ops/update", {
        id: opsOrderId,
        newObj: {
          name: opsOrderName,
          project: project._id,
          organization: organization._id,
        },
      });
      history.push("/procurements");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Updated successful",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while updating",
        },
      });
    }
  };

  const deleteOpsOrder = async () => {
    try {
      const { data } = await Api.post("/procurement/ops/delete", {
        opsOrderId: opsOrderId,
      });
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ops order deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while deleting ops order",
        },
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenOpsOrderSearchDialog = () => {
    setOpsOrderSearchDialog(true);
  };

  const handleCloseOpsOrderSearchDialog = () => {
    setOpsOrderSearchDialog(false);
  };

  const UpdateOpsOrderStatus = async () => {
    try {
      await Api.post("/procurement/ops/UpdateOpsOrderStatus", {
        opsOrderId: opsOrderId,
        opsStatus: opsOrderStatus
      });
    } catch (err) {
      console.log(err);
    };
  };

  useEffect(() => {
    UpdateOpsOrderStatus();
  }, [opsOrderStatus])

  const createOpsOrderRequestWithType = async () => {
    const RequestDetails = {
      type: activeOpsType,
      status: opsOrderStatus,
      user: user._id,
      organization: organization?._id,
      project: project?._id,
      vendor: tx?.secondParty?._id,
      vendorName: tx?.secondParty?.parent.displayName,
      orderDeadline: orderDeadline,
      expectedArrival: expectedArrival,
      untaxedAmountVal: allTotalBillDetails.untaxedAmountVal,
      taxObj: allTotalBillDetails.taxesList,
      totalAmountVal: allTotalBillDetails.totalAmountVal,
    }
    const BillDetails = {
      billitems: allTotalBillDetails.billitems,
    }

    const res = await Api.post("procurement/ops/createOpsOrderWithType", {
      allTotalBillDetails: BillDetails,
      RequestDetails: RequestDetails,
      refOpsOrders: referenceOpsOrders
    });

    if (res?.data) {
      console.log("create Ops Order with Type", res?.data)
    }
  };

  useEffect(() => {
    switch (subType) {
      case "Material":
        setType(typeArr[0]);
        break;
      case "Material + Labour":
        setType(typeArr[1]);
        break;
      case "Labour":
        setType(typeArr[2]);
        break;
      case "Services":
        setType(typeArr[2]);
        break;
      case "Subcontract":
        setType(typeArr[2]);
        break;
    }
  }, [subType]);

  const handleChangeTableColumn = async (event, values) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setTableColumn((prevSelected) => [...prevSelected, values]);
    } else {
      setTableColumn((prevSelected) =>
        prevSelected.filter((item) => item !== values)
      );
    }
  };

  const handleDeletereRerenceOpsOrders = async (values) => {
    setReferenceOpsOrders((prevSelected) =>
      prevSelected.filter((item) => item !== values)
    );
  };

  const addNewSelectedItems = (items) => {
    const updatedOpsOrder = {
      ...opsOrder,
      selectedBudgetIds: items
    }
    setOpsOrder(updatedOpsOrder)
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            {/* <KeyboardBackspaceIcon className={classes.backArrow} /> */}
            <ArrowBackIosIcon className={classes.backArrow} />
          </IconButton>
          <Typography className={classes.headerTitle}>
            {/* {opsOrder?.orderCount ? `#${opsOrder.orderCount}` : ""} */}
            {/* {opsOrder?.orderCount
              ? formattedDate +
              `/#${opsOrder.orderCount.toString().padStart(2, "0")}`
              : ""} */}
            {opsOrderName}
          </Typography>
        </div>
        <div className={classes.barRight}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenUpdateBudget(true);
            }}
            style={{ marginRight: "10px" }}
          >
            update budget
          </Button>
          <LoadingButton
            loading={loading}
            variant="outlined"
            disabled
            style={{ marginRight: "10px" }}
          >
            {loading ? "Syncing..." : "Synced"}
          </LoadingButton>
          {/* <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
            color="primary"
          >
            PUBLISH
          </Button> */}

          <Button
            id="demo-customized-button"
            variant="outlined"
            onClick={handleClick}
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            className={classes.dropDownbutton}
            endIcon={<ArrowDropDownIcon />}
          >
            <span style={{ marginLeft: '8px' }}> {opsOrderStatus ? opsOrderStatus : "STATUS"}</span>
          </Button>
          {/* <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                updateOpsOrder();
                handleClose();
              }}
              disableRipple
            >
              <Archive />
              Save
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowConfDialog(true);
                handleClose();
              }}
              disableRipple
            >
              <Delete />
              Delete
            </MenuItem>
          </StyledMenu> */}
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <OpsOrderStatus
              opsTypes={opsTypes}
              activeOpsType={activeOpsType}
              setActiveOpsType={setActiveOpsType}
              opsOrderStatus={opsOrderStatus}
              setOpsOrderStatus={setOpsOrderStatus}
            />
          </StyledMenu>
        </div>
      </div>

      <div className={classes.Stepperdiv}>
        <BillStepper
          opsTypes={opsTypes}
          activeOpsType={activeOpsType}
          setActiveOpsType={setActiveOpsType}
          // handleMaterialRequest={handleMaterialRequest}
          handleMaterialRequest={createOpsOrderRequestWithType}
          handlePrint={handlePrint}
        />
      </div>

      {/* <ReactToPrint
        trigger={() => (
          <Button color="primary" onClick={handlePrint} autoFocus>
            Print
          </Button>
        )}
        content={() => componentRef.current}
        documentTitle="new ops order"
        pageStyle="print"
      /> */}

      <div ref={componentRef} className={classes.bodyCont}>
        <div>
          <Paper className={classes.listCardCont} elevation={2}>
            <Box>
              {/* <Typography variant="h6">{activeOpsType}</Typography>
              <Box display="flex" alignItems="center">
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  style={{ padding: 0, margin: "0px 10px 0px 0px" }}
                >
                  <StarBorderOutlined style={{ fontSize: "2.2rem" }} />
                </IconButton>
                <Typography variant="h4">
                  {opsOrder?.orderCount
                    ? formattedDate +
                    `/#${opsOrder.orderCount.toString().padStart(2, "0")}`
                    : ""}
                </Typography>
              </Box> */}

              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <table className={classes.listTableSty}>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Vendor</td>
                        <td>
                          <TextField
                            error={tx?.secondParty?.parent.displayName === undefined}
                            variant="standard"
                            placeholder="Select Vendor"
                            value={tx?.secondParty?.parent.displayName}
                            fullWidth
                            size="small"
                            readOnly
                            onClick={() => setShowCreateVendorDialog(true)}
                            helperText={tx?.secondParty?.parent.displayName === undefined ? "Vendor Is Not Defind" : ""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>GST Treatment</td>
                        <td>Consumer</td>
                      </tr>
                      {/* <tr>
                        <td style={{ fontWeight: 500 }}>Vendor Reference</td>
                        <td>
                          <TextField
                            variant="standard"
                            placeholder="Enter Reference"
                            fullWidth
                            size="small"
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td style={{ fontWeight: 500 }}>Reference</td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
                              {referenceOpsOrders.length > 0 ? (
                                referenceOpsOrders.map((data, index) => (
                                  <div key={index} style={{ marginRight: 8 }}>
                                    <Card style={{ marginBottom: '5px', width: '90%' }}>
                                      <CardContent style={{ display: 'flex', alignItems: 'center', padding: '1px' }}>
                                        <p style={{ marginLeft: '4%' }}>
                                          {data.type} {data.name}
                                        </p>
                                        <CardActions style={{ justifyContent: 'flex-end' }}>
                                          <RemoveIcon style={{ cursor: 'pointer' }} onClick={() => { handleDeletereRerenceOpsOrders(data) }} />
                                        </CardActions>
                                      </CardContent>
                                    </Card>
                                  </div>
                                ))
                              ) : (
                                <TextField variant="standard" placeholder="Select References" fullWidth />
                              )}
                            </div>
                            <div style={{ marginLeft: 0 }}>
                              <AddIcon style={{ cursor: 'pointer' }} onClick={handleOpenOpsOrderSearchDialog} />
                            </div>
                            <OpsOrderSearchDialog
                              open={opsOrderSearchDialog}
                              onClose={handleCloseOpsOrderSearchDialog}
                              referenceOpsOrders={referenceOpsOrders}
                              setReferenceOpsOrders={setReferenceOpsOrders}
                              OpsOrdersWithTypes={OpsOrdersWithTypes}
                              setOpsOrdersWithTypes={setOpsOrdersWithTypes}
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <table className={classes.listTableSty}>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Order Deadline</td>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <td>
                            {/* <TextField
                            variant="standard"
                            placeholder="DD/MM/YYYY"
                            fullWidth
                            size="small"
                          /> */}
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              value={orderDeadline ? orderDeadline : opsOrder.orderDeadline}
                              onChange={handleChangeOrderDeadline}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </td>
                        </MuiPickersUtilsProvider>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Expected Arrival</td>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <td>
                            {/* <TextField
                            variant="standard"
                            placeholder="DD/MM/YYYY"
                            fullWidth
                            size="small"
                          /> */}
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              value={expectedArrival ? expectedArrival : opsOrder.expectedArrival}
                              onChange={handleChangeExpectedArrival}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </td>
                        </MuiPickersUtilsProvider>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Budget Item</td>
                        <td>
                          <TextField
                            variant="standard"
                            placeholder="Select Budget"
                            fullWidth
                            size="small"
                            onClick={() => setShowBudgetPicker(true)}
                          />
                        </td>
                      </tr>
                    </table>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: "20px", marginLeft: "-20px" }}>
          <MyAutocomplete
            value={subType}
            isSmall={false}
            text={subTypeText}
            setText={setSubTypeText}
            placeholder={"Resource Type"}
            results={subTypeArr}
            getOptionLabel={(option) => {
              return option || " ";
            }}
            onSelect={(value) => {
              setSubType(value);
            }}
            label={"Resource Type"}
            setWidth={"17%"}
          />

          <div style={{
            width: "40px", // Adjust the width as needed
            height: "40px", // Set the height to match the width for a square
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#e0e0e0", // Background color for the square box
          }}>
            <MyPopOver
              closeOnClick={true}
              appearContent={
                <PlayListIcon style={{ cursor: 'pointer', fontSize: "30px" }} />
              }
              showContent={
                <div className={classes.optionsCont}>
                  <div className={classes.singleOption}>
                    <FormGroup>
                      {tableColumn?.map((values, i) => (
                        <FormControlLabel
                          key={i}
                          control={
                            <Checkbox
                              checked={showTableColumn.includes(values)}
                              onChange={(e) => { handleChangeTableColumn(e, values) }}
                              color="primary"
                            />}
                          label={values}
                        />
                      ))}
                    </FormGroup>

                  </div>

                </div>
              }
            />
          </div>

        </div>

        <div>
          <BillListTable
            showTableColumn={showTableColumn}
            opsOrderName={opsOrderName}
            setOpsOrderName={setOpsOrderName}
            project={project}
            setProject={setProject}
            organization={organization}
            setOrganization={setOrganization}
            loading={loading}
            setLoading={setLoading}
            opsOrder={opsOrder}
            setTotalBillDetails={setTotalBillDetails}
          />
        </div>

        <ConfirmationDialog
          open={showConfDialog}
          setOpen={setShowConfDialog}
          title="Delete OPS Order"
          message="Are you sure you want to delete this ops order?"
          successButtonText="Delete"
          cancelButtonText="Cancel"
          successListener={() => {
            deleteOpsOrder();
          }}
          cancelListener={() => { }}
        />

        {organization && organization.wallet && (
          <FinanceRelationDialog
            open={showCreateVendorDialog}
            setOpen={setShowCreateVendorDialog}
            tx={tx}
            setTx={setTx}
            updateApi={updateTx}
            relationType={"Customer"}
            walletId={organization.wallet}
            setLoadingBool={setTxLoading}
          />
        )}
        <BudgetPicker open={showBudgetPicker} setOpen={setShowBudgetPicker} />

        <BudgetUpdate
          openUpdateBudget={openUpdateBudget}
          setOpenUpdateBudget={setOpenUpdateBudget}
          projectProfileId={projectProfileId}
          selectedBudgetId={selectedBudgetId}
          setSelectedBudgetId={setSelectedBudgetId}
          opsOrderId={opsOrderId}
          budegtUpdateItemType={"Procurements"}
          filterToGetBudgetUpdateItems={filterToGetBudgetUpdateItems}
          projectId={projectId}
          organizationId={organizationId}
          projectBlockId={projectBlockId}
          ref={BudgetUpdateRef}
          pageLoader={setLoadingBool}
          curParentStatus={curParentStatus}
          addNewSelectedItems={addNewSelectedItems}
        />

        {loadingBool &&
          <div className={classes.loaderCont} >
            <CircularProgress
              size={60}
              thickness={3}
              style={{ color: 'rgb(92, 144, 242)' }}
            />
          </div>}
      </div>
      {/* Send Mail Modal */}
      {/* <Modal
        open={showSendMail}
        onClose={handleCloseSendMail}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.mailmodal}
      >
        <Card className={classes.sendMailCard}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalTitle}>
              Compose Email
            </Typography>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={handleCloseSendMail}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />

          <CardContent>
            <div className={classes.inputContainer}>
              <Typography color="textSecondary" gutterBottom>
                Recipients
              </Typography>

              <Autocomplete
                {...defaultProps}
                id="auto-complete"
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <InputText {...params} label="autoComplete" style={{ flex: 1 }} />
                )}
                PaperProps={{
                  style: {
                    width: '100%', // Set the dropdown width to 100% of the input width
                  },
                }}
              />
            </div>

            <div className={classes.inputContainer}>
              <Typography color="textSecondary" gutterBottom>
                Subject
              </Typography>
              <InputText id="standard-basic" label="Standard" fullWidth />
            </div>

            <div className={classes.inputContainer}>
              <Typography color="textSecondary" gutterBottom>
                Message
              </Typography>
              <TextField
                id="additional-message"
                label="Type your message here"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={showMessages}
              />

            </div>

          </CardContent>
          <Divider />

          <div className={classes.inputContainer}>

            <span>
              <a href="#" onClick={handleViewAttachment}>
                View Attachment
              </a>
            </span>


            <input
              type="file"
              id="pdf-attachment"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={handleAttachmentChange}
            />
            <label htmlFor="pdf-attachment">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                style={{ marginLeft: '1%' }}
              >
                Attach PDF
              </Button>
            </label>

          </div>
          <Divider style={{ marginTop: '2%' }} />
          <CardActions className={classes.modalActions}>
            <Button size="small">Learn More</Button>
            <Button size="small">Learn More</Button>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
      </Modal> */}
      {/* Send Mail Modal */}
    </div>
  );
};

export default OpsOrder;
