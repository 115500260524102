import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Button, TextField, Box } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import { Add, ExpandMore } from "@material-ui/icons";
import { Autocomplete, LinearProgress } from "@mui/material";
import { useDebounce } from "react-use";
import Api from "../../../helpers/Api";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import CreateOffering from "../../finance2o/productsAndServices/create.offering.only";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
  },
  headerSty: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "19px",
      fontWeight: "600",
    },
  },
  tableCont: {
    width: "100%",
  },
  subheadCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
  },
  leftSubhead: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "18px",
      fontWeight: "600",
      color: "white",
    },
  },
  rigthSubhead: {
    display: "flex",
    alignItems: "center",
  },
  headAmountCont: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    "& h3": {
      fontSize: "13px",
      fontWeight: "400",
      color: "white",
    },
    "& p": {
      fontSize: "13px",
      fontWeight: "510",
      color: "white",
      marginLeft: "3px",
    },
    "& span": {
      fontSize: "13px",
      fontWeight: "400",
      margin: "0px 5px",
      color: "white",
    },
  },
  tableSty: {
    width: "100%",
    border: "1px solid gray",
    borderCollapse: "collapse",
    "& th": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "0px",
    },
    "& td": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0px",
      textAlign: "right",
      paddingRight: "10px",
    },
  },
  titleBtnCont: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    "& p": {
      fontSize: "13px",
      fontWeight: "500",
    },
  },
  btnCont: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "4px",
    borderRadius: "10px",
    marginLeft: "10px",
  },
  snoSty: {
    width: "40px",
  },
  itemTitleSty: {
    width: "160px",
  },
  itemTypeSty: {
    width: "130px",
  },
  qtySty: {
    width: "40px",
  },
  taxChipSty: {
    width: "80px",
  },
  unitSty: {
    width: "40px",
  },
  priceUnitSty: {
    width: "40px",
  },
  priceUnitGSTSty: {
    width: "40px",
  },
  GSTSty: {
    width: "120px",
  },
  totalCostIncludingGSTSty: {
    width: "160px",
  },
  optionsCont: {
    padding: "5px",
  },
  singleOption: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    "& img": {
      width: "20px",
      height: "auto",
      marginRight: "10px",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
  totalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "10px",
  },
  totalContainerChild: {
    flex: "0.5",
    margin: "10px",
    display: "flex",
    alignItems: "top",
  },
  totalTableSty: {
    width: "65%",
    border: "none",
    borderCollapse: "collapse",
    padding: "0px",
    fontSize: "0.9rem",
    color: "#27272a",
    fontWeight: 500,
    "& tr": {
      height: "5px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
      textAlign: "right",
    },
  },
  listTableSty: {
    width: "100%",
    border: "none",
    borderCollapse: "collapse",
    padding: "0px",
    fontSize: "0.9rem",
    color: "#27272a",
    fontWeight: 400,
    marginTop: "15px",
    "& tr": {
      height: "15px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
      textAlign: "left",
    },
  },
  inputText: {
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    fontSize: "14px",
    padding: "5px 7px",
    border: "none",
    outline: "none",
    "&:focus": {
      border: "1px solid #111",
    },
  },
}));

const GRNTable = ({
  organization,
  warehouse,
  moves,
  setMoves,
  movesToDel,
  setMovesToDel,
}) => {
  const classes = useStyles();
  const [materials, setMaterials] = useState([]);
  const [title, setTitle] = useState("");
  const [shouldSearch, setShouldSearch] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [tx, setTx] = useState({ type: "Bill" });
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [offeringCreateOpen, setOfferingCreateOpen] = useState(false);
  const [offeringCreateLoading, setOfferingCreateLoading] = useState(false);

  const searchFromDefaultLibrary = async () => {
    try {
      setLoadingMaterials(true);
      // console.log("Org: ", organization);
      const { data } = await Api.post("/wallet/offering/search-item", {
        searchStr: title,
        organizationId: organization?._id,
      });
      if (data) {
        setMaterials(data);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoadingMaterials(false);
    }
  };

  const handleMaterialSearch = async () => {
    if (title && title.length >= 3 && shouldSearch) {
      await searchFromDefaultLibrary();
    }
  };

  useDebounce(
    () => {
      handleMaterialSearch();
    },
    500,
    [title]
  );

  const addMove = (index) => {
    let newAry = [...moves];
    newAry.splice(index, 0, {
      _id: null,
      product: null,
      quantity: 0,
      rate: 0,
      fromType: null,
      toType: "ONSITE",
      fromWarehouse: null,
      toWarehouse: warehouse?._id,
    });
    setMoves(newAry);
  };

  const deleteMove = (index) => {
    if (moves.length > 1) {
      const updatedItems = [...moves];
      let itemToDel = moves[index];
      updatedItems.splice(index, 1);
      setMoves(updatedItems);
      if (itemToDel?._id) {
        movesToDel.push(itemToDel);
      }
    }
  };

  return (
    <div>
      <table className={classes.tableSty}>
        <tr>
          <th className={classes.itemTypeSty}>Sno.</th>
          <th className={classes.itemTypeSty}>Item Name</th>
          <th className={classes.itemTypeSty}>Quantity</th>
          <th className={classes.itemTypeSty}>Rate</th>
          <th className={classes.itemTypeSty}>Total</th>
        </tr>
        {moves?.map((move, index) => (
          <React.Fragment key={index}>
            <tr>
              <td className={classes.snoSty}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ padding: "0px 10px" }}
                >
                  <p>{index + 1}</p>
                  <MyPopOver
                    closeOnClick={true}
                    appearContent={
                      <Button className={classes.btnCont}>
                        <Add
                          style={{
                            marginRight: "5px",
                            fontSize: "15px",
                            color: "white",
                          }}
                        />
                        <ExpandMore
                          style={{ fontSize: "15px", color: "white" }}
                        />
                      </Button>
                    }
                    showContent={
                      <div className={classes.optionsCont}>
                        <div
                          className={classes.singleOption}
                          onClick={() => addMove(index)}
                        >
                          <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" />
                          <p>Create Move</p>
                        </div>
                        <div
                          className={classes.singleOption}
                          onClick={() => deleteMove(index)}
                        >
                          <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                          <p>Delete Move</p>
                        </div>
                      </div>
                    }
                  />
                </Box>
              </td>
              <td className={classes.itemTypeSty}>
                <Autocomplete
                  value={selectedMaterial}
                  onChange={(event, newValue) => {
                    setShouldSearch(false);
                    setSelectedMaterial(newValue);
                    console.log("Onchange called", newValue);

                    let newMoves = [...moves];
                    newMoves[index].product = newValue;
                    setMoves(newMoves);
                    setTitle("");
                  }}
                  onClose={(evt, reason) => {
                    if (!move?.product?.name) {
                      setTitle("");
                    }
                  }}
                  options={materials}
                  renderInput={(params) => (
                    <div
                      ref={params.InputProps.ref}
                      style={{ padding: "0px 10px" }}
                    >
                      <input
                        {...params.inputProps}
                        type="text"
                        placeholder="Enter item title"
                        value={move?.product?.name || title}
                        onChange={(e) => {
                          setShouldSearch(true);
                          setTitle(e.target.value);
                        }}
                        className={classes.inputText}
                      />
                    </div>
                  )}
                  loading={loadingMaterials}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      sx={{
                        color: "#3f3f46",
                        backgroundColor: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#f4f4f5",
                        },
                        cursor: "pointer",
                        padding: "2px 5px",
                      }}
                    >
                      {option?.name}
                    </Box>
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        border: "1px solid lightgray",
                        marginLeft: 1,
                        width: 484,
                      },
                    },
                  }}
                  noOptionsText={
                    <Button
                      onClick={() => {
                        console.log("Clicked: ", tx);
                        setOfferingCreateOpen(true);
                      }}
                      size="small"
                      variant="text"
                      autoCapitalize="false"
                    >
                      Create New?
                    </Button>
                  }
                />
              </td>
              <td className={classes.itemTypeSty}>
                <div style={{ padding: "0px 10px" }}>
                  <input
                    value={move?.quantity || 0}
                    onChange={(e) => {
                      let newMoves = [...moves];
                      newMoves[index].quantity = e.target.value;
                      setMoves(newMoves);
                    }}
                    className={classes.inputText}
                    type="number"
                    readOnly={move?._id !== null}
                  />
                </div>
              </td>
              <td className={classes.itemTypeSty}>
                <div style={{ padding: "0px 10px" }}>
                  <input
                    value={move?.rate || 0}
                    onChange={(e) => {
                      let newMoves = [...moves];
                      newMoves[index].rate = e.target.value;
                      setMoves(newMoves);
                    }}
                    className={classes.inputText}
                    type="number"
                    readOnly={move?._id !== null}
                  />
                </div>
              </td>
              <td className={classes.itemTypeSty}>
                <div style={{ padding: "0px 10px" }}>
                  <input
                    value={move?.quantity * move?.rate || 0}
                    className={classes.inputText}
                    type="number"
                    readOnly
                  />
                </div>
              </td>
            </tr>
            <NormalDialog
              openDialog={offeringCreateOpen}
              handleCloseShare={() => {
                setOfferingCreateOpen(false);
              }}
              pageTitle={"Add New"}
              content={
                <div>
                  <CreateOffering
                    text={title || ""}
                    setOpen={setOfferingCreateOpen}
                    setLoading={setOfferingCreateLoading}
                    onSelect={(data) => {
                      setShouldSearch(false);
                      setSelectedMaterial(data);
                      console.log("createOfferdata", data);
                    }}
                    tx={tx}
                    orgId={organization?._id}
                  />
                  <div style={{ width: "100%", height: "4px" }}>
                    {offeringCreateLoading && <LinearProgress />}
                  </div>
                </div>
              }
            />
          </React.Fragment>
        ))}
      </table>
    </div>
  );
};

export default GRNTable;
