const initialState = {
  materialFile: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AddEstimation":
      console.log("Estimation added:", action.payload);
      return {
        ...state,
        ...action.payload,
      };

    case "ResetEstimation":
      return initialState;

    default:
      return state;
  }
};
