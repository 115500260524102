import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import Api from "../../../helpers/Api";

const useStyles = makeStyles((theme) => ({
    customDialog: {
        height: '70%', // Adjust the height as needed
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        width: '100%', // Adjust the width as needed
        height: '100%', // Adjust the height as needed
        display: '-webkit-inline-box',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cardContent: {
        textAlign: 'center',
    },
    textField: {
        flex: 1, // Make the text field take up all available space
        marginRight: '10px',
        marginTop: '10px',
        width: '100%'
    },
    searchResults: {
        marginTop: '20px', // Adjust the margin as needed
        width: '100%',
        height: '100%', // You can adjust the height or use a max-height
        overflowY: 'auto', // Enable vertical scroll if needed
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const OpsOrderSearchDialog = (props) => {
    const {
        open,
        onClose,
        referenceOpsOrders,
        setReferenceOpsOrders,

        OpsOrdersWithTypes,
        setOpsOrdersWithTypes
    } = props;
    const classes = useStyles();
    const [opsOrderType, setOpsOrderType] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredRequest, setFilteredRequest] = useState([]);


    // const handleChangeSelect = (type) => {
    //     setOpsOrderType(type);
    //     setReferenceOpsOrders([]);
    // }

    const handleChangeCheck = (event, values) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setReferenceOpsOrders((prevSelected) => [...prevSelected, values]);
        } else {
            setReferenceOpsOrders((prevSelected) =>
                prevSelected.filter((item) => item !== values)
            );
        }

    }

    useEffect(() => {
        const filteredlRequest = OpsOrdersWithTypes?.filter((value) =>
            value.type === opsOrderType &&
            (searchQuery === "" || value.opsOrderUniqueId.includes(searchQuery))
        );
        setFilteredRequest(filteredlRequest);
    }, [searchQuery])


    const getOpsOrderRequestWithType = async (type) => {
        setOpsOrderType(type);
        const obj = {
            requestType: type
        }
        const res = await Api.post("procurement/ops/getOpsOrdertWithType", obj);

        if (res?.data) {
            setOpsOrdersWithTypes(res?.data);
        }
    };

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            className={{ paper: classes.customDialog }} // Apply the custom style to the Dialog component
        >
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Typography variant="h5" component="div" gutterBottom>
                        References
                    </Typography>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>

                        <TextField
                            select
                            label="Select"
                            variant="outlined"
                            className={classes.textField}
                            value={opsOrderType}
                            onChange={(e) => {
                                // handleChangeSelect(e.target.value)
                                getOpsOrderRequestWithType(e.target.value)
                            }}
                            defaultValue=""
                        >
                            <MenuItem value="">Select an option</MenuItem>
                            <MenuItem value="Material Request">Material Request</MenuItem>
                            <MenuItem value="BOQ">BOQ</MenuItem>
                            <MenuItem value="Purchase Order">Purchase Order</MenuItem>
                            <MenuItem value="Work Order">Work Order</MenuItem>
                            <MenuItem value="GRN">GRN</MenuItem>
                        </TextField>
                        <TextField
                            label="Search"
                            variant="outlined"
                            className={classes.textField}
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                        />
                    </div>
                </CardContent>
                <CardContent className={classes.searchResults}>

                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Reference By Types</FormLabel>
                        <FormGroup>

                            {filteredRequest.length > 0 ?

                                (filteredRequest?.map((values, index) => (
                                    <FormControlLabel
                                        key={values.opsOrderUniqueId}
                                        control={
                                            <Checkbox
                                                checked={referenceOpsOrders.includes(values)}
                                                onChange={(e) => { handleChangeCheck(e, values) }}
                                            />
                                        }
                                        label={`Sl.No: ${index}  ID: ${values.opsOrderUniqueId}`}
                                    />

                                )))
                                :
                                (OpsOrdersWithTypes?.map((values, index) => (
                                    <FormControlLabel
                                        key={values.opsOrderUniqueId}
                                        control={
                                            <Checkbox
                                                checked={referenceOpsOrders.includes(values)}
                                                onChange={(e) => { handleChangeCheck(e, values) }}
                                            />
                                        }
                                        label={`Sl.No: ${index}   ID: ${values.opsOrderUniqueId}`}
                                    />
                                )))
                            }

                        </FormGroup>
                    </FormControl>

                </CardContent>
            </Card>
        </Dialog>
    );
};

export default OpsOrderSearchDialog;
