import React, { useEffect, useState } from "react";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { dayjs } from "dayjs";
import Dialog from "../../styled/generic/Dialog";
import CheckGroup from "../../styled/generic/CheckGroup";
import FormBox from "../../styled/generic/FormBox";
import DatePicker from "../../styled/generic/DatePicker";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";

const InventoryFilterDialog = ({
  open,
  setOpen,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  selectedStatusOptions,
  setSelectedStatusOptions,
  setShouldRefreshStocks,
}) => {
  const statusOptions = ["INBOUND", "ONSITE", "USED", "RETURN", "WASTED"];

  return (
    <Dialog open={open} setOpen={setOpen} title="Filter Stock Moves">
      <CheckGroup
        label="Status"
        options={statusOptions}
        selectedOptions={selectedStatusOptions}
        setSelectedOptions={setSelectedStatusOptions}
      />

      <FormBox label="From Date">
        <DatePicker value={fromDate} setValue={setFromDate} />
      </FormBox>

      <FormBox label="To Date">
        <DatePicker value={toDate} setValue={setToDate} />
      </FormBox>

      <DuoButtonGroup
        primaryButtonText="Apply"
        primaryButtonListener={() => {
          setShouldRefreshStocks(true);
          setOpen(false);
        }}
        secondaryButtonText="Clear"
        secondaryButtonListener={() => {
          setSelectedStatusOptions([]);
          setFromDate(null);
          setToDate(null);
          setShouldRefreshStocks(true);
          setOpen(false);
        }}
      />
    </Dialog>
  );
};

export default InventoryFilterDialog;
