import { Switch } from "react-router-dom";
import { IoBusOutline } from "react-icons/io5";
import { IoRestaurantOutline } from "react-icons/io5";
import { BsTrainFront, BsHospital } from "react-icons/bs";
import { LuSchool } from "react-icons/lu";
import { FaUniversity } from "react-icons/fa";
import { IoWaterOutline } from "react-icons/io5";
import { IoCarOutline } from "react-icons/io5";
import { BiCctv } from "react-icons/bi";
import { MdLocalAirport } from "react-icons/md";
import { MdOutlineStoreMallDirectory } from "react-icons/md";
import { FaPowerOff } from "react-icons/fa6";
import { FaWifi } from "react-icons/fa";
import { GiGymBag } from "react-icons/gi";
import { TbAirConditioningDisabled } from "react-icons/tb";
import { FaSwimmer } from "react-icons/fa";
import { FaGasPump } from "react-icons/fa";
import { GiGrass } from "react-icons/gi";
import { FaFireExtinguisher } from "react-icons/fa";
import { GrElevator } from "react-icons/gr";
import { MdOutlineSportsBasketball } from "react-icons/md";

const PROXIMITY_OPTIONS = [
  {
    title: "Hospital",
    icon: BsHospital,
  },
  {
    title: "Airport",
    icon: MdLocalAirport,
  },
  {
    title: "Shopping Mall",
    icon: MdOutlineStoreMallDirectory,
  },
  {
    title: "Bus Stop",
    icon: IoBusOutline,
  },
  {
    title: "Restaurants",
    icon: IoRestaurantOutline,
  },
  {
    title: "Railway Station",
    icon: BsTrainFront,
  },
  {
    title: "School",
    icon: LuSchool,
  },
  {
    title: "University",
    icon: FaUniversity,
  },
];

const AMENITY_OPTIONS = [
  {
    title: "Water Supply",
    icon: IoWaterOutline,
  },
  {
    title: "Parking",
    icon: IoCarOutline,
  },
  {
    title: "Security",
    icon: BiCctv,
  },
  {
    title: "Power Backup",
    icon: FaPowerOff,
  },
  {
    title: "Wifi",
    icon: FaWifi,
  },
  {
    title: "Gym",
    icon: GiGymBag,
  },
  {
    title: "Air conditioner",
    icon: TbAirConditioningDisabled,
  },
  {
    title: "Swimming Pool",
    icon: FaSwimmer,
  },
  {
    title: "Gas Station",
    icon: FaGasPump,
  },
  {
    title: "Park",
    icon: GiGrass,
  },
  {
    title: "Fire Safety",
    icon: FaFireExtinguisher,
  },
  {
    title: "Elevator",
    icon: GrElevator,
  },
  {
    title: "Play Area",
    icon: MdOutlineSportsBasketball,
  },
];

const AREA_UNIT_OPTIONS = ["Sq ft", "Meter sq", "Acres"];

const AMENITY_OPTIONS_TEXT_ARR = [
  "Water Supply",
  "Security",
  "Power Backup",
  "Wifi",
  "Gym",
  "Air conditioner",
  "Swimming Pool",
  "Gas Station",
  "Park",
  "Fire Safety",
  "Elevator",
  "Play Area",
];

const UNIT_ARRAY = [
  "Meter",
  "Item",
  "Foot",
  "Square Meter",
  "Square Foot",
  "Cubic Meter",
  "Cubic Foot",
  "Kilogram",
  "Number",
  "Bags",
  "Hour",
  "Labour",
  "Liter",
  "Lumpsum",
  "Job",
  "Day",
  "Month",
  "Tonne",
  "Yard",
  "Acre",
  "Inch",
  "Week",
  "Square Yard",
  "Cubic Yard",
  "Gallon",
  "Barrel",
  "Pound",
  "Point",
  "Packet",
  "Box",
  "Carton",
  "Unit",
  "Sample",
  "Cubic Yard",
  "Square Foot",
  "Pounds",
  "Linear Foot",
  "Each",
  "Cubic Foot",
  "Sheet",
  "Pack",
  "Roll",
  "Brass",
];

const FLOOR_OPTIONS = [
  { title: "Ground Floor", value: 0 },
  { title: "1st Floor", value: 1 },
  { title: "2nd Floor", value: 2 },
  { title: "3rd Floor", value: 3 },
  { title: "5th or More", value: -1 },
];

const FURNISHING_OPTIONS = [
  "Fully Furnished",
  "Semi Furnished",
  "Un Furnished",
];

export {
  PROXIMITY_OPTIONS,
  AMENITY_OPTIONS,
  AREA_UNIT_OPTIONS,
  UNIT_ARRAY,
  AMENITY_OPTIONS_TEXT_ARR,
  FLOOR_OPTIONS,
  FURNISHING_OPTIONS,
};
