import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import IssueSvg from "../../../Assets/issue.svg";
import { Avatar, Chip, Radio, Typography } from "@material-ui/core";
import html2plaintext from "html2plaintext";
import arrayToReducer from "../../../helpers/arrayToReducer";

const useStyles = makeStyles((theme) => ({
	row: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	imgStyle: {
		width: "1.1rem",
		height: "1.1rem",
		borderRadius:'1.1rem'
	},
	itemStyle: {
		minWidth: "18rem",
	},
}));

const EntityListItemByID = ({entity}) => {
	const classes = useStyles();
    const parent = entity?.profile?.parent
    const file = parent?.displayPicture
    const name = parent?.displayName


	return (

			<div className={classes.row}>
				<ListItemAvatar>
                    <img 
                            src={file?.thumbUrl||file?.url} 
                            className={classes.imgStyle}
                        />
				</ListItemAvatar>

				<ListItemText
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						width: "3rem",
					}}
					id={entity?._id}
					primary={name|| " "}
					// could add desc in doc mode in secondary. look for list item text overflow

				/>
                <Chip
                    label={entity?.type}
                    clickable
                    color="primary"
                    variant="outlined"
                />
			</div>

	);
};

export default EntityListItemByID;
