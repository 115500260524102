import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React from "react";

const DuoButtonGroup = ({
  primaryButtonText,
  primaryButtonListener,
  secondaryButtonText,
  secondaryButtonListener,
  direction = "right",
  buttonWidth = "150px",
  hideSecondary = false,
  disablePrimaryButton,
  disableSecondaryButton,
  disableMargins = false,
  loadingPrimary = false,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={direction === "right" ? "flex-end" : "flex-start"}
      sx={{ mt: disableMargins ? 0 : 3 }}
    >
      {!hideSecondary && (
        <Button
          variant="outlined"
          onClick={() => secondaryButtonListener()}
          style={{ marginRight: "5px", width: buttonWidth }}
          disableElevation
          disabled={disableSecondaryButton}
        >
          {secondaryButtonText}
        </Button>
      )}
      <LoadingButton
        variant="contained"
        onClick={() => primaryButtonListener()}
        style={{ marginLeft: "5px", width: buttonWidth }}
        disableElevation
        disabled={disablePrimaryButton}
        loading={loadingPrimary}
      >
        {primaryButtonText}
      </LoadingButton>
    </Box>
  );
};

export default DuoButtonGroup;
