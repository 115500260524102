import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, IconButton } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { mainRoles } from "../../helpers/contactHelper"
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AllCommonField from "../styled/CommonComponents/AllCommonFields";
import {
    BasicUserData,
    BasicOrgData,
    BasicGroupData,
    prospectFields,
    customerFields,
    employeeFields,
    contractorFields,
    vendorFields,
    investorFields,
    lenderFields,
    tenantFields,
    workerFields
} from "../../helpers/roleRelatedFields"
import { createPalApiCall, getAllAdminProjectsAndOrgs } from "../styled/CommonComponents/api.call";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "hidden",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    formCont: {
        width: "52%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px 15px 20px",
        borderRight: "1px solid #ececec",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    directoryMainCont: {
        width: "48%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px 15px 0px",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },
    btnCont: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    roleAndTypeCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    allfieldsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    divAndDeleteCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "20px"
    },
    profileTypeRoleCont: {
        width: "calc(100% - 45px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    imageNameCont: {
        width: "250px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    nameUsernameCont: {
        width: "calc(100% - 35px)",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "300"
        }
    },
    directoryHeadCont: {
        width: "100%",
        height: "45px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        borderBottom: "2px solid #ececec",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600"
        }
    },
    allSelectedProfileCont: {
        width: "100%",
        height: "calc(100% - 47px)",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
}))

const CreatePalDrawer = ({
     openPal, setOpenPal, title, financialRelationType, 
     mainProfileWitParent, profileIdArr, selectEntityFunction, 
     setNewPalItem
}) => {
    const classes = useStyles();

    const { user } = useSelector((state) => state.auth);

    const [type, setType] = useState("User");
    const [roleType, setRoleType] = useState("Customer");
    const [stateChange, setStateChange] = useState(false)
    const [basicDataWithField, setBasicDataWithField] = useState({})
    const [roleDataWithField, setRoleDataWithField] = useState({})
    const [selectedProfileForRelation, setSelectedProfileForRelation] = useState([{}])
    const [relationScopTypes, setRelationScopTypes] = useState([])
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [projectOptions, setProjectOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [warningMsg, setWarningMsg] = useState("")
    const [warningBool, setWarningBool] = useState(false)

    useEffect(() => {
        if (profileIdArr && profileIdArr.length > 0) {
            getAllAdminProjectsAndOrgs({ givenProfiles: profileIdArr })
                .then((data) => {
                    const organizationArr = data?.organizations || [];
                    const projectArr = data?.projects || [];
                    setOrganizationOptions(organizationArr)
                    setProjectOptions(projectArr)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [mainProfileWitParent])

    const changeSelectedProfileForRelationValue = () => {
        let obj = {
            type: mainProfileWitParent?.parentModelName === "User" ? "Myself" : mainProfileWitParent?.parentModelName,
            profile: mainProfileWitParent,
            role: financialRelationType
        }
        console.log("1")
        setSelectedProfileForRelation([obj])
    }

    useEffect(() => {
        changeSelectedProfileForRelationValue()
    }, [mainProfileWitParent?.parentModelName, mainProfileWitParent, financialRelationType])

    const changeRelationScopTypesValue = () => {
        let isMyself = false;
        selectedProfileForRelation.map((c) => {
            if (c.type === "Myself") {
                isMyself = true;
            }
        })

        if (isMyself) {
            setRelationScopTypes(["Project", "Organization"])
        } else {
            setRelationScopTypes(["Myself", "Project", "Organization"])
        }
    }

    useEffect(() => {
        changeRelationScopTypesValue();
    }, [selectedProfileForRelation, stateChange])

    useEffect(() => {
        setStateChange(!stateChange)
        switch (roleType) {
            case "Prospect":
                setRoleDataWithField(prospectFields)
                break;
            case "Customer":
                setRoleDataWithField(customerFields)
                break;
            case "Employee":
                setRoleDataWithField(employeeFields)
                break;
            case "Contractor":
                setRoleDataWithField(contractorFields)
                break;
            case "Vendor":
                setRoleDataWithField(vendorFields)
                break;
            case "Investor":
                setRoleDataWithField(investorFields)
                break;
            case "Lender":
                setRoleDataWithField(lenderFields)
                break;
            case "Tenant":
                setRoleDataWithField(tenantFields)
                break;
            case "Worker":
                setRoleDataWithField(workerFields)
                break;
        }
        setStateChange(!stateChange)
    }, [roleType])

    useEffect(() => {
        setStateChange(!stateChange)
        switch (type) {
            case "User":
                setBasicDataWithField(BasicUserData)
                break;
            case "Organization":
                setBasicDataWithField(BasicOrgData)
                break;
            case "Group":
                setBasicDataWithField(BasicGroupData)
                break;
        }
        setStateChange(!stateChange)
    }, [type])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setWarningBool(false);
    }

    const createPal = async (e) => {
        e.preventDefault();
        let basicDataObj = {}
        let contactDataObj = {}
        let basicData = {}
        let relationObject = {}
        let isAnyIssue = false;
        let msg = ""

        Object.keys(basicDataWithField).map((field) => {
            basicDataObj[field] = basicDataWithField[field]?.value
        })
        Object.keys(roleDataWithField).map((field) => {
            contactDataObj[field] = roleDataWithField[field]?.value
        })

        if (type === "User" && (basicDataObj?.firstName == null || basicDataObj?.firstName == "" || basicDataObj?.firstName.length < 2)) {
            isAnyIssue = true;
            msg = "First name is required "
        } else if ((type === "Organization" || type === "Group") && (basicDataObj?.displayName == null || basicDataObj?.displayName == "" || basicDataObj?.displayName.length < 3)) {
            isAnyIssue = true;
            msg = "Name is required "
        }

        if (selectedProfileForRelation === null || selectedProfileForRelation.length === 0) {
            isAnyIssue = true;
            if (msg.length > 5) {
                msg = msg + "and have to selected minimum one entity for directory"
            } else {
                msg = msg + "Have to selected minimum one entity for directory"
            }
        }

        if (isAnyIssue) {
            setWarningMsg(msg)
            setWarningBool(isAnyIssue)
            return;
        }

        setLoading(true)
        if (type === "User") {
            basicData = {
                email: (basicDataObj?.mailId || "").toLowerCase(),
                displayName: `${basicDataObj?.firstName} ${basicDataObj?.middleName} ${basicDataObj?.lastName}`,
                address: {
                    street: basicDataObj?.street,
                    city: basicDataObj?.city,
                    postalCode: basicDataObj?.postalCode,
                    state: basicDataObj?.state,
                    country: basicDataObj?.country,
                },
                phone: basicDataObj?.phone,
                type: type,
                parent: mainProfileWitParent?.parent,
                parentModelName: mainProfileWitParent?.parentModelName,
                contactModelName: roleType,
                user: user._id,
                costCode: basicDataObj?.costCode,
                hourlyRate: basicDataObj?.hourlyRate,
                pan: basicDataObj?.pan,
            }
        } else if (type === "Organization") {
            basicData = {
                type: type,
                address: {
                    street: basicDataObj?.street,
                    city: basicDataObj?.city,
                    postalCode: basicDataObj?.postalCode,
                    state: basicDataObj?.state,
                    country: basicDataObj?.country,
                },
                displayName: basicDataObj?.displayName,
                email: (basicDataObj?.mailId || "").toLowerCase(),
                phone: basicDataObj?.phone,
                zip: basicDataObj?.postalCode || "",
                website: basicDataObj?.website,
                pan: basicDataObj?.pan,
                taxId: basicDataObj?.taxId,
                parent: mainProfileWitParent?.parent,
                parentModelName: mainProfileWitParent?.parentModelName,
                contactModelName: roleType,
                user: user._id,
                costCode: basicDataObj?.costCode,
                hourlyRate: basicDataObj?.hourlyRate
            }
        } else if (type === "Group") {
            basicData = {
                type: type,
                displayName: basicDataObj?.displayName,
                email: (basicDataObj?.mailId || "").toLowerCase(),
                phone: basicDataObj?.phone,
                costCode: basicDataObj?.costCode,
                hourlyRate: basicDataObj?.hourlyRate,
                parent: mainProfileWitParent?.parent,
                parentModelName: mainProfileWitParent?.parentModelName,
                contactModelName: roleType,
                user: user._id
            }
        }

        selectedProfileForRelation.map((profileType) => {
            const curProfileId = profileType?.profile?._id || null
            if (curProfileId) {
                const rolesArr = relationObject[curProfileId] || []
                rolesArr.push(profileType?.role)
                const rolesArrSet = new Set([...rolesArr])
                relationObject[curProfileId] = [...rolesArrSet]
            }
        })

        const requestObj = {
            contactData: contactDataObj,
            basicData: basicData,
            relationObject,
            currentEntityProfileId: mainProfileWitParent?._id
        }

        console.log(requestObj)

        await createPalApiCall(requestObj)
            .then((data) => {
                console.log(data)
                selectEntityFunction(data)
                setNewPalItem(data)
                setLoading(false)
                setOpenPal(false)
                setType("User");
                setRoleType("Customer");
                setRoleDataWithField(customerFields)
                setBasicDataWithField(BasicUserData)
                setWarningMsg("")
                setWarningBool(false)
                changeSelectedProfileForRelationValue();
                changeRelationScopTypesValue()
            })
            .catch((err) => {
                console.log(err)
            })
    };

    return (
        <NormalDrawer
            openDrawer={openPal}
            setOpenDrawer={setOpenPal}
            anchor={"right"}
            title={title ? title : "Add New Pal"}
            width={"85vw"}
            content={<form className={classes.mainCont} onSubmit={createPal} >
                <div className={classes.bodyCont}  >
                    <div className={classes.formCont} >
                        <div className={classes.roleAndTypeCont} >
                            <RadioGroup row aria-label="position" name="position" value={type} onChange={(evt, val) => setType(val)} >
                                <FormControlLabel value="User" control={<Radio color="primary" />} label="User" />
                                <FormControlLabel value="Organization" control={<Radio color="primary" />} label="Organization" />
                                <FormControlLabel value="Group" control={<Radio color="primary" />} label="Group" />
                            </RadioGroup>

                            <Autocomplete
                                size="small"
                                value={roleType}
                                options={mainRoles.filter(role => role !== "Owner" && role !== "Admin" && role !== "Viewer" && role !== "Other")}
                                getOptionLabel={(option) => option || ""}
                                getOptionSelected={(option) => {
                                    return option === roleType;
                                }}
                                style={{ width: "150px" }}
                                onChange={(event, value) => {
                                    setRoleType(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Role"}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.allfieldsCont} >
                            {/* basic Data */}
                            {Object.keys(basicDataWithField).map((fieldData) => (
                                <AllCommonField
                                    type={basicDataWithField[fieldData]?.type}
                                    value={basicDataWithField[fieldData]?.value}
                                    lable={basicDataWithField[fieldData]?.lable}
                                    row={basicDataWithField[fieldData]?.row}
                                    width={basicDataWithField[fieldData]?.width}
                                    onChange={(field, value) => {
                                        setStateChange(!stateChange)
                                        const locObj = {
                                            ...basicDataWithField
                                        }
                                        locObj[field].value = value
                                        setBasicDataWithField(locObj)
                                        setStateChange(!stateChange)
                                    }}
                                    fieldData={fieldData}
                                    stateChange={stateChange}
                                    setStateChange={setStateChange}
                                />
                            ))}
                            {/* role specific data */}
                            {Object.keys(roleDataWithField).map((fieldData) => (
                                <AllCommonField
                                    type={roleDataWithField[fieldData]?.type}
                                    value={roleDataWithField[fieldData]?.value}
                                    lable={roleDataWithField[fieldData]?.lable}
                                    row={roleDataWithField[fieldData]?.row}
                                    width={roleDataWithField[fieldData]?.width}
                                    fieldData={fieldData}
                                    onChange={(field, value) => {
                                        setStateChange(!stateChange)
                                        const locObj = {
                                            ...roleDataWithField
                                        }
                                        locObj[field].value = value
                                        setRoleDataWithField(locObj)
                                        setStateChange(!stateChange)
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={classes.directoryMainCont} >
                        <div className={classes.directoryHeadCont} >
                            <h3>Add In Directory</h3>

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setStateChange(!stateChange)
                                    const obj = {
                                        type: "Project",
                                        profile: null,
                                        role: financialRelationType
                                    }
                                    let arr = [...selectedProfileForRelation]
                                    arr.push(obj);
                                    console.log("2")
                                    setSelectedProfileForRelation(arr)
                                    setStateChange(!stateChange)
                                }}
                            >
                                Add New
                            </Button>
                        </div>

                        <div className={classes.allSelectedProfileCont} >
                            {selectedProfileForRelation && selectedProfileForRelation.map((profileTypeRole, i) => (
                                <div className={classes.divAndDeleteCont} >
                                    <div className={classes.profileTypeRoleCont} >
                                        <Autocomplete
                                            size="small"
                                            value={profileTypeRole?.type}
                                            options={relationScopTypes}
                                            getOptionLabel={(option) => option || ""}
                                            getOptionSelected={(option) => {
                                                return option === profileTypeRole?.type;
                                            }}
                                            style={{ width: "25%" }}
                                            onChange={(event, value) => {
                                                console.log(value)
                                                setStateChange(!stateChange)
                                                let obj = {
                                                    ...profileTypeRole
                                                }
                                                if (value === "Myself") {
                                                    const profileData = {
                                                        _id: user?.profile,
                                                        parent: user,
                                                        parentModelName: "User"
                                                    }
                                                    obj.profile = profileData
                                                }
                                                obj.type = value
                                                let arr = [...selectedProfileForRelation]
                                                arr[i] = obj
                                                console.log(arr)
                                                console.log("3")
                                                setSelectedProfileForRelation(arr)
                                                setStateChange(!stateChange)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={"Type"}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />

                                        {profileTypeRole?.type !== "Myself" && (
                                            <Autocomplete
                                                size="small"
                                                value={profileTypeRole?.profile}
                                                options={profileTypeRole?.type === "Project" ? projectOptions : organizationOptions}
                                                getOptionLabel={(option) => option?.parent?.displayName || ""}
                                                getOptionSelected={(option) => {
                                                    return option?._id === profileTypeRole?.profile?._id;
                                                }}
                                                style={{ width: "45%" }}
                                                onChange={(event, value) => {
                                                    setStateChange(!stateChange)
                                                    let obj = {
                                                        ...profileTypeRole
                                                    }
                                                    obj.profile = value
                                                    let arr = [...selectedProfileForRelation]
                                                    arr[i] = obj
                                                    console.log("4")
                                                    setSelectedProfileForRelation(arr)
                                                    setStateChange(!stateChange)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={profileTypeRole?.type === "Project" ? "Project" : "Organization"}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        )}

                                        <Autocomplete
                                            size="small"
                                            value={profileTypeRole?.role}
                                            options={mainRoles.filter(role => role !== "Owner" && role !== "Admin" && role !== "Viewer" && role !== "Other")}
                                            getOptionLabel={(option) => option || ""}
                                            getOptionSelected={(option) => {
                                                return option === roleType;
                                            }}
                                            style={{ width: "25%" }}
                                            onChange={(event, value) => {
                                                setStateChange(!stateChange)
                                                let arr = [...selectedProfileForRelation]
                                                arr[i].role = value
                                                console.log("5")
                                                setSelectedProfileForRelation(arr)
                                                setStateChange(!stateChange)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={"Role"}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </div>
                                    <IconButton
                                        onClick={() => {
                                            let arr = [...selectedProfileForRelation]
                                            arr.splice(i, 1)
                                            setSelectedProfileForRelation(arr)
                                        }}
                                    >
                                        <DeleteIcon style={{ fontSize: "20px" }} />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "5px" }} >
                    {loading && (<LinearProgress />)}
                </div>
                <div className={classes.btnCont} >
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        type="submit"
                    >
                        Save & Close
                    </Button>
                </div>
                <Snackbar
                    open={warningBool}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert onClose={handleClose} severity="error">
                        {warningMsg}
                    </Alert>
                </Snackbar>
            </form>}
        />
    );
};

export default CreatePalDrawer;