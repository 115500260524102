import { createTheme } from "@material-ui/core/styles";

const colors = {
  primary: {
    100: "#BCDFFB",
    200: "#95CDF9",
    300: "#6EBBF7",
    400: "#48A8F5",
    500: "#2196F3",
    600: "#0B79D1",
    700: "#085A9B",
    800: "#063B66",
    900: "#031C31",
  },
  secondary: {
    100: "#FBD8BC",
    200: "#F9C195",
    300: "#F7AB6E",
    400: "#F59448",
    500: "#F37E21",
    600: "#D1630B",
    700: "#9B4A08",
    800: "#663006",
    900: "#311703",
  },
  red: {
    100: "#FCDADA",
    200: "#F9B5B5",
    300: "#F58F8F",
    400: "#F26A6A",
    500: "#EF4444",
    600: "#E71414",
    700: "#B30F0F",
    800: "#800B0B",
    900: "#4C0707",
  },
  orange: {
    100: "#FDD3B5",
    200: "#FCBB8D",
    300: "#FBA366",
    400: "#FA8B3E",
    500: "#F97316",
    600: "#D25905",
    700: "#9B4204",
    800: "#642B03",
    900: "#2D1301",
  },
  skyblue: {
    100: "#A1DEF9",
    200: "#7AD0F7",
    300: "#54C3F5",
    400: "#2DB5F2",
    500: "#0EA5E9",
    600: "#0B80B4",
    700: "#085A7F",
    800: "#04354A",
    900: "#010F15",
  },
  green: {
    100: "#9CEEBA",
    200: "#79E8A2",
    300: "#57E28A",
    400: "#34DC72",
    500: "#22C55E",
    600: "#1A9547",
    700: "#116530",
    800: "#09351A",
    900: "#010603",
  },
  zinc: {
    100: "#C5C5C9",
    200: "#B0B0B6",
    300: "#9B9BA2",
    400: "#85858E",
    500: "#71717A",
    600: "#56565D",
    700: "#3B3B40",
    800: "#202023",
    900: "#050506",
  },
  disabled: "#89939E",
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary[600],
    },
    secondary: {
      main: colors.secondary[600],
    },
    error: {
      main: colors.red[600],
    },
    warning: {
      main: colors.orange[600],
    },
    info: {
      main: colors.skyblue[600],
    },
    success: {
      main: colors.green[600],
    },
    text: {
      primary: colors.zinc[800],
      secondary: colors.zinc[700],
    },
    background: {
      default: "#E7ECFD",
      paper: "#FFFFFF",
    },
    action: {
      active: colors.primary[100],
      hover: "rgba(0,0,0,0.05)",
      selected: colors.primary[100],
      disabled: colors.disabled,
      disabledBackground: colors.disabled,
    },
    divider: colors.zinc[200],
  },
  typography: {
    fontFamily: "Inter",
    fontSize: 16,
    h1: {
      fontSize: "2.5rem",
      color: colors.zinc[900],
    },
    h2: {
      fontSize: "2rem",
      color: colors.zinc[900],
    },
    h3: {
      fontSize: "1.75rem",
      color: colors.zinc[800],
    },
    h4: {
      fontSize: "1.5rem",
      color: colors.zinc[800],
    },
    h5: {
      fontSize: "1.25rem",
      color: colors.zinc[800],
    },
    h6: {
      fontSize: "1rem",
      color: colors.zinc[800],
    },
    subtitle1: {
      fontSize: "1rem",
      color: colors.zinc[700],
    },
    subtitle2: {
      fontSize: "0.875rem",
      color: colors.zinc[600],
    },
    body1: {
      fontSize: "1rem",
      color: colors.zinc[700],
    },
    body2: {
      fontSize: "0.875rem",
      color: colors.zinc[600],
    },
    button: {
      fontSize: "1rem",
      textTransform: "none",
      color: colors.zinc[600],
    },
    caption: {
      fontSize: "0.75rem",
      color: colors.zinc[600],
    },
    overline: {
      fontSize: "0.625rem",
      textTransform: "uppercase",
      color: colors.zinc[700],
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  sideMargin: {
    fullScreen: "65px",
    mdScreen: "35px",
    smScreen: "15px",
    sxScreen: "5px",
  },
  appbar: {
    height: "52px",
  },
  drawer: {
    width: "7rem",
    smWidth: "7rem",
  },
});

export const drawerWidthTheme = {
  width: "7rem",
  smWidth: "7rem",
};

export default theme;
