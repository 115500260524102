import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import { useSelector } from "react-redux";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { TbCrane } from "react-icons/tb";
import { Avatar, makeStyles, Paper, IconButton } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
	cardsCont: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		paddingTop: "10px"
	},
	paperCont: {
		width: "31%",
		"& .MuiIconButton-root": {
			padding: "0px"
		}
	},
	cardSty: {
		width: "100%",
		height: "130px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		margin: "0px",
		padding: "0px",
		"& p": {
			marginTop: "10px",
			fontSize: "16px",
			fontWeight: "510",
			color: "black"
		}
	},
	avatarSty: {
		width: "55px",
		height: "55px",
		backgroundColor: theme.palette.primary.main,
	},
	iconSty: {
		fontSize: "35px"
	}
}));


function DialogOptionSelect({
	setTab, setParentProfileObject, setSelectedProfile,
	setBreadcrumbHistory, breadcrumbHistory
}) {
	const classes = useStyles();
	const { user } = useSelector((state) => state.auth);

	return (
		<div className={classes.cardsCont} >
			<Paper
				className={classes.paperCont}
				onClick={() => {
					if (setTab) {
						setTab(0);
					}
				}}
			>
				<IconButton style={{ width: "100%", borderRadius: "0px" }} >
					<div className={classes.cardSty} >
						<Avatar className={classes.avatarSty} >
							<ApartmentIcon className={classes.iconSty} />
						</Avatar>

						<p>Organizations</p>
					</div>
				</IconButton>
			</Paper>

			<Paper
				className={classes.paperCont}
				onClick={() => {
					if (setTab) {
						setTab(1);
					}
				}}
			>
				<IconButton style={{ width: "100%", borderRadius: "0px" }} >
					<div className={classes.cardSty} >
						<Avatar className={classes.avatarSty} >
							<TbCrane className={classes.iconSty} />
						</Avatar>

						<p>Projects</p>
					</div>
				</IconButton>
			</Paper>

			<Paper
				className={classes.paperCont}
				onClick={() => {
					setTab(2)
					setSelectedProfile(user?.profile)
					setBreadcrumbHistory([
						...breadcrumbHistory, 'User',
						user?.displayName
					]);
					setParentProfileObject(user)
				}}
			>
				<IconButton style={{ width: "100%", borderRadius: "0px" }} >
					<div className={classes.cardSty} >
						<Avatar className={classes.avatarSty} >
							<PersonIcon className={classes.iconSty} />
						</Avatar>

						<p>My profile</p>
					</div>
				</IconButton>
			</Paper>
		</div>
	);
}

export default DialogOptionSelect;