import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Paper,
  Modal,
  Card,
  CardContent,
  CardActions,
  Divider,
  TextField as InputText,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { DataGrid } from "@mui/x-data-grid";
import Api from "../../../helpers/Api";
import PropTypes from "prop-types";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ScreenShareOutlinedIcon from "@material-ui/icons/ScreenShareOutlined";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import BillListTable from "./BillListTable";
import {
  Archive,
  ChevronRight,
  Delete,
  Edit,
  KeyboardArrowDown,
  StarBorderOutlined,
  Close as CloseIcon,
} from "@material-ui/icons";
import { Grid, Menu, MenuItem, TextField, alpha, styled } from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import GRNTable from "./GRNTable";
import ConfirmationDialog from "../../global/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    padding: "0px 5px",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },

  bodyCont: {
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
    padding: "15px",
  },

  tableSty: {
    width: "100%",
    border: "1px solid gray",
    borderCollapse: "collapse",
    "& th": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "0px",
    },
    "& td": {
      border: "1px solid gray",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0px",
      textAlign: "right",
      paddingRight: "10px",
    },
  },
  listTableSty: {
    width: "100%",
    border: "none",
    borderCollapse: "collapse",
    padding: "0px",
    fontSize: "0.9rem",
    color: "#27272a",
    fontWeight: 400,
    marginTop: "15px",
    "& tr": {
      height: "40px",
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      padding: "0px",
      textAlign: "left",
    },
  },
  listCardCont: {
    marginTop: "0.7%",
    width: "100%",
    padding: "15px",
  },
  //Send mail
  // mailmodal: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //   overflow: 'auto', /* Add scroll for smaller screens */
  // },

  // sendMailCard: {
  //   width: '80%', /* Adjust the width */
  //   maxWidth: 1000, /* Set a maximum width for the card */
  //   position: 'relative', /* Allow z-index to work */
  // },

  // modalHeader: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  //   padding: '8px 16px', /* Add some padding */
  //   backgroundColor: '#f0f0f0', /* Background color for header */
  // },

  // modalTitle: {
  //   flexGrow: 1,
  // },

  // closeButton: {
  //   marginLeft: '8px',
  // },

  // inputContainer: {
  //   // display: 'flex',
  //   // alignItems: 'center',
  //   marginTop: '16px',

  // },

  // modalActions: {
  //   justifyContent: 'flex-start', /* Align actions to the right */
  //   padding: '16px',
  // },
  //send mail
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const GRN = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [opsOrder, setOpsOrder] = useState("Ishant");
  const [opsOrderName, setOpsOrderName] = useState("Ishant");
  const [project, setProject] = useState();
  const [organization, setOrganization] = useState();
  const [warehouse, setWarehouse] = useState();
  const [moves, setMoves] = useState([]);
  const [movesToDel, setMovesToDel] = useState([]);

  const { opsId } = useParams();

  const getOpsOrder = async () => {
    try {
      const { data } = await Api.post("/procurement/ops/get-by-id", {
        opsOrderId: opsId,
      });
      setOpsOrder(data);
      // Different states coz they can be edited from frontend
      setOpsOrderName(data.name || "Untitled");
      setProject(data.project);
      setOrganization(data.organization);
      setWarehouse(data.warehouse);
      let stockMoveList = data.stockMoveList;
      if (stockMoveList?.moves?.length > 0) {
        setMoves(stockMoveList?.moves || []);
      } else {
        setMoves([
          {
            _id: null,
            product: null,
            quantity: 0,
            rate: 0,
            fromType: null,
            toType: "ONSITE",
            fromWarehouse: null,
            toWarehouse: warehouse,
          },
        ]);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch ops order",
        },
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfDialog, setShowConfDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteOpsOrder = async () => {
    try {
      const { data } = await Api.post("/procurement/ops/delete", {
        opsOrderId: opsId,
      });
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ops order deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while deleting ops order",
        },
      });
    }
  };

  const updateGRN = async () => {
    try {
      if (!project || !project._id) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please choose a project",
          },
        });
        return;
      }
      if (!organization || !organization._id) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please choose a project",
          },
        });
        return;
      }

      let movesToCreate = moves.filter((item) => item._id === null);

      const { data } = await Api.post("/procurement/grn/update", {
        id: opsId,
        movesToDelete: movesToDel,
        movesToCreate: movesToCreate,
      });
      if (data) {
        history.goBack();
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Updated successful",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to update GRN",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while updating",
        },
      });
    }
  };

  useEffect(() => {
    getOpsOrder();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <Typography className={classes.headerTitle}>GRN</Typography>
        </div>
        <div className={classes.barRight}>
          <div className={classes.barRight}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              onClick={handleClick}
              endIcon={<KeyboardArrowDown />}
              color="primary"
            >
              PUBLISH
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  updateGRN();
                  handleClose();
                }}
                disableRipple
              >
                <Archive />
                Save
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowConfDialog(true);
                  handleClose();
                }}
                disableRipple
              >
                <Delete />
                Delete
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
      </div>
      <div className={classes.bodyCont}>
        <div>
          <Paper className={classes.listCardCont} elevation={2}>
            <Box>
              <Typography variant="h6">Goods Received Note</Typography>
              <Box display="flex" alignItems="center">
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  style={{ padding: 0, margin: "0px 10px 0px 0px" }}
                >
                  <StarBorderOutlined style={{ fontSize: "2.2rem" }} />
                </IconButton>
                <Typography variant="h4">
                  {opsOrder?.orderCount
                    ? `#${opsOrder.orderCount.toString().padStart(2, "0")}`
                    : ""}
                </Typography>
              </Box>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <table className={classes.listTableSty}>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Vendor</td>
                        <td>
                          <TextField
                            variant="standard"
                            placeholder="Select Vendor"
                            value=""
                            fullWidth
                            size="small"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Warehouse</td>
                        <td>{warehouse?.name}</td>
                      </tr>
                    </table>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <table className={classes.listTableSty}>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Move Date</td>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <td>
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </td>
                        </MuiPickersUtilsProvider>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 500 }}>Expected Arrival</td>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <td>
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </td>
                        </MuiPickersUtilsProvider>
                      </tr>
                    </table>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          {warehouse?._id && (
            <GRNTable
              organization={organization}
              warehouse={warehouse}
              moves={moves}
              setMoves={setMoves}
              movesToDel={movesToDel}
              setMovesToDel={setMovesToDel}
            />
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={showConfDialog}
        setOpen={setShowConfDialog}
        title="Delete OPS Order"
        message="Are you sure you want to delete this ops order?"
        successButtonText="Delete"
        cancelButtonText="Cancel"
        successListener={() => {
          deleteOpsOrder();
        }}
        cancelListener={() => {}}
      />
    </div>
  );
};

export default GRN;
