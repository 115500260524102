import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { roleMapForContact } from "../../../helpers/contactHelper";

const useStyles = makeStyles((theme) => ({
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "200"
        }
    },
    roleChip: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: "10px",
        fontWeight: "400",
        padding: "1px 5px",
        borderRadius: "7px",
        marginRight: "5px"
    },
    showFlex: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    }
}));

function SimpleUserCard({
    profile, selectEntityFunction, selectedEntitiesProfileIds,
    onClickOptionHideOptions, setShowBool
}) {
    const classes = useStyles();

    const [contactArr, setContactArr] = useState([])

    useEffect(() => {
        const contacts = profile?.contacts || []
        let idArr = [];
        let arr = []
        contacts.map((contact) => {
            if (!idArr.includes(contact?._id)) {
                if (contact?.profile == profile?._id) {
                    arr.push(contact)
                } else {
                    arr.push({
                        ...contact,
                        type: roleMapForContact[contact?.type]
                    })
                }
                idArr.push(contact?._id)
            }
        })
        setContactArr(arr)
    }, [profile?.contacts])

    return (
        <div
            className="simpleUserCardMainCont"
            onClick={() => {
                if (selectEntityFunction) {
                    const parent = profile?.parent
                    const updatedParent = {
                        ...parent,
                        displayPicture: profile?.displayPicture,
                    }
                    selectEntityFunction({
                        _id: profile?._id,
                        model: profile?.model,
                        public: profile?.public,
                        username: profile?.username,
                        parentModelName: profile?.parentModelName,
                        createdAt: profile?.createdAt,
                        parent: updatedParent,
                        contacts: contactArr || []
                    })
                }
                if (onClickOptionHideOptions) {
                    setShowBool(false)
                }
            }}
            style={selectedEntitiesProfileIds.includes(profile?._id) ? { background: "#ececec" } : {}}
        >
            <div className={classes.showFlex} >
                {profile?.parentModelName === "OutSider" ? (
                    <Avatar
                        style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "5px"
                        }}
                    >
                        {profile?.displayPicture?.thumbUrl}
                    </Avatar>
                ) : (
                    <Avatar
                        src={profile?.displayPicture?.thumbUrl ? profile?.displayPicture?.thumbUrl : profile?.displayPicture?.url}
                        alt={profile?.parent?.displayName}
                        style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "5px"
                        }}
                    />
                )}
                <div className={classes.detailsCont} >
                    <h3>{profile?.parent?.displayName}</h3>
                    <p>{profile?.parent?.username}</p>
                </div>
            </div>
            <span className="simpleUserCardType" >
                {`${profile?.parentModelName} ${profile?.parentModelName === "Pal" ? profile?.parent?.type : ""}`}
            </span>
            {contactArr && contactArr.length > 0 && (
                <div className="simpleUserCardRolesCont" >
                    {contactArr.map((contact) => (
                        <div className={classes.roleChip} >{contact?.type}</div>
                    ))}
                </div>
            )}
        </div>
    );
}
export default SimpleUserCard; 