import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const OrgProjectPickerDialog = ({
  open,
  setOpen,
  defaultProjectId,
  defaultOrganizationId,
  callback,
  type,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [orgTeams, setOrgTeams] = useState([]);
  const [projectTeams, setProjectTeams] = useState([]);
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const [selectedOrg, setSelectedOrg] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedProjectBlock, setSelectedProjectBlock] = useState();
  const [selectedWarehouse, setSelectedWarehouse] = useState();

  const dispatch = useDispatch();

  const getBasicData = async () => {
    const res = await Api.post("shared/getBasicData", {
      userProfileId: user?.profile,
    });

    if (res?.data) {
      const data = res?.data;
      const orgTeamRes = data.orgTeams;
      const projectTeamRes = data.projectTeams;
      console.log("OrgProject: ", data);
      setOrgTeams(orgTeamRes);
      setProjectTeams(projectTeamRes);

      if (defaultProjectId) {
        setSelectedProject(
          projectTeamRes.find((item) => item?.parent?._id === defaultProjectId)
            ?.parent
        );
      }

      if (defaultOrganizationId) {
        setSelectedOrg(
          orgTeamRes.find((item) => item?.parent?._id === defaultOrganizationId)
            ?.parent
        );
      }
    }
  };

  const getProjectBlocks = async () => {
    if (!selectedProject || !selectedProject?._id) {
      return;
    }

    try {
      const { data } = await Api.post("/public/project/block/get", {
        projectId: selectedProject?._id,
      });

      if (data) {
        setProjectBlocks(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getWarehouses = async () => {
    try {
      const { results } = await Api.post(
        "/inventory/getWareHousesByOrgProject",
        {
          projectId: selectedProject?._id || null,
          organizationId: selectedOrg?._id || null,
          mode: "organization",
        }
      );
      if (results && results.length > 0) {
        setWarehouses(results);
        setSelectedWarehouse(results[0]);
      }
    } catch (err) {
      console.log("Warehouse error: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch warehouse",
        },
      });
    }
  };

  useEffect(() => {
    getBasicData();
  }, [defaultProjectId, defaultOrganizationId, type]);

  useEffect(() => {
    getProjectBlocks();
  }, [selectedProject]);

  useEffect(() => {
    if (type === "GRN" && selectedOrg) {
      getWarehouses();
    }
  }, [selectedProject, selectedOrg]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Create Ops Order</DialogTitle>
      <DialogContent>
        <Box style={{ padding: "5px 0px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              {selectedOrg ? "Organization" : "Select Organization"}
            </InputLabel>
            <Select
              value={selectedOrg}
              onChange={(evt) => setSelectedOrg(evt.target.value)}
              label={selectedOrg ? "Organization" : "Select Organization"}
            >
              {orgTeams.length > 0 &&
                orgTeams.map((obj) => (
                  <MenuItem key={obj._id} value={obj?.parent}>
                    {obj?.parent?.displayName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <InputLabel id="demo-simple-select-helper-label">
              {selectedProject ? "Project" : "Select Project"}
            </InputLabel>
            <Select
              value={selectedProject}
              onChange={(evt) => setSelectedProject(evt.target.value)}
              label={selectedProject ? "Project" : "Select Project"}
            >
              {projectTeams.length > 0 &&
                projectTeams.map((obj) => (
                  <MenuItem key={obj._id} value={obj?.parent}>
                    {obj?.parent?.displayName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {projectBlocks && projectBlocks.length > 0 && (
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <InputLabel id="demo-simple-select-helper-label">
                {selectedProjectBlock
                  ? "Project Block"
                  : "Select Project Block"}
              </InputLabel>
              <Select
                value={selectedProjectBlock}
                onChange={(evt) => setSelectedProjectBlock(evt.target.value)}
                label={
                  selectedProjectBlock
                    ? "Project Block"
                    : "Select Project Block"
                }
              >
                {projectBlocks.map((obj) => (
                  <MenuItem key={obj._id} value={obj}>
                    {obj?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {type === "GRN" && (
            <div>
              {warehouses && warehouses?.length > 0 ? (
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {selectedWarehouse ? "Warehouse" : "Select Warehouse"}
                  </InputLabel>
                  <Select
                    value={selectedWarehouse}
                    onChange={(evt) => setSelectedWarehouse(evt.target.value)}
                    label={selectedWarehouse ? "Warehouse" : "Select Warehouse"}
                  >
                    {warehouses.map((obj) => (
                      <MenuItem key={obj._id} value={obj}>
                        {obj?.name} {obj?.belongsToOrg && "(Org)"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <span style={{ color: "red" }}>
                  This project and org are not associated with any warehouses.
                  Please create one to continue
                </span>
              )}
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (!selectedProject || !selectedOrg) {
              dispatch({
                type: "AddApiAlert",
                payload: {
                  success: false,
                  message: "Please select project and organization",
                },
              });
            } else if (type === "GRN" && !selectedWarehouse) {
              dispatch({
                type: "AddApiAlert",
                payload: {
                  success: false,
                  message: "Please select a warehouse",
                },
              });
            } else {
              callback({
                project: selectedProject,
                projectBlock: selectedProjectBlock,
                organization: selectedOrg,
                selectedWarehouse: selectedWarehouse,
              });
            }
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgProjectPickerDialog;
