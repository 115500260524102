import React from "react";
import NavButton from "./NavButton";
import { useMediaQuery } from "@material-ui/core";
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import { useTheme } from "@material-ui/core/styles";
import { BsShop } from "react-icons/bs";
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";

const NavButtonInfos = [
  {
    icon: BsShop,
    text: "Marketplace",
    link: "/",
  },
  {
    icon: AssignmentIcon,
    text: "Listings",
    link: "/listings",
  },
  {
    icon: PollOutlinedIcon,
    text: "Workspace",
    link: "/workspace",
  },
  {
    icon: HiOutlineBuildingOffice2,
    text: "Projects",
    link: "/projects",
  },
  {
    icon: ExploreOutlinedIcon,
    text: "Feeds",
    link: "/feed",
  },
  {
    icon: AccountBoxOutlinedIcon,
    text: "Account",
    link: "/account",
  },
];

export default function Navbar(props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {NavButtonInfos.map((NBInfo, index) => {
        return (
          <NavButton
            key={NBInfo.text}
            text={NBInfo.text}
            icon={NBInfo.icon}
            index={index}
            link={NBInfo.link}
          />
        );
      })}
    </>
  );
}