import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { InputAdornment, TextField, Button, IconButton, ClickAwayListener } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from "@material-ui/icons/Add";
import Checkbox from '@material-ui/core/Checkbox';
import { mainRoles } from "../../../helpers/contactHelper"
import TuneIcon from '@material-ui/icons/Tune';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Collapse from '@material-ui/core/Collapse';
import EntitySearchPagination from "./EntitySearchPagination";
import CreatePalDrawer from "../../AddMember/CreatePalDrawer";

const useStyles = makeStyles((theme) => ({
    textFieldBtnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-outlined": {
            padding: "7px 15px",
            marginLeft: "10px"
        }
    },
    textFieldContWithFilter: {
        width: (props) => props.noPalCreate ? "100%" : "calc(100% - 95px)",
        position: "relative",
        "& .MuiIconButton-root": {
            padding: "5px"
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingRight: "25px"
        }
    },
    textFieldContWithOutFilter: {
        width: (props) => props.noPalCreate ? "100%" : "calc(100% - 95px)",
        position: "relative",
        "& .MuiIconButton-root": {
            padding: "5px"
        }
    },
    filterMainContShow: {
        width: "100%",
        height: "70vh",
        position: "absolute",
        zIndex: "999",
        top: "40px",
        left: "0px",
        opacity: "1",
        borderBottomLeftRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        overflowX: "hidden",
        backgroundColor: "white",
        transition: "all .3s ease-in-out",
    },
    filterSideBar: {
        width: "190px",
        height: "100%",
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        backgroundColor: "rgba(231, 236, 253, 0.1)"
    },
    filterBody: {
        width: "calc(100% - 190px)",
        height: "100%",
        overflowY: "auto"
    },
    filterSelectedOptions: {
        fontSize: "16px",
        fontWeight: "500",
        padding: "8px 10px",
        cursor: "pointer",
        backgroundColor: "rgba(231, 236, 253, 1)",
        borderBottom: `2px solid rgba(45, 118, 224, 1)`,
        transition: "all .3s ease-in-out",
    },
    filterUnselectedOptions: {
        fontSize: "16px",
        fontWeight: "500",
        padding: "8px 10px",
        cursor: "pointer",
        borderBottom: `2px solid rgba(0, 0, 0, 0.1)`,
        transition: "all .3s ease-in-out",
    },
    filterMainContHide: {
        width: "100%",
        height: "70vh",
        position: "absolute",
        padding: "5px",
        top: "40px",
        left: "0px",
        opacity: "0",
        borderBottomLeftRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        overflowX: "hidden",
        backgroundColor: "white",
        transition: "all .3s ease-in-out",
    },
    optionsContSingle: {
        width: "100%",
        padding: "10px"
    },
    filterBtn: {
        position: "absolute",
        top: "4px",
        right: "5px",
        zIndex: "999",
        "& .MuiSvgIcon-root": {
            fontSize: "20px"
        }
    },
    arrowCont: {
        "& .MuiSvgIcon-root": {
            fontSize: "13px"
        },
    },
    checkboxCont: {
        "& .MuiSvgIcon-root": {
            fontSize: "17px"
        },
    },
    scopSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "1px 0px 1px 5px",
        backgroundColor: "rgba(231, 236, 253, 0.3)",
        marginBottom: "7px",
        borderRadius: "5px"
    },
    nameArrowCont: {
        width: "calc(100% - 35px)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "space-between"
    },
    roleSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "2px 0px 2px 25px",
    },
    optionsContShow: {
        width: "100%",
        maxHeight: "280px",
        position: "absolute",
        left: "0px",
        top: "40px",
        backgroundColor: "white",
        zIndex: "99999",
        borderTop: "none",
        borderBottomLeftRadius: "3px",
        borderBottomRightRadius: "3px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        overflowX: "hidden",
        overflowY: "auto",
        transition: "all .3s ease-in-out",
    },
    optionsContHide: {
        width: "100%",
        maxHeight: "0px",
        position: "absolute",
        left: "0px",
        top: "40px",
        borderTop: "none",
        zIndex: "99999",
        backgroundColor: "white",
        borderBottomLeftRadius: "3px",
        borderBottomRightRadius: "3px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        overflowX: "hidden",
        overflowY: "auto",
        transition: "all .3s ease-in-out",
    },
    expanded: {
        transform: "rotate(90deg)",
        transition: "all .3s ease-in-out",
    },
    collapse: {
        transform: "rotate(0deg)",
        transition: "all .3s ease-in-out",
    },
    addNewPalBtn: {
        position: "absolute",
        top: "5px",
        right: "5px",
        zIndex: "9999"
    }
}));

const defaultSearchParameter = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            },
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            },
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Project",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

//Required****
//1.profileIdArr = which profiles contact you want to see in search reasult***(required)***
//2.selectEntityFunction = function to select entity from the list***(required)***
//3.mainProfileWitParent = the profile where you use the component***(required)***
//4.selectedEntities = profile of all selected entities***(required)***

//Optional
//1.searchParameter = through which entityes and roles you want to search. follow structher of "defaultSearchParameter",
//2.notAllowedProfileId = which profiles you don't want to see in the search reasult
//3.noFilter = if you don't want to show filters to the users, just make it "ture"
//4.onClickOptionHideOptions = if you make it ture then when you click any option on dropdown, all the options will hide after that click
//5.financialRelationType = financial relation type

export default function EntitySearch({
    searchParameter, profileIdArr = [], notAllowedProfileId = [],
    noFilter = false, selectedEntities = [], selectEntityFunction,
    onClickOptionHideOptions = false, financialRelationType = "Customer",
    mainProfileWitParent, noPalCreate, allowOutSider=false
}) {
    const classes = useStyles({
        noPalCreate
    });
    const theme = useTheme()

    const [filterOptions, setFilterOptions] = useState([])
    const [filterOptionChange, setFilterOptionChange] = useState(false)
    const [entityScopObj, setEntityScopObj] = useState([])
    const [entityRoleObj, setEntityRoleObj] = useState([])
    const [showBool, setShowBool] = useState(false)
    const [searchStr, setSearchStr] = useState("")
    const [showFilter, setShowFilter] = useState(false)
    const [openPal, setOpenPal] = useState(false)
    const [newPalItem, setNewPalItem] = useState()
    const [curFilterOption, setCurFilterOption] = useState("User")

    useEffect(() => {
        let locSearchParameter = searchParameter || defaultSearchParameter
        let arr1 = []
        locSearchParameter.map((param) => {
            let locScops = param?.scop || []
            let arr2 = []
            locScops.map((scop) => {
                let locRoles = scop?.role || []
                let arr3 = []
                locRoles.map((role) => {
                    arr3.push({
                        role: role,
                        selected: locScops.length === 1 ? true : locScops.length === 2 && scop?.type === "Contact" ? false : true,
                        isExpanded: false,
                    })
                })
                arr2.push({
                    type: scop?.type,
                    roles: arr3,
                    selected: locScops.length === 1 ? true : locScops.length === 2 && scop?.type === "Contact" ? false : true,
                    isExpanded: false,
                })
            })
            arr1.push({
                entity: param?.entity,
                selected: true,
                roles: arr2,
                isExpanded: false,
            })
        })
        setFilterOptions(arr1)
        setFilterOptionChange(!filterOptionChange)
    }, [searchParameter])

    const onCheckClick = (updatedFilter) => {
        const updatedFilterOptions = updatedFilter.map((option) => {
            const scopRolesArr = option.roles.map((scopRole) => {
                const rolesArr = scopRole.roles.filter((roleType) => roleType.selected)
                if (scopRole.roles.length === 0) {
                    return {
                        ...scopRole
                    }
                } else {
                    return {
                        ...scopRole,
                        selected: rolesArr.length > 0 ? true : false
                    }
                }
            })

            let isSelected = false;
            if (scopRolesArr.length === 2) {
                if (scopRolesArr[0].selected || scopRolesArr[1].selected) {
                    isSelected = true
                }
            } else if (scopRolesArr.length === 1) {
                if (scopRolesArr[0].selected) {
                    isSelected = true
                }
            }

            return {
                ...option,
                roles: scopRolesArr,
                selected: isSelected
            }
        })
        setFilterOptions(updatedFilterOptions)
        setFilterOptionChange(!filterOptionChange)
    }

    useEffect(() => {
        let entityScop = {}
        let entityRole = {}
        filterOptions.map((option) => {
            if (option?.selected) {
                let locScop = null
                let roleArr = []
                option.roles.map((scopRole) => {
                    if ((option.roles.length === 2 && scopRole.selected) || option.roles.length === 1) {
                        locScop = scopRole?.type
                        scopRole.roles.map((role) => {
                            if (role.selected) {
                                roleArr.push(role.role)
                            }
                        })
                    }
                })
                if (locScop) {
                    if (option.entity === "User") {
                        entityScop["users"] = locScop
                        entityRole["users"] = roleArr
                    } else if (option.entity === "Organization") {
                        entityScop["organizations"] = locScop
                        entityRole["organizations"] = roleArr
                    } else if (option.entity === "Project") {
                        entityScop["projects"] = locScop
                        entityRole["projects"] = roleArr
                    } else if (option.entity === "User(Pal)") {
                        entityScop["palUser"] = locScop
                        entityRole["palUser"] = roleArr
                    } else if (option.entity === "Organization(Pal)") {
                        entityScop["palOrg"] = locScop
                        entityRole["palOrg"] = roleArr
                    } else if (option.entity === "Group(Pal)") {
                        entityScop["palGroup"] = locScop
                        entityRole["palGroup"] = roleArr
                    }
                }
            }
        })
        setEntityScopObj(entityScop)
        setEntityRoleObj(entityRole)
    }, [filterOptionChange])


    return (
        <ClickAwayListener onClickAway={() => { setShowBool(false); setShowFilter(false) }}>
            <div className={classes.textFieldBtnCont} >
                <div className={noFilter ? classes.textFieldContWithOutFilter : classes.textFieldContWithFilter} >
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        placeholder="Enter user/project/organization"
                        autoComplete='off'
                        value={searchStr}
                        fullWidth
                        onChange={(e) => { setSearchStr(e.target.value); }}
                        onClick={() => {
                            setShowFilter(false)
                            setShowBool(true)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    {noFilter ? null : (<>
                        <span className={classes.filterBtn} >
                            <IconButton onClick={() => { setShowBool(false); setShowFilter(true) }} >
                                <TuneIcon />
                            </IconButton>
                        </span>
                        <div className={showFilter ? classes.filterMainContShow : classes.filterMainContHide} >
                            <div className={classes.filterSideBar} >
                                {filterOptions.map((mainEntity) => (
                                    <div
                                        className={curFilterOption === mainEntity.entity ? classes.filterSelectedOptions : classes.filterUnselectedOptions}
                                        onClick={() => {
                                            setCurFilterOption(mainEntity.entity)
                                        }}
                                    >
                                        {mainEntity.entity}
                                    </div>
                                ))}
                            </div>
                            <div className={classes.filterBody} >
                                {filterOptions.map((param, i) => (<div key={i} >
                                    {curFilterOption === param?.entity ? (<div className={classes.optionsContSingle} >
                                        {param.roles.map((scopRole, j) => (<React.Fragment key={`${i}-${j}`} >
                                            <div className={classes.scopSty} >
                                                <span className={classes.checkboxCont} >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={scopRole?.selected}
                                                        onChange={(e) => {
                                                            let arr = [...filterOptions]
                                                            arr[i].roles[j].selected = e.target.checked
                                                            let subSubArr = arr[i].roles[j].roles.map((role) => {
                                                                return {
                                                                    ...role,
                                                                    selected: e.target.checked
                                                                }
                                                            })
                                                            arr[i].roles[j].roles = subSubArr
                                                            let otherIndex = j === 0 ? 1 : 0
                                                            if (arr[i].roles.length > 1 && arr[i].roles[otherIndex].selected === true && e.target.checked) {
                                                                arr[i].roles[otherIndex].selected = false;
                                                                let subSubArr2 = arr[i].roles[otherIndex].roles.map((role) => {
                                                                    return {
                                                                        ...role,
                                                                        selected: false
                                                                    }
                                                                })
                                                                arr[i].roles[otherIndex].roles = subSubArr2
                                                            }
                                                            setFilterOptions(arr)
                                                            onCheckClick(arr)
                                                        }}
                                                    />
                                                </span>
                                                <div
                                                    className={classes.nameArrowCont}
                                                    onClick={() => {
                                                        if (scopRole.type === "Contact") {
                                                            let arr = [...filterOptions]
                                                            arr[i].roles[j].isExpanded = !arr[i].roles[j].isExpanded
                                                            setFilterOptions(arr)
                                                        }
                                                    }}
                                                >
                                                    <p>{scopRole?.type}</p>
                                                    <div>
                                                        {scopRole.type === "Contact" && (
                                                            <span className={classes.arrowCont} >
                                                                <IconButton>
                                                                    <ArrowForwardIosIcon className={filterOptions[i].roles[j].isExpanded ? classes.expanded : classes.collapse} />
                                                                </IconButton>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse in={scopRole.isExpanded}>
                                                <div>
                                                    {scopRole?.roles && scopRole.roles.map((role, k) => (
                                                        <div className={classes.roleSty} key={`${i}-${j}-${k}`} >
                                                            <span className={classes.checkboxCont} >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={role?.selected}
                                                                    onChange={(e) => {
                                                                        let arr = [...filterOptions]
                                                                        arr[i].roles[j].roles[k].selected = !arr[i].roles[j].roles[k].selected
                                                                        setFilterOptions(arr)
                                                                        onCheckClick(arr)
                                                                    }}
                                                                />
                                                            </span>
                                                            <p>{role.role}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Collapse>
                                        </React.Fragment>))}
                                    </div>) : null}
                                </div>))}
                            </div>
                        </div>
                    </>)}
                    <div className={showBool ? classes.optionsContShow : classes.optionsContHide} >
                        <EntitySearchPagination
                            entityScopObj={entityScopObj}
                            entityRoleObj={entityRoleObj}
                            searchStr={searchStr}
                            profileIdArr={profileIdArr}
                            notAllowedProfileId={notAllowedProfileId}
                            selectEntityFunction={selectEntityFunction}
                            selectedEntities={selectedEntities}
                            onClickOptionHideOptions={onClickOptionHideOptions}
                            setShowBool={setShowBool}
                            newPalItem={newPalItem}
                            allowOutSider={allowOutSider}
                        />
                    </div>
                </div>
                {noPalCreate ? null : (<>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setShowBool(false)
                            setShowFilter(false)
                            setOpenPal(true)
                        }}
                    >
                        New
                    </Button>
                    <CreatePalDrawer
                        text={searchStr}
                        openPal={openPal}
                        setOpenPal={setOpenPal}
                        financialRelationType={financialRelationType}
                        mainProfileWitParent={mainProfileWitParent}
                        profileIdArr={profileIdArr}
                        selectEntityFunction={selectEntityFunction}
                        setNewPalItem={setNewPalItem}
                    />
                </>)}
            </div>
        </ClickAwayListener>
    );
}