import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import _ from "lodash";
import { Typography, Avatar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import emptyIcon from "../../../Assets/EmptyInboxOutline.svg";
import LessText from "../../styled/CommonComponents/LessText";
import CreatePal from "../../finance2o/commonComponent/transaction/CreatePal";
import {
  getUserByName,
  getOrgByName,
  getOwnUsers,
  findAndAddRelation,
} from "../../finance2o/commonComponent/transaction/api";
const useStyles = makeStyles((theme) => ({
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  showSecondPartyInfoCont: {
    width: "65%",
  },
  showSecondPartyImgCont: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  showSecondPartyCont: {
    width: "320px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px 0px",
  },
  secondPartyEditCont: {
    width: "16%",
  },
  mainDialogCont: {
    width: "450px",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "78vw",
    },
  },
  optionsCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
    },
    "& .MuiButton-root": {
      border: "1px solid gray",
      borderRadius: "15px",
      marginRight: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-label": {
        fontSize: "10px",
      },
    },
  },
  optionBtnCont: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-root": {
      border: "1px solid gray",
      borderRadius: "10px",
      marginRight: "8px",
      padding: "1px 10px",
      minWidth: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-root": {
        padding: "1px 5px",
      },
    },
  },
  containerSty: {
    width: "100%",
    height: "calc(100vh - 350px)",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "10px",
  },
  emptyBox: {
    width: "100%",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "150px",
    },
  },
  singleCard: {
    display: "flex",
    alignItems: "center",
    padding: "7px 0px",
    paddingLeft: "15px",
    borderRadius: "5px",
    cursor: "pointer",
    "& div": {
      marginLeft: "10px",
    },
    "& h3": {
      fontSize: "17px",
      fontWeight: "510",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "450",
    },
    "&:hover": {
      backgroundColor: "#ececec",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 0px",
      "& div": {
        marginLeft: "5px",
      },
      "& h3": {
        fontSize: "15px",
        fontWeight: "500",
      },
      "& p": {
        fontSize: "12px",
        fontWeight: "400",
      },
    },
  },
  bottomAct: {
    width: "100%",
    padding: "10px 7px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "180px",
      width: "auto",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
    },
    "& a": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        height: "120px",
      },
    },
  },
}));

const FinanceRelationDialog = ({
  open,
  setOpen,
  tx,
  setTx,
  updateApi,
  walletId,
  setLoadingBool,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const { teamIds, teamDictionary } = useSelector((state) => state.team);

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const [show, setShow] = useState("user");

  const [allOwnUsers, setAllOwnUsers] = useState([]);
  const [allOwnProjects, setAllOwnProjects] = useState([]);
  const [allOwnOrgs, setAllOwnOrgs] = useState([]);
  const [accessibleProjects, setAccessibleProjects] = useState([]);

  const [userObj, setUserObj] = useState(null);
  const [userName, setUserName] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [allUserIds, setAllUserIds] = useState([]);

  const [orgObj, setOrgObj] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [allOrgs, setAllOrgs] = useState([]);
  const [allOrgIds, setAllOrgIds] = useState([]);

  const [projectObj, setProjectObj] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectIds, setAllProjectIds] = useState([]);

  useEffect(() => {
    if (tx?.secondParty) {
      const obj = {
        profile: tx?.secondParty,
        wallet: tx?.secondPartyWallet,
      };

      if (tx?.secondParty?.parentModelName === "User") {
        setUserObj(obj);
        setShow("user");
      } else if (tx?.secondParty?.parentModelName === "Organization") {
        setOrgObj(obj);
        setShow("organization");
      } else if (tx?.secondParty?.parentModelName === "Project") {
        setProjectObj(obj);
        setShow("project");
      } else {
        if (tx?.secondParty?.parent?.type === "User") {
          setUserObj(obj);
          setShow("user");
        } else if (tx?.secondParty?.parent?.type === "Organization") {
          setOrgObj(obj);
          setShow("organization");
        } else if (tx?.secondParty?.parent?.type === "Project") {
          setProjectObj(obj);
          setShow("project");
        }
      }
    }
  }, [tx, open]);

  useEffect(() => {
    getOwnUsers({ walletId })
      .then((allEntities) => {
        let OnlyUsers = [];
        let OnlyProjects = [];
        let OnlyOrgs = [];
        let OnlyUserIds = [];
        let OnlyProjectIds = [];
        let OnlyOrgIds = [];
        let filteredEntity = [];
        let profileIds = [];

        allEntities &&
          allEntities.length > 0 &&
          allEntities.map((entity) => {
            if (
              !profileIds.includes(entity?.profile?._id) &&
              tx?.firstParty?._id !== entity?.profile?._id
            ) {
              profileIds.push(entity?.profile?._id);
              filteredEntity.push(entity);
            }
          });

        filteredEntity &&
          filteredEntity?.length > 0 &&
          filteredEntity.map((d) => {
            if (d?.profile?.parentModelName === "Pal") {
              if (d?.profile?.parent?.type === "User") {
                OnlyUsers.push(d);
                OnlyUserIds.push(d?.profile?.parent?._id);
              } else if (d?.profile?.parent?.type === "Organization") {
                OnlyOrgs.push(d);
                OnlyOrgIds.push(d?.profile?.parent?._id);
              } else if (d?.profile?.parent?.type === "Project") {
                OnlyProjects.push(d);
                OnlyProjectIds.push(d?.profile?.parent?._id);
              }
            } else if (d?.profile?.parentModelName === "User") {
              OnlyUsers.push(d);
              OnlyUserIds.push(d?.profile?.parent?._id);
            } else if (d?.profile?.parentModelName === "Organization") {
              OnlyOrgs.push(d);
              OnlyOrgIds.push(d?.profile?.parent?._id);
            } else if (d?.profile?.parentModelName === "Project") {
              OnlyProjects.push(d);
              OnlyProjectIds.push(d?.profile?.parent?._id);
            }
          });

        if (tx?.firstParty?.parentModelName === "User") {
          OnlyUserIds.push(tx?.firstParty?.parent?._id);
        } else if (tx?.firstParty?.parentModelName === "Organization") {
          OnlyOrgIds.push(tx?.firstParty?.parent?._id);
        }

        setAllUsers(OnlyUsers);
        setAllProjects(OnlyProjects);
        setAllOrgs(OnlyOrgs);

        setAllOwnUsers(OnlyUsers);
        setAllOwnProjects(OnlyProjects);
        setAllOwnOrgs(OnlyOrgs);

        setAllUserIds(OnlyUserIds);
        setAllOrgIds(OnlyOrgIds);
        setAllProjectIds(OnlyProjectIds);
      })
      .catch((err) => {
        console.log(err);
      });

    let allAccessableProjects = [];
    teamIds.map((teamId) => {
      var teamData = teamDictionary[teamId];
      if (
        teamData?.parentModelName === "Project" &&
        teamData?.parent &&
        tx?.firstParty?._id !== teamData?.parent?.profile?._id
      ) {
        allAccessableProjects.push({
          profile: teamData?.parent?.profile,
          wallet: teamData?.parent?.profile?.parent?.wallet,
        });
      }
    });

    setAccessibleProjects(allAccessableProjects);
  }, [walletId, open]);

  const handleClose = async () => {
    setOpen(false);
  };

  // for users
  useDebounce(
    () => {
      if (userName && userName.length > 1) {
        onChangeUserNameInput(userName);
      } else {
        setAllUsers(allOwnUsers);
      }
    },
    1200,
    [userName]
  );

  const onChangeUserNameInput = async (value) => {
    await getUserByName({ name: value, userIds: allUserIds })
      .then((users) => {
        let filteredOwnUsers = [];
        allOwnUsers.map((user) => {
          let name = user?.profile?.parent?.displayName;
          const patt = new RegExp(value, "i");
          const res = patt.test(name);
          if (res) {
            filteredOwnUsers.push(user);
          }
        });
        let allUsers = [...filteredOwnUsers, ...users];

        setAllUsers(allUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectUser = (value) => {
    if (userObj?.profile?._id == value?.profile?._id) {
      setUserObj(null);
    } else {
      setUserObj(value);
    }
    setOrgObj(null);
    setProjectObj(null);
  };

  // for orgs
  useDebounce(
    () => {
      if (orgName && orgName.length > 1) {
        onChangeOrgNameInput(orgName);
      } else {
        setAllOrgs(allOwnOrgs);
      }
    },
    1200,
    [orgName]
  );

  const onChangeOrgNameInput = async (value) => {
    await getOrgByName({ name: value, orgIds: allOrgIds })
      .then((orgs) => {
        let filteredOwnOrgs = [];
        allOwnOrgs.map((org) => {
          let name = org?.profile?.parent?.displayName;
          const patt = new RegExp(value, "i");
          const res = patt.test(name);
          if (res) {
            filteredOwnOrgs.push(org);
          }
        });

        let allOrgs = [...filteredOwnOrgs, ...orgs];

        setAllOrgs(allOrgs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectOrg = (value) => {
    if (orgObj?.profile?._id == value?.profile?._id) {
      setOrgObj(null);
    } else {
      setOrgObj(value);
    }
    setUserObj(null);
    setProjectObj(null);
  };

  // for projects
  useDebounce(
    () => {
      if (projectName && projectName.length > 1) {
        onChangeProjectNameInput(projectName);
      } else {
        setAllProjects(allOwnProjects);
      }
    },
    1200,
    [projectName]
  );

  const onChangeProjectNameInput = async (value) => {
    let filteredProjects = [];
    let ownFilteredProjects = [];

    accessibleProjects.map((projectData) => {
      let name = projectData?.profile?.parent?.displayName;
      const patt = new RegExp(value, "i");
      const res = patt.test(name);
      if (res && !allProjectIds.includes(projectData?.profile?.parent?._id)) {
        filteredProjects.push(projectData);
      }
    });

    allOwnProjects.map((projectData) => {
      let name = projectData?.profile?.parent?.displayName;
      const patt = new RegExp(value, "i");
      const res = patt.test(name);
      if (res) {
        ownFilteredProjects.push(projectData);
      }
    });

    const finalProjectArr = [...ownFilteredProjects, ...filteredProjects];
    setAllProjects(finalProjectArr);
  };

  const onSelectProject = (value) => {
    if (projectObj?.profile?._id == value?.profile?._id) {
      setProjectObj(null);
    } else {
      setProjectObj(value);
    }
    setUserObj(null);
    setOrgObj(null);
  };

  const findOrAddRel = async (profile) => {
    if (profile) {
      const relObj = {
        profile: profile?._id,
        wallet: walletId,
        addedBy: user?.profile,
        user: user?._id,
        type: tx?.type === "Invoice" ? "Customer" : "Vendor",
      };

      await findAndAddRelation(relObj)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateSelected = async () => {
    setLoadingBool(true);

    let value = null;
    if (userObj) {
      value = userObj;
    } else if (orgObj) {
      value = orgObj;
    } else if (projectObj) {
      value = projectObj;
    }

    if (value) {
      await updateApi({
        _id: tx?._id,
        secondParty: value?.profile?._id,
        secondPartyWallet: value?.wallet?._id,
      });

      const newTx = {
        ...tx,
        secondParty: value?.profile,
        secondPartyWallet: value?.wallet,
      };

      findOrAddRel(value?.profile);

      setTx(newTx);
      setOpen(false);
      setUserObj(null);
      setUserName("");
      setOrgObj(null);
      setOrgName("");
      setProjectObj(null);
      setProjectName("");
    }

    setLoadingBool(false);
  };

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={`Add Vendor`}
      content={
        <div className={classes.mainDialogCont}>
          <div style={{ padding: "15px 5px 0px" }}>
            <div className={classes.optionsCont}>
              <div className={classes.optionBtnCont}>
                <Button
                  variant={show === "user" ? "contained" : ""}
                  color={show === "user" ? "primary" : ""}
                  style={
                    show === "user"
                      ? { border: "none", padding: "3px 8px" }
                      : {}
                  }
                  onClick={() => {
                    setShow("user");
                  }}
                >
                  User
                </Button>
                <Button
                  variant={show === "project" ? "contained" : ""}
                  color={show === "project" ? "primary" : ""}
                  style={
                    show === "project"
                      ? { border: "none", padding: "3px 8px" }
                      : {}
                  }
                  onClick={() => {
                    setShow("project");
                  }}
                >
                  Project
                </Button>
                <Button
                  variant={show === "organization" ? "contained" : ""}
                  color={show === "organization" ? "primary" : ""}
                  style={
                    show === "organization"
                      ? { border: "none", padding: "3px 8px" }
                      : {}
                  }
                  onClick={() => {
                    setShow("organization");
                  }}
                >
                  {isExSmall ? "Org" : "Organization"}{" "}
                </Button>
              </div>

              <CreatePal
                tx={tx}
                setTx={setTx}
                updateApi={updateApi}
                walletId={walletId}
                findOrAddRel={findOrAddRel}
                handleClose={handleClose}
                setLoadingBool={setLoadingBool}
              />
            </div>
            {show === "user" && (
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                size="small"
                placeholder="Enter user name"
                fullWidth
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            )}
            {show === "project" && (
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                size="small"
                placeholder="Enter project name"
                fullWidth
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
              />
            )}
            {show === "organization" && (
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                size="small"
                placeholder="Enter organization name"
                fullWidth
                value={orgName}
                onChange={(e) => {
                  setOrgName(e.target.value);
                }}
              />
            )}

            <div className={classes.containerSty}>
              {show === "user" && (
                <>
                  {allUsers && allUsers.length > 0 ? (
                    <>
                      {allUsers.map((userData, i) => (
                        <div
                          className={classes.singleCard}
                          onClick={() => {
                            onSelectUser(userData);
                          }}
                          key={i}
                          style={
                            userData?.profile?._id == userObj?.profile?._id
                              ? { backgroundColor: "#e0f5fb" }
                              : {}
                          }
                        >
                          <Avatar
                            src={
                              userData?.profile?.parent?.displayPicture
                                ?.thumbUrl ||
                              userData?.profile?.parent?.displayPicture?.url
                            }
                            alt={userData?.profile?.parent?.displayName}
                            style={
                              isExSmall
                                ? { width: "30px", height: "30px" }
                                : { width: "40px", height: "40px" }
                            }
                          />
                          <div>
                            <h3>
                              <LessText
                                limit={35}
                                string={userData?.profile?.parent?.displayName}
                              />
                            </h3>
                            <p>{userData?.profile?.parent?.email}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={classes.emptyBox}>
                      <img src={emptyIcon} />
                      <p>no user is available</p>
                    </div>
                  )}
                </>
              )}

              {show === "project" && (
                <>
                  {allProjects && allProjects.length > 0 ? (
                    <>
                      {allProjects.map((projectData, i) => (
                        <div
                          className={classes.singleCard}
                          onClick={() => {
                            onSelectProject(projectData);
                          }}
                          key={i}
                          style={
                            projectData?.profile?._id ==
                            projectObj?.profile?._id
                              ? { backgroundColor: "#e0f5fb" }
                              : {}
                          }
                        >
                          <Avatar
                            src={
                              projectData?.profile?.parent?.displayPicture
                                ?.thumbUrl ||
                              projectData?.profile?.parent?.displayPicture?.url
                            }
                            alt={projectData?.profile?.parent?.displayName}
                            style={
                              isExSmall
                                ? { width: "30px", height: "30px" }
                                : { width: "40px", height: "40px" }
                            }
                          />
                          <div>
                            <h3>
                              <LessText
                                limit={35}
                                string={
                                  projectData?.profile?.parent?.displayName
                                }
                              />
                            </h3>
                            <p>{projectData?.profile?.parent?.email}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={classes.emptyBox}>
                      <img src={emptyIcon} />
                      <p>no project is available</p>
                    </div>
                  )}
                </>
              )}

              {show === "organization" && (
                <>
                  {allOrgs && allOrgs.length > 0 ? (
                    <>
                      {allOrgs.map((orgData, i) => (
                        <div
                          className={classes.singleCard}
                          onClick={() => {
                            onSelectOrg(orgData);
                          }}
                          key={i}
                          style={
                            orgData?.profile?._id == orgObj?.profile?._id
                              ? { backgroundColor: "#e0f5fb" }
                              : {}
                          }
                        >
                          <Avatar
                            src={
                              orgData?.profile?.parent?.displayPicture
                                ?.thumbUrl ||
                              orgData?.profile?.parent?.displayPicture?.url
                            }
                            alt={orgData?.profile?.parent?.displayName}
                            style={
                              isExSmall
                                ? { width: "30px", height: "30px" }
                                : { width: "40px", height: "40px" }
                            }
                          />
                          <div>
                            <h3>
                              <LessText
                                limit={35}
                                string={orgData?.profile?.parent?.displayName}
                              />
                            </h3>
                            <p>{orgData?.profile?.parent?.email}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={classes.emptyBox}>
                      <img src={emptyIcon} />
                      <p>no organization is available</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={classes.bottomAct}>
            {userObj || orgObj || projectObj ? (
              <Button
                onClick={() => {
                  updateSelected();
                }}
                variant="contained"
                size="small"
                color="primary"
              >
                Save & Close
              </Button>
            ) : (
              <Button variant="contained" size="small" color="primary" disabled>
                Save & Close
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default FinanceRelationDialog;
