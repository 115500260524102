import React, { useState } from "react";
import StandardContainer from "../../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import LabelWithSingleActionButton from "../../../../styled/generic/LabelWithSingleActionButton";
import { Add } from "@mui/icons-material";
import TabsWithCommonAction from "../../../../styled/generic/TabsWithCommonAction";
import { Box } from "@mui/material";
import EarningsTable from "./tables/EarningsTable";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import LabelWithCustomActions from "../../../../styled/generic/LabelWithCustomActions";
import Button from "../../../../styled/generic/Button";
import DeductionTable from "./tables/DeductionsTable";
import ReimbursementTable from "./tables/ReimbursementsTable";
import TaxesTable from "./tables/TaxesTable";

const SalaryComponents = () => {
  const [tab, setTab] = useState("Earnings");
  const history = useHistory();
  const { organizationId } = useParams();

  const handleCreateComponent = (type) => {
    history.push(
      `/hr/settings/${organizationId}?view=EditSalaryComponent&type=${type}`
    );
  };

  return (
    <StandardContainer>
      <LabelWithCustomActions
        label="Salary Component"
        actions={
          <Box>
            <PopupMenuList
              appearContent={
                <Button variant="contained" startIcon={<Add />}>
                  Component
                </Button>
              }
              actions={[
                {
                  title: "Earning",
                  action: () => handleCreateComponent("Earning"),
                },
                {
                  title: "Deduction (PreTax)",
                  action: () => handleCreateComponent("PreTaxDeduction"),
                },
                {
                  title: "Deduction (PostTax)",
                  action: () => handleCreateComponent("PostTaxDeduction"),
                },
                {
                  title: "Reimbursement",
                  action: () => handleCreateComponent("Reimbursement"),
                },
                {
                  title: "Taxes",
                  action: () => handleCreateComponent("Taxes"),
                },
              ]}
            />
          </Box>
        }
      />

      <StandardAppContainerRounded>
        <TabsWithCommonAction
          tabs={["Earnings", "Deduction", "Reimbursement", "Taxes"]}
          currentTabVariable={tab}
          onTabChange={(tab) => setTab(tab)}
          action={<></>}
        />
        <Box sx={{ p: 2 }}>
          {tab === "Earnings" && <EarningsTable />}
          {tab === "Deduction" && <DeductionTable />}
          {tab === "Reimbursement" && <ReimbursementTable />}
          {tab === "Taxes" && <TaxesTable />}
        </Box>
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default SalaryComponents;
