import React, { useEffect, useState } from "react";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import { TreeItem, useTreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { clsx } from "clsx";

const CustomContentRoot = styled("div")(({ theme }) => ({
  WebkitTapHighlightColor: "transparent",
  "&&:hover, &&.Mui-disabled, &&.Mui-focused, &&.Mui-selected, &&.Mui-selected.Mui-focused, &&.Mui-selected:hover":
    {
      backgroundColor: "transparent",
    },
  ".MuiTreeItem-contentBar": {
    position: "absolute",
    width: "100%",
    height: 24,
    left: 0,
  },
  "&:hover .MuiTreeItem-contentBar": {
    backgroundColor: theme.palette.action.hover,
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: "transparent",
    },
  },
  "&.Mui-disabled .MuiTreeItem-contentBar": {
    opacity: theme.palette.action.disabledOpacity,
    backgroundColor: "transparent",
  },
  "&.Mui-focused .MuiTreeItem-contentBar": {
    backgroundColor: theme.palette.action.focus,
  },
  "&.Mui-selected .MuiTreeItem-contentBar": {
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
  "&.Mui-selected:hover .MuiTreeItem-contentBar": {
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
    ),
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      ),
    },
  },
  "&.Mui-selected.Mui-focused .MuiTreeItem-contentBar": {
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity
    ),
  },
}));

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
  };

  return (
    <CustomContentRoot
      className={clsx(className, classes.root, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div className="MuiTreeItem-contentBar" />
      <div className={classes.iconContainer}>{icon}</div>
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
    </CustomContentRoot>
  );
});

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
});

const BudgetPicker = ({ open, setOpen }) => {
  const [budgetData, setBudgetData] = useState([]);

  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const getBudgets = async () => {
    try {
      const { data } = await Api.post("/procurement/ops/budget-tree", {
        userId: user?._id,
      });
      setBudgetData(data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while deleting ops order",
        },
      });
    }
  };

  useEffect(() => {
    getBudgets();
  }, []);

  return (
    <div>
      <NormalDialog
        openDialog={open}
        handleCloseShare={() => setOpen(false)}
        pageTitle="Select Budget"
        content={
          <div style={{ width: "65vh" }}>
            <Box sx={{ minHeight: 220, flexGrow: 1 }}>
              <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                multiSelect
              >
                {budgetData?.map((workHead) => (
                  <CustomTreeItem
                    key={workHead?._id}
                    nodeId={workHead?._id}
                    label={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {workHead?.name || "Untitled"}
                        <Checkbox size="small" />
                      </Box>
                    }
                  >
                    {workHead?.items?.map((workItem) => (
                      <CustomTreeItem
                        key={workItem?._id}
                        nodeId={workItem?._id}
                        label={
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {workItem?.name || "Untitled"}
                            <Checkbox size="small" />
                          </Box>
                        }
                      >
                        {workItem?.items?.map((workSubItem) => (
                          <CustomTreeItem
                            key={workSubItem?._id}
                            nodeId={workSubItem?._id}
                            label={
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {workSubItem?.name || "Untitled"}
                                <Checkbox size="small" />
                              </Box>
                            }
                          />
                        ))}
                      </CustomTreeItem>
                    ))}
                  </CustomTreeItem>
                ))}
              </TreeView>
            </Box>
            <Box display="flex" alignItems="center" justifyContenr="flex-end">
              <Button variant="contained">Save</Button>
            </Box>
          </div>
        }
        loading={false}
      />
    </div>
  );
};

export default BudgetPicker;
