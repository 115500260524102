import React, { useEffect, useState } from "react";
import StandardContainer from "../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";
import TableContainer from "../../../styled/generic/TableContainer";
import ListingTableSingleUserCell from "../../../styled/generic/ListingTableSingleUserCell";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import { makeStyles } from "@material-ui/core";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import dayjs from "dayjs";
import { Edit, EditOutlined } from "@mui/icons-material";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import _ from "lodash";
import PayrollCheckDrawer from "./PayrollCheckDrawer";
import Button from "../../../styled/generic/Button";
import ConfirmationDialog from "../../../global/ConfirmationDialog";
import HorizBox from "../../../styled/generic/HorizBox";

const useStyles = makeStyles({
  tableSty: {
    width: "auto",
    border: "none",
    backgroundColor: "#F5F7FA",
    borderCollapse: "collapse",
    position: "relative",
    "& tr": {
      height: "80px",
      marginTop: "5px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid rgba(0,0,0,0.10)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.005)",
      },
    },
    "& th": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
      backgroundColor: "#F5F7FA",
      textAlign: "left",
      fontSize: "0.85rem",
    },
    "& td": {
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "15px",
      textAlign: "left",
      verticalAlign: "center",
      fontSize: "0.9rem",
      color: "#111111",
      "&:nth-child(odd)": {
        fontWeight: "bold",
      },
      "&:nth-child(even)": {
        fontWeight: (props) => (props.boldTextEnabled ? "bold" : "normal"),
      },
    },
  },
});

const Cell = ({ children }) => {
  return <Box sx={{ width: "200px" }}>{children}</Box>;
};

const PayrollChecks = () => {
  const { payrollRunId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles({ boldTextEnabled: true });

  const [paychecks, setPaychecks] = useState([]);
  const [paychecksLoading, setPaychecksLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [showReviewDrawer, setShowReviewDrawer] = useState(false);
  const [itemToReview, setItemToReview] = useState(null);
  const [leavesRecordData, setLeavesRecordData] = useState([]);
  const [absentTimesheetRecordData, setAbsentTimesheetRecordData] = useState();
  const [halfDayTimesheetRecordData, setHalfDayTimesheetRecordData] =
    useState();
  const [loanPaymentsData, setLoanPaymentsData] = useState([]);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [itemToMarkAsPaid, setItemToMarkAsPaid] = useState(null);

  const handleReview = (item) => {
    console.log("Reviewing: ", item);
    setItemToReview(item);
    setShowReviewDrawer(true);
  };

  const getPaychecks = async () => {
    try {
      setPaychecksLoading(true);
      const { data } = await Api.post(`/payroll/paycheck/get`, {
        payrollRunId: payrollRunId,
      });
      if (data && Array.isArray(data)) {
        let _columns = [{ type: "user", label: "Employee", value: null }];
        let recordMap = {};
        let leaveMap = {};

        // Get all salary component related columns
        for (let i = 0; i < data?.length; i++) {
          let paycheck = data[i];
          let salaryDetailsRecord = paycheck?.salaryDetailsRecord;
          for (let j = 0; j < salaryDetailsRecord?.length; j++) {
            let salaryRecord = salaryDetailsRecord[j];
            let component = salaryRecord?.componentRef;
            let componentId = component?._id;
            recordMap[componentId] = salaryRecord;
          }
          let leaves = paycheck?.worker?.payrollContract?.leaves || [];
          for (let j = 0; j < leaves?.length; j++) {
            let leave = leaves[j];
            let leaveType = leave?.leave;
            leaveMap[leaveType] = leave;
          }
        }
        let salaryComponentCols = Object.entries(recordMap).map(
          ([key, value]) => {
            return {
              type: "salaryComponent",
              label: value?.componentRef?.name,
              value: key,
              data: value,
            };
          }
        );
        _columns = [..._columns, ...salaryComponentCols];
        // _columns.push({ type: "fixed", label: "TOTAL PAY", value: null });
        // _columns.push({ type: "fixed", label: "ACTIONS", value: null });
        setColumns(_columns);

        let rows = [];
        console.log("Data: ", data);

        // Process all finrels
        for (let i = 0; i < data?.length; i++) {
          let item = data[i];
          let totalSalary = 0;
          console.log("Initial salary: ", totalSalary);

          let salaryRows = [];

          // Handle salary component records
          // Basic pay
          for (let j = 0; j < salaryComponentCols?.length; j++) {
            let comp = salaryComponentCols[j];
            if (comp?.data?.componentRef?.flag === "BASIC_PAY") {
              let value = comp?.data?.amount || 0;
              totalSalary += value;
            }
          }
          console.log("Adding Basic pay: ", totalSalary);

          // PreTaxDeductions
          for (let j = 0; j < salaryComponentCols?.length; j++) {
            let comp = salaryComponentCols[j];
            if (comp?.data?.componentRef?.type === "PreTaxDeduction") {
              let value = comp?.data?.amount || 0;
              totalSalary -= value;
            }
          }
          console.log("Subtracting PreTaxDeductions: ", totalSalary);

          // Tax
          for (let j = 0; j < salaryComponentCols?.length; j++) {
            let comp = salaryComponentCols[j];
            if (comp?.data?.componentRef?.type === "Tax") {
              let value = comp?.data?.amount || 0;
              totalSalary -= value;
            }
          }
          console.log("Subtracting Tax: ", totalSalary);

          // PostTaxDeductions
          for (let j = 0; j < salaryComponentCols?.length; j++) {
            let comp = salaryComponentCols[j];
            if (comp?.data?.componentRef?.type === "PostTaxDeduction") {
              let value = comp?.data?.amount || 0;
              totalSalary -= value;
            }
          }
          console.log("Subtracting PostTaxDeductions: ", totalSalary);

          // Add other earnings
          for (let j = 0; j < salaryComponentCols?.length; j++) {
            let comp = salaryComponentCols[j];

            // Do not consider basic pay since it is already added
            if (comp?.data?.componentRef?.flag === "BASIC_PAY") {
              continue;
            }

            if (
              comp?.data?.componentRef?.type === "Earning" ||
              comp?.data?.componentRef?.type === "Reimbursement"
            ) {
              let value = comp?.data?.amount || 0;
              totalSalary += value;
            }
          }
          console.log("Adding other earnings: ", totalSalary);

          // Form salary rows
          for (let j = 0; j < salaryComponentCols?.length; j++) {
            let comp = salaryComponentCols[j];

            console.log("Item: ", item);
            console.log("Comp: ", comp);

            let searcyKey = comp?.value;
            let key = comp?.label;
            let value =
              item?.salaryDetailsRecord?.find(
                (x) => x?.componentRef?._id === searcyKey
              )?.amount || 0;

            console.log("Value: ", value);

            salaryRows.push({
              label: key,
              value: value,
              type: comp?.data?.componentRef?.type,
              _id: comp?.data?._id,
            });
          }

          // Handle loan payment deductions
          let loanPayments = item?.loanPayments;
          for (let j = 0; j < loanPayments?.length; j++) {
            let loanPayment = loanPayments[j];
            totalSalary -= loanPayment?.amount || 0;
          }

          // Handle timesheets based deductions
          let costOfOneDay = item?.costOfOneDay;
          let absentTimesheetRecord = item?.absentTimesheetRecord;
          let halfDayTimesheetRecord = item?.halfDayTimesheetRecord;

          totalSalary -= absentTimesheetRecord?.amount || 0;
          totalSalary -= halfDayTimesheetRecord?.amount || 0;

          // Handle leaves
          let leavesRecord = item?.leavesRecord || [];
          // NO NEED TO HANDLE LEAVES AS I HAVE DEDUCTED THEM USING ABSENT TIMESHEET RECORDS
          // for (let j = 0; j < leavesRecord?.length; j++) {
          //   let leavesRecordItem = leavesRecord[j];
          //   totalSalary -= leavesRecordItem?.amount || 0;
          // }

          if (totalSalary < 0) {
            totalSalary = 0;
          }

          rows.push({
            worker: item?.worker,
            salaryData: salaryRows,
            totalSalary: totalSalary,
            costOfOneDay: costOfOneDay,
            leavesRecord,
            absentTimesheetRecord,
            halfDayTimesheetRecord,
            _id: item?._id,
            isReviewed: item?.isReviewed,
            status: item?.status,
            loanPayments: item?.loanPayments,
          });

          setLeavesRecordData(leavesRecord);
          setAbsentTimesheetRecordData(absentTimesheetRecord);
          setHalfDayTimesheetRecordData(halfDayTimesheetRecord);
          setLoanPaymentsData(loanPayments);
        }
        console.log("Rows: ", rows);
        setPaychecks(rows);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch payrolls",
        },
      });
    } finally {
      setPaychecksLoading(false);
    }
  };

  useEffect(() => {
    getPaychecks();
  }, []);

  const markPaycheckAsPaid = async () => {
    try {
      const { data } = await Api.post(`/payroll/paycheck/pay`, {
        paycheckId: itemToMarkAsPaid?._id,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Paycheck marked as paid",
          },
        });
        setPaychecks(
          paychecks.map((x) =>
            x._id === itemToMarkAsPaid._id ? { ...x, status: "Paid" } : x
          )
        );
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to mark paycheck as paid",
        },
      });
    }
  };

  return (
    <StandardContainer showAppBar={true} appBarTitle="Regular Payrolls">
      <StandardAppContainerRounded>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {paychecksLoading ? (
            <Box
              width="100%"
              height="400px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box style={{ position: "relative", width: "100%" }}>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  minHeight: "400px",
                  overflowX: "auto",
                }}
              >
                {!paychecks || !Array.isArray(paychecks) ? (
                  <Box
                    width="100%"
                    height="400px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No results found
                  </Box>
                ) : (
                  <table className={classes.tableSty}>
                    <tr
                      style={{
                        height: "40px",
                        borderBottom: "none",
                      }}
                    >
                      {columns.map((col, index) => (
                        <th>
                          <Cell>{col?.label}</Cell>
                        </th>
                      ))}
                    </tr>
                    {paychecks?.map((item) => (
                      <tr>
                        <td>
                          <Cell>
                            <SingleUserWithCaptionTableCell
                              label={
                                item?.worker?.mainProfile?.parent?.displayName
                              }
                              caption={`${item?.worker?.firstPartyContact?.department?.displayName} ${item?.worker?.firstPartyContact?.designation?.name}`}
                              url={
                                item?.worker?.mainProfile?.parent
                                  ?.displayPicture?.url
                              }
                            />
                          </Cell>
                        </td>
                        {item?.salaryData?.map((salary) => (
                          <td>
                            <Cell>₹ {salary?.value}</Cell>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                )}
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  boxShadow: "2px 0px 8px 0px rgba(86, 86, 86, 0.25)",
                  borderLeft: "1px solid #EBECED",
                }}
              >
                <table className={classes.tableSty}>
                  <tr style={{ height: "40px", borderBottom: "none" }}>
                    <th>
                      <Cell>TOTAL PAY</Cell>
                    </th>
                    <th>
                      <Cell>ACTIONS</Cell>
                    </th>
                  </tr>
                  {paychecks?.map((item) => (
                    <tr>
                      <td>
                        <Cell>₹ {item?.totalSalary}</Cell>
                      </td>
                      <td>
                        <Cell>
                          <HorizBox spacing={1}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleReview(item);
                              }}
                              disabled={item?.status === "Paid"}
                            >
                              Review
                            </Button>

                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setItemToMarkAsPaid(item);
                                setShowConfirmationDialog(true);
                              }}
                              disabled={
                                item?.status === "Paid" || !item?.isReviewed
                              }
                            >
                              {item?.status === "Unpaid" ? "Pay?" : "Paid"}
                            </Button>
                          </HorizBox>
                        </Cell>
                      </td>
                    </tr>
                  ))}
                </table>
              </Box>
            </Box>
          )}
        </Box>
      </StandardAppContainerRounded>
      <PayrollCheckDrawer
        open={showReviewDrawer}
        setOpen={setShowReviewDrawer}
        finrel={itemToReview}
        setFinrel={setItemToReview}
        leavesRecord={leavesRecordData}
        setLeavesRecord={setLeavesRecordData}
        absentTimesheetRecord={absentTimesheetRecordData}
        setAbsentTimesheetRecord={setAbsentTimesheetRecordData}
        halfDayTimesheetRecord={halfDayTimesheetRecordData}
        setHalfDayTimesheetRecord={setHalfDayTimesheetRecordData}
        loanPayments={loanPaymentsData}
        onPaycheckReviewed={getPaychecks}
      />
      <ConfirmationDialog
        title="Mark as Paid"
        message="Are you sure you want to mark this paycheck as paid?"
        successButtonText="Yes"
        cancelButtonText="Cancel"
        successListener={() => {
          markPaycheckAsPaid();
        }}
        cancelListener={() => {}}
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
      />
    </StandardContainer>
  );
};

export default PayrollChecks;
