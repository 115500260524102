import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "3px 5px",
        borderBottom: "1px solid #ececec"
    },
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400"
        }
    }
}));

function SimpleUserCardSkeleton() {
    const classes = useStyles();
    return (
        <div className={classes.mainCont} >
            <Skeleton
                variant="circle"
                style={{
                    width: "35px",
                    height: "35px",
                    marginRight: "5px"
                }}
            />
            <div className={classes.detailsCont} >
                <Skeleton style={{ width: "100%", height: "30px" }} variant="text" />
                <Skeleton style={{ width: "100%", height: "20px" }} variant="text" />
            </div>
        </div>
    );
}
export default SimpleUserCardSkeleton; 