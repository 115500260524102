import { ExpandMoreSharp, Search } from "@material-ui/icons";
import {
  Box,
  Collapse,
  IconButton,
  InputAdornment,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import LessText from "../../styled/CommonComponents/LessText";
import Inbound from "../../../Assets/box.svg";
import Onsite from "../../../Assets/box.svg";
import Used from "../../../Assets/arrow.svg";
import Return from "../../../Assets/refresh.svg";
import Wasted from "../../../Assets/arrow.svg";
import Total from "../../../Assets/card.svg";
import TextField from "../../styled/generic/TextField";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton size="small" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginBottom: "0.5rem",
  },
  stockDropbox: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "0.5rem",
    padding: "10px",
    gap: "1.3rem",
    backgroundColor: "#fff",
    marginBottom: "10px",
    borderLeft: "8px solid #3b82f6",
  },
  stockData: {
    color: "#52525b",
    fontSize: "0.95rem",
    fontWeight: 500,
  },
  stockDataQty: {
    color: "#52525b",
    fontSize: "0.95rem",
    fontWeight: 500,
  },
  stockDataCost: {
    color: "#52525b",
    fontSize: "0.95rem",
    fontWeight: 500,
  },
}));

const InventoryStocks = ({
  stocks,
  stockSearchQuery,
  setStockSearchQuery,
  selectedStock,
  setSelectedStock,
  setShouldRefreshStockMoves,
  loading,
}) => {
  const classes = useStyles();
  const [expandedStocks, setExpandedStocks] = useState({});

  const handleStockExpand = (index) => {
    setExpandedStocks((prevState) => ({
      ...prevState,
      [index]: !prevState[index] || false,
    }));
  };

  const [totalCost, setTotalCost] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  const calculateTotal = () => {
    let total = 0;
    let qty = 0;

    for (let i = 0; i < stocks.length; i++) {
      let stock = stocks[i];
      total +=
        (stock?.inboundCost || 0) +
        (stock?.onsiteCost || 0) +
        (stock?.usedCost || 0) +
        (stock?.returnCost || 0) +
        (stock?.wastedCost || 0);

      qty +=
        (stock?.inboundQuantity || 0) +
        (stock?.onsiteQuantity || 0) +
        (stock?.usedQuantity || 0) +
        (stock?.returnQuantity || 0) +
        (stock?.wastedQuantity || 0);
    }

    setTotalCost(total);
    setTotalQty(qty);
  };

  useEffect(() => {
    calculateTotal();
  }, [stocks]);

  return (
    <div>
      <div className={classes.header}>
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            gap: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedStock(null);
            setShouldRefreshStockMoves(true);
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            All Stocks
          </Typography>
          <img style={{ width: "1.1rem" }} src="/icons/arrow-down.svg" />
        </div>
        {stocks?.length > 0 && (
          <div style={{ display: "flex", gap: "1rem", fontWeight: "500" }}>
            <Typography variant="subtitle2">
              Total quantity : {totalQty}
            </Typography>
            <Typography variant="subtitle2">
              Total price : ₹ {totalCost}
            </Typography>
          </div>
        )}
      </div>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search"
        size="small"
        value={stockSearchQuery}
        onChange={(e) => setStockSearchQuery(e.target.value)}
        style={{ backgroundColor: "#fff" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {loading ? (
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((d, i) => (
          <Box key={i} sx={{ padding: "5px 0px" }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={45}
              sx={{ opacity: "0.5" }}
            />
          </Box>
        ))
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "5px 0px 10px 0px",
            }}
          >
            {/* <div>
              {stocks?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Total}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "5px",
                    }}
                  />
                  <span style={{ color: "#52525b", fontSize: "0.85rem" }}>
                    TOTAL: ₹{totalCost} | {totalQty} Units
                  </span>
                </div>
              )}
            </div> */}
            {/* <span
              style={{
                fontSize: "0.8rem",
                cursor: "pointer",
                color: "#2D76E0",
                marginLeft: "auto",
              }}
              onClick={() => {
                setSelectedStock(null);
                setShouldRefreshStockMoves(true);
              }}
            >
              Show All
            </span> */}
          </div>
          {stocks?.map((stock, index) => (
            <Box
              key={index}
              sx={{
                "&:hover": {
                  backgroundColor: "#e0f2fe",
                },
              }}
              className={classes.stockDropbox}
              onClick={() => {
                {
                  setSelectedStock(stock);
                  setShouldRefreshStockMoves(true);
                }
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <Typography style={{ fontWeight: "500" }} variant="subtitle1">
                    <LessText
                      limit={20}
                      string={`${String(stock?.product?.name)}`}
                    />
                  </Typography>
                  <Box>
                    <Typography variant="subtitle2">{`Total quantity: ${
                      stock?.onsiteQuantity || 0
                    } Total quantity: ₹ ${stock?.onsiteCost || 0}`}</Typography>
                  </Box>
                </div>
                <ExpandMore
                  expand={expandedStocks[index]}
                  onClick={() => handleStockExpand(index)}
                  aria-expanded={expandedStocks[index]}
                  aria-label="show more"
                >
                  <ExpandMoreSharp />
                </ExpandMore>
              </Box>

              <Collapse in={expandedStocks[index]} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0.5",
                    }}
                  >
                    <img
                      src={Inbound}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <span className={classes.stockDataQty}>
                        Inbound ({stock?.inboundQuantity})
                      </span>
                      <span className={classes.stockDataCost}>
                        &nbsp; ₹ {stock?.inboundCost || 0}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0.5",
                    }}
                  >
                    <img
                      src={Onsite}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <span className={classes.stockDataQty}>
                        Onsite ({stock?.onsiteQuantity})
                      </span>
                      <span className={classes.stockDataCost}>
                        &nbsp; ₹ {stock?.onsiteCost || 0}
                      </span>
                    </div>
                  </div>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0.5",
                    }}
                  >
                    <img
                      src={Used}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <span className={classes.stockDataQty}>
                        Used ({stock?.usedQuantity})
                      </span>
                      <span className={classes.stockDataCost}>
                        &nbsp; ₹ {stock?.usedCost || 0}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0.5",
                    }}
                  >
                    <img
                      src={Return}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <span className={classes.stockDataQty}>
                        Return ({stock?.returnQuantity})
                      </span>
                      <span className={classes.stockDataCost}>
                        &nbsp; ₹ {stock?.returnCost || 0}
                      </span>
                    </div>
                  </div>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0.5",
                    }}
                  >
                    <img
                      src={Wasted}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <span className={classes.stockDataQty}>
                        Wasted ({stock?.wastedQuantity})
                      </span>
                      <span className={classes.stockDataCost}>
                        &nbsp; ₹ {stock?.wastedCost || 0}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "0.5",
                    }}
                  >
                    <img
                      src={Total}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <span className={classes.stockDataQty}>
                        Total (
                        {(stock?.inboundQuantity || 0) +
                          (stock?.onsiteQuantity || 0) +
                          (stock?.usedQuantity || 0) +
                          (stock?.returnQuantity || 0) +
                          (stock?.wastedQuantity || 0)}
                        )
                      </span>
                      <span className={classes.stockDataCost}>
                        &nbsp; ₹
                        {(stock?.inboundCost || 0) +
                          (stock?.onsiteCost || 0) +
                          (stock?.usedCost || 0) +
                          (stock?.returnCost || 0) +
                          (stock?.wastedCost || 0)}
                      </span>
                    </div>
                  </div>
                </Box>

                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="caption">
                    {`Inbound Cost: ₹${stock?.inboundCost || 0} / ${
                      stock?.inboundQuantity || 0
                    }`}
                  </Typography>
                  <Typography variant="caption">
                    {`Onsite Cost: ₹${stock?.onsiteCost || 0} / ${
                      stock?.onsiteQuantity || 0
                    }`}
                  </Typography>
                  <Typography variant="caption">
                    {`Used Cost: ₹${stock?.usedCost || 0} / ${
                      stock?.usedQuantity || 0
                    }`}
                  </Typography>
                  <Typography variant="caption">
                    {`Return Cost: ₹${stock?.returnCost || 0} / ${
                      stock?.returnQuantity || 0
                    }`}
                  </Typography>
                  <Typography variant="caption">
                    {`Wasted Cost: ₹${stock?.wastedCost || 0} / ${
                      stock?.wastedQuantity || 0
                    }`}
                  </Typography>{" "}
                </Box> */}
              </Collapse>
            </Box>
          ))}
        </div>
      )}
    </div>
  );
};

export default InventoryStocks;
