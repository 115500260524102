import { Box, Typography } from "@mui/material";

import React from "react";

const FormBox = ({
  label,
  labelTypographyVariant = "h6",
  children,
  disableMargins,
  labelColor = "auto",
  hideLabel = false,
  fontWeight = "normal",
  sx = {},
  ...props
}) => {
  return (
    <Box width="100%" sx={{ mb: disableMargins ? 0 : 3, ...sx }} {...props}>
      {!hideLabel && (
        <Typography
          color={labelColor}
          variant={labelTypographyVariant}
          gutterBottom
          sx={{fontWeight: fontWeight}}
        >
          {label}
        </Typography>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export default FormBox;
