import React, { useState, useRef, useEffect } from "react";
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import BudgetUpdateBody from "./BudgetUpdateBody";

const useStyles = makeStyles((theme) => ({
    dialogBody: {
        padding: "10px 10px 0px",
        width: "450px",
        "& p": {
            fontSize: "14px",
            marginBottom: "20px"
        },
    },
    closeCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "7px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "500",
            width: "85px",
            color: "red"
        },
        "& p": {
            width: "calc(100% - 85px)",
            marginBottom: "0px"
        }
    },
    continueCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "15px",
            fontWeight: "500",
            width: "85px",
        },
        "& p": {
            width: "calc(100% - 85px)",
            marginBottom: "0px"
        }
    },
    btnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "10px",
        marginBottom: "-5px",
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    }
}));

const BudgetUpdateSingleItem = ({
    item, selectedBudgetId, handleCheck, idAmountMap, setIdAmountMap,
    amountChangeFlag, setAmountChangeFlag, parentChildrenMap, idBudgetItemMap
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [openWraning, setOpenWraning] = useState(false)
    const [clickedInputRef, setClickedInputRef] = useState(null)
    const [isBlur, setIsBlur] = useState(false)
    const [isBlurStateChange, setIsBlurStateChange] = useState(false)
    const [currentBudgetItem, setCurrentBudgetItem] = useState(null)
    const [closeBudgetIds, setCloseBudgetIds] = useState([])
    const [openBudgetIds, setOpenBudgetIds] = useState([])

    useEffect(() => {
        if (isBlur) {
            if (clickedInputRef && clickedInputRef.current) {
                clickedInputRef.current.blur();
            }
        } else {
            if (clickedInputRef && clickedInputRef.current) {
                clickedInputRef.current.focus();
            }
        }
    }, [isBlur, isBlurStateChange])

    const onClickAnyInput = (curBudget) => {
        setCurrentBudgetItem(curBudget)
        const currentId = curBudget?._id;

        if (closeBudgetIds.includes(currentId)) {
            setOpenWraning(true)
        } else if (!openBudgetIds.includes(currentId)) {
            onClickContinue(curBudget)
        } else {
            setIsBlur(false)
            setIsBlurStateChange(!isBlurStateChange)
        }
    }

    const onClickContinue = (curBudget) => {
        setAmountChangeFlag(!amountChangeFlag)
        let currentId = curBudget?._id;
        let oldOpenBudgetIdsSet = new Set([...openBudgetIds, currentId]);
        let oldOpenBudgetIds = [...oldOpenBudgetIdsSet];
        let allAncestors = curBudget?.ancestors || [];
        let allChildren = parentChildrenMap[currentId] || [];
        let curAllCloseIdsSet = new Set([...allAncestors, ...allChildren]);
        let curAllCloseIds = [...curAllCloseIdsSet];
        let curOpen = oldOpenBudgetIds.filter(item => !curAllCloseIds.includes(item));
        let closeAbleItems = []
        curOpen.map((itemId) => {
            let currentBudgetItem = idBudgetItemMap[itemId];
            let locAncestors = currentBudgetItem?.ancestors || [];
            let locChildren = parentChildrenMap[itemId] || [];
            closeAbleItems = [...closeAbleItems, ...locAncestors, ...locChildren]
        })
        let updatedCloseIdsSet = new Set([...closeAbleItems]);
        let updatedCloseIdsArr = [...updatedCloseIdsSet]
        let updatedIdAmountMap = {}
        selectedBudgetId.map((id) => {
            if (updatedCloseIdsArr.includes(id)) {
                updatedIdAmountMap[id] = 0
            } else {
                updatedIdAmountMap[id] = idAmountMap[id] === "" || idAmountMap[id] === null ? 0 : idAmountMap[id]
            }
        })
        setIdAmountMap(updatedIdAmountMap)
        setCloseBudgetIds(updatedCloseIdsArr)
        setOpenBudgetIds(curOpen)
        setIsBlur(false)
        setIsBlurStateChange(!isBlurStateChange)
        setOpenWraning(false)
        setAmountChangeFlag(!amountChangeFlag)
    }

    const onClickCancle = () => {
        setOpenWraning(false)
        setIsBlur(true)
        setIsBlurStateChange(!isBlurStateChange)
    }

    return (<>
        <BudgetUpdateBody
            item={item}
            selectedBudgetId={selectedBudgetId}
            handleCheck={handleCheck}
            idAmountMap={idAmountMap}
            setIdAmountMap={setIdAmountMap}
            setClickedInputRef={setClickedInputRef}
            amountChangeFlag={amountChangeFlag}
            setAmountChangeFlag={setAmountChangeFlag}
            onClickAnyInput={onClickAnyInput}
            closeBudgetIds={closeBudgetIds}
        />

        <NormalDialog
            openDialog={openWraning}
            hideCrossIcon={true}
            handleCloseShare={() => { }}
            pageTitle={"Warning"}
            content={<>
                <div className={classes.dialogBody} >
                    <p>You have to enter value in the same level for a single budget item group</p>
                    <div className={classes.closeCont} >
                        <h3>Close</h3>
                        <p>It will remain the same, and you will be able to enter values at the same level.</p>
                    </div>
                    <div className={classes.continueCont} >
                        <h3>Continue</h3>
                        <p>It will remove amounts from all of the other levels and allow you to enter amounts in the currently clicked level.</p>
                    </div>
                </div>
                <div className={classes.btnCont} >
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={onClickCancle}
                        style={{ marginRight: "7px" }}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => { onClickContinue(currentBudgetItem) }}
                    >
                        Continue
                    </Button>
                </div>
            </>}
        />
    </>);
};

export default BudgetUpdateSingleItem;