import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExpandMoreSharp,
  Search,
  Add,
  Card,
  MoreVert,
  Filter,
  Sort,
} from "@material-ui/icons";
import TableContainer from "../../styled/generic/TableContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import TableActionsContainer from "../../styled/generic/TableActionsContainer";
import TextField from "../../styled/generic/TextField";
import PopupMenuList from "../../styled/generic/PopupMenuList";
import MoreButton from "../../styled/generic/MoreButton";
import { useDebounce } from "react-use";

const WarehouseView = ({
  setWarehouse,
  setShowWarehousesMenu,
  setShowConfigureWarehouseDialog,
  warehouses,
}) => {
  const [filteredWarehouses, setFilteredWarehouses] = useState([...warehouses]);
  const [searchTerm, setSearchTerm] = useState("");

  const filterWarehouses = () => {
    if (!searchTerm || searchTerm === "") {
      setFilteredWarehouses([...warehouses]);
    } else {
      setFilteredWarehouses(
        warehouses.filter((warehouse) =>
          warehouse?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  useDebounce(
    () => {
      filterWarehouses();
    },
    500,
    [searchTerm]
  );

  return (
    <StandardAppContainerRounded>
      <Box>
        <TableActionsContainer
          left={<Typography variant="h6">All Warehouses</Typography>}
          right={
            <Box display="flex" alignItems="center">
              <TextField
                sx={{ width: 250 }}
                variant="outlined"
                placeholder="Search..."
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Button
                variant="contained"
                color="primary"
                disableElevation
                endIcon={<Add />}
                sx={{ ml: 2 }}
                onClick={() => {
                  setWarehouse(null);
                  setShowWarehousesMenu(false);
                  setShowConfigureWarehouseDialog(true);
                }}
              >
                Create
              </Button>
            </Box>
          }
        />
        <div>
          <Box>
            <TableContainer
              columns={["Name", "Project", "Organization", ""]}
              data={warehouses}
            >
              {filteredWarehouses?.map((warehouse) => (
                <tr>
                  <td>{warehouse?.name || "Untitled"}</td>
                  <td>{warehouse?.project?.displayName || "-"}</td>
                  <td>{warehouse?.organization?.displayName || "Untitled"}</td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mr: 2,
                      }}
                    >
                      <PopupMenuList
                        appearContent={<MoreButton />}
                        actions={[
                          {
                            title: "Edit",
                            action: () => {
                              setWarehouse(warehouse);
                              setShowConfigureWarehouseDialog(true);
                            },
                          },
                          {
                            title: "Delete",
                            action: () => {},
                          },
                        ]}
                      />
                    </Box>
                  </td>
                </tr>
              ))}
            </TableContainer>
          </Box>
        </div>
      </Box>
    </StandardAppContainerRounded>
  );
};

export default WarehouseView;
