import {
  Box,
  DialogContent,
  IconButton,
  Dialog as MuiDialog,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import StandardDialogContainer from "./StandardDialogContainer";
import { Clear } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({ open, setOpen, children, title, hideCrossIcon }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiDialog
      open={open}
      setOpen={setOpen}
      TransitionComponent={Transition}
      keepMounted
      disableEnforceFocus
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: "12px",
          maxWidth: "90vw",
        },
      }}
    >
      <DialogContent>
        <StandardDialogContainer>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" style={{ fontWeight: 600 }}>
              {title}
            </Typography>
            {hideCrossIcon === true ? (
              <Box></Box>
            ) : (
              <IconButton color="primary" onClick={() => handleClose()}>
                <Clear />
              </IconButton>
            )}
          </Box>

          <Box sx={{ mt: 2 }}>{children}</Box>
        </StandardDialogContainer>
      </DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
