import React from "react";
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
    labelSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "13px",
            color: "Gray",
            fontWeight: "500",
            marginLeft: "5px"
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
        }
    },
    resourceTextSty: {
        fontSize: "11px",
        color: "gray",
        fontWeight: "300",
    },
    resourceIconSty: {
        fontSize: "15px",
        color: "gray"
    },
}));

const BudgetUpdateWorkitemBody = ({
    workitemData, i, j, k, selectedBudgetId, setSelectedBudgetId,
    parentChildrenMap, handleCheck
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    return (<StyledTreeItem
        nodeId={`${workitemData?.name}-${i}-${j}-${k}`}
        label={<div className={classes.labelSty} >
            <Checkbox
                color="primary"
                checked={workitemData?._id && selectedBudgetId.includes(workitemData?._id)}
                onChange={(event) => {
                    handleCheck(workitemData?._id, event.target.checked)
                }}
            />
            <p>
                {workitemData?.name || ""}
            </p>
        </div>}
    >
        {workitemData.resources && workitemData.resources.map((resource, t) => (
            <StyledTreeItem
                key={t}
                nodeId={`${resource?.resource?.name}-${i}-${j}-${k}-${t}`}
                icon={<ArrowRightAltIcon className={classes.resourceIconSty} />}
                label={<div>
                    <p className={classes.resourceTextSty} >{resource?.resource?.name}</p>
                </div>}
            />
        ))}
        {workitemData.children && workitemData.children.map((subWorkitemData, p) => (
            <BudgetUpdateWorkitemBody
                key={p}
                workitemData={subWorkitemData}
                i={i}
                j={j}
                k={p * k}
                parentChildrenMap={parentChildrenMap}
                selectedBudgetId={selectedBudgetId}
                setSelectedBudgetId={setSelectedBudgetId}
                handleCheck={handleCheck}
            />
        ))}
    </StyledTreeItem>);
};

export default BudgetUpdateWorkitemBody;