import React, { useState, useRef, useEffect } from "react";
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';
import TextFieldNumberFormated from "../styled/CommonComponents/TextFieldNumberFormated";
import { useDebounce } from "react-use";

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
    labelSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& p": {
            fontSize: "13px",
            color: "Gray",
            fontWeight: "500",
            marginLeft: "5px"
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "7px 10px",
            fontSize: "14px"
        }
    },
    checkAndNameCont: {
        width: "calc(100% - 230px)",
        display: "flex",
        alignItems: "center",
    },
    resourceTextSty: {
        fontSize: "11px",
        color: "gray",
        fontWeight: "300",
    },
    resourceIconSty: {
        fontSize: "15px",
        color: "gray"
    },
    inputSty: {
        width: "200px"
    },
    customInputSty: {
        width: "206px",
        borderRadius: "4px",
        opacity: "0.5",
        border: "1px solid #cccccc",
        padding: "2px 8px"
    },
}));

const BudgetUpdateBody = ({
    item, selectedBudgetId, handleCheck, idAmountMap, setIdAmountMap, setClickedInputRef,
    amountChangeFlag, setAmountChangeFlag, onClickAnyInput, closeBudgetIds
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [amount, setAmount] = useState(0)
    const [activeInput, setActiveInput] = useState(false)

    useDebounce(() => {
        if (idAmountMap && item?._id && idAmountMap[item?._id]) {
            setAmount(idAmountMap[item?._id]);
        }
    }, 3000, [idAmountMap])

    const handleLableClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    useDebounce(() => {
        if (activeInput) {
            setAmountChangeFlag(!amountChangeFlag)
            let locIdAmountMap = { ...idAmountMap }
            locIdAmountMap[item?._id] = amount ? amount : 0;
            setIdAmountMap(locIdAmountMap)
            setAmountChangeFlag(!amountChangeFlag)
        }
    }, 2000, [amount])

    return (<StyledTreeItem
        nodeId={item?.nodeId}
        onLabelClick={handleLableClick}
        label={<div className={classes.labelSty} >
            <div className={classes.checkAndNameCont} >
                <Checkbox
                    color="primary"
                    checked={item?._id && selectedBudgetId ? selectedBudgetId.includes(item?._id) : false}
                    onChange={(event) => {
                        handleCheck(item?._id, event.target.checked)
                    }}
                />
                <p>
                    {item?.name || ""}
                </p>
            </div>

            <div onClick={()=>{ setActiveInput(true) }} >
                {!closeBudgetIds.includes(item?._id) ? (
                    <TextFieldNumberFormated
                        value={amount}
                        inputRef={ref}
                        onChange={(e) => {
                            setAmount(e.target.value)
                            setClickedInputRef(ref)
                            onClickAnyInput(item)
                            setAmountChangeFlag(!amountChangeFlag)
                        }}
                        id={`id-${item?._id}`}
                        placeholder="Amount"
                        variant="outlined"
                        size="small"
                        className={classes.inputSty}
                    />
                ) : (<div
                    className={classes.customInputSty}
                    onClick={() => {
                        setClickedInputRef(ref)
                        onClickAnyInput(item)
                        setAmountChangeFlag(!amountChangeFlag)
                    }}
                >
                    ₹{item?._id && idAmountMap ? idAmountMap[item?._id] : 0}
                </div>)}
            </div>
        </div>}
    >
        {item.children && item.children.map((item, p) => (
            <BudgetUpdateBody
                item={item}
                key={p}
                selectedBudgetId={selectedBudgetId}
                handleCheck={handleCheck}
                idAmountMap={idAmountMap}
                setIdAmountMap={setIdAmountMap}
                setClickedInputRef={setClickedInputRef}
                amountChangeFlag={amountChangeFlag}
                setAmountChangeFlag={setAmountChangeFlag}
                onClickAnyInput={onClickAnyInput}
                closeBudgetIds={closeBudgetIds}
            />
        ))}
    </StyledTreeItem>);
};

export default BudgetUpdateBody;