import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmationDialog({
  title,
  message,
  successButtonText,
  cancelButtonText,
  successListener,
  cancelListener,
  open,
  setOpen,
}) {
  const handleCancel = () => {
    cancelListener();
    setOpen(false);
  };

  const handleSuccess = () => {
    successListener();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{cancelButtonText}</Button>
          <Button onClick={handleSuccess} autoFocus>
            {successButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
