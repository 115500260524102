import { Box } from "@mui/material";
import React from "react";

const AppBar = ({ children, sx }) => {
  return (
    <Box
      sx={{
        padding: "10px",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.04)",
        backgroundColor: "#FFFFFF",
        zIndex: 999,
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

export default AppBar;
