import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Popover,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { DataGrid } from "@mui/x-data-grid";
import Api from "../../../helpers/Api";
import PropTypes from "prop-types";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { LoadingButton } from "@mui/lab";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ScreenShareOutlinedIcon from "@material-ui/icons/ScreenShareOutlined";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import OpsOrderList from "./OpsOrderList";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  Add,
  Filter,
  FilterList,
  KeyboardArrowDown,
  Search,
} from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import OrgProjectPickerDialog from "./OrgProjectPickerDialog";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";

const OpsfilterValue = [
  "All",
  "Material Request",
  "Bill Of Quantities",
  "Purchase Order",
  "Work Order",
  "GRN",
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
    padding: "0px 5px",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  singleOption: {
    display: "flex",
    flexDirection: "column",
    padding: "5px",
    alignItems: "left",
    cursor: "pointer",
  },
}));

const Procurement = ({ fromComponent, type, opsProp }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [opsType, setOpsType] = useState();
  const [filteredOpsOrderType, setFilteredOpsOrderType] = useState(
    OpsfilterValue[0]
  );

  const projectId = new URLSearchParams(location.search).get("projectId");
  const organizationId = new URLSearchParams(location.search).get(
    "organizationId"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const createOpsOrder = async (data) => {
    try {
      const { opsOrder, billList } = await Api.post("/procurement/ops/create", {
        profileId: user.profile,
        userId: user._id,
        projectId: data?.project?._id,
        projectBlockId: data?.projectBlock?._id,
        organizationId: data?.organization?._id,
        opsType: opsType,
        selectedWarehouse: data?.selectedWarehouse?._id,
      });

      if (opsType === "GRN") {
        history.push(`/procurements/grn/${opsOrder._id}`);
      } else {
        history.push(`/procurements/ops-order/${opsOrder._id}/${billList._id}`);
      }
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "OPS Order created successfully",
        },
      });
    } catch (err) {
      console.log("Error: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An error occurred while creating ops order",
        },
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);

  const handleShowFilterDialog = (event) => {
    setShowFilterPopover(true);
    // setAnchorEl(event.target);
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setShowFilterPopover(false);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const [opsTypeAnchorEl, setOpsTypeAnchorEl] = useState(null);
  const [showOpsTypes, setShowOpsTypes] = useState(null);
  const [opsTypes] = useState([
    "Material Request",
    "Bill Of Quantities",
    "Purchase Order",
    "Work Order",
    "GRN",
  ]);

  const handleOpsTypesMenuClose = () => {
    setOpsTypeAnchorEl(null);
    setShowOpsTypes(null);
  };

  const handleChangeFilteredOpsOrder = async (value) => {
    setFilteredOpsOrderType(value);
  };

  useEffect(() => {
    if (fromComponent && type) {
      handleChangeFilteredOpsOrder(type);
      console.log("Type changed: ", type);
    }
  }, [type]);

  return (
    <StandardAppContainerRounded>
      <div className={classes.root}>
        <div className={classes.topBar}>
          <div className={classes.barLeft}>
            {!fromComponent && (
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
              >
                <KeyboardBackspaceIcon className={classes.backArrow} />
              </IconButton>
            )}
            <Typography className={classes.headerTitle}>
              {type ? type : "Procurements"}
            </Typography>
          </div>
          <div className={classes.barRight}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            {!fromComponent && (
              <IconButton
                aria-label="filter"
                onClick={(e) => handleShowFilterDialog(e)}
              >
                <FilterList />
              </IconButton>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "5px" }}
              disableElevation
              endIcon={fromComponent ? <AddIcon /> : <ExpandMoreIcon />}
              onClick={(e) => {
                if (opsProp) {
                  setOpsType(opsProp);
                  setShowCreateDialog(true);
                } else {
                  setOpsTypeAnchorEl(e.currentTarget);
                  setShowOpsTypes(true);
                }
              }}
            >
              Create
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={opsTypeAnchorEl}
              open={showOpsTypes}
              onClose={handleOpsTypesMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {opsTypes.map((item, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    setOpsType(item);
                    setShowCreateDialog(true);
                    handleOpsTypesMenuClose();
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>

        <Box sx={{ width: "100%" }}>
          <OpsOrderList
            filteredOpsOrderType={filteredOpsOrderType}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Box>
      </div>
      <OrgProjectPickerDialog
        open={showCreateDialog}
        setOpen={setShowCreateDialog}
        defaultProjectId={projectId}
        defaultOrganizationId={organizationId}
        callback={(data) => createOpsOrder(data)}
        type={opsType}
      />
      <Popover
        open={showFilterPopover}
        anchorEl={anchorEl}
        // onClose={() => setAnchorEl()}
        onClose={handleClosePopover}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "left",
        // }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          {/* <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              /> */}
          {OpsfilterValue?.map((values, i) => (
            <div key={i} className={classes.singleOption}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredOpsOrderType.includes(values)}
                    onChange={() => {
                      handleChangeFilteredOpsOrder(values);
                    }}
                    color="primary"
                  />
                }
                label={values}
              />
            </div>
          ))}
        </Box>
      </Popover>
    </StandardAppContainerRounded>
  );
};

export default Procurement;
