import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";
import { setActivities } from "./activity.utils";

export const useGetUserNotification = () => {
	const dispatch = useDispatch();
	const activity = useSelector((state) => state.activity);
	const { user } = useSelector((state) => state.auth);


	useEffect(() => {
		if (user && user._id) {
			Api.post("notification/get", {
				profile: user?.profile,
			}).then((res) => {
				const notifications = res?.data;
				console.log(notifications,' yeh notifications aaya')
				const {
					newDict, idArr
				} = arrayToReducer(notifications)

				dispatch({
					type: "AddActivity",
					payload: {
						topNotificationIds: idArr,
						activityDictionary:newDict
					},
				});
			});
		}
	}, [ user]);


};

export const useGetTeamActivities = (teamIds, limit, skip) => {
	const dispatch = useDispatch();
	const activity = useSelector((state) => state.activity);
	const [actIds, setActIds] = useState([]);

	useEffect(() => {
		if (teamIds && teamIds.length > 0) {
			Api.post("activity/team", {
				teamIds,
				limit,
				skip,
			}).then((activities) => {
				if (activities && activities.length > 0) {
					//   console.log({ activities });
					const activityIds = setActivities(
						activities,
						activity,
						dispatch
					);
					setActIds(activityIds);
				}
			});
		}
	}, [teamIds, limit, skip]);

	return actIds;
};

export const useGetMyActivities = (limit, skip) => {
	const dispatch = useDispatch();
	const [actIds, setActIds] = useState([]);
	const activity = useSelector((state) => state.activity);
	const { user } = useSelector((state) => state.auth);
	const userId = user._id;

	useEffect(() => {
		if (userId) {
			Api.post("activity/my", {
				user: userId,
				limit,
				skip,
			}).then((activities) => {
				if (activities && activities.length > 0) {
					const activityIds = setActivities(
						activities,
						activity,
						dispatch
					);
					setActIds(activityIds);
				}
			});
		}
	}, [userId, limit, skip]);

	return actIds;
};
