import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  dividers: {
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      paddingBottom: "20px",
    },
  },
  dialogHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottom: "1px solid #c5c4c4",
    "& h4": {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      "& h4": {
        fontSize: "15px",
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NormalDialog(props) {
  const {
    openDialog,
    handleCloseShare,
    pageTitle,
    content,
    loading,
    hideCrossIcon,
    disableHeaderMargin,
    padding = "2px 7px 10px",
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseShare}
      TransitionComponent={Transition}
      keepMounted
      disableEnforceFocus
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: isSmall ? "5px" : "12px",
          maxWidth: "90vw",
        },
      }}
    >
      <DialogContent
        className={classes.dividers}
        style={isSmall ? { padding: padding } : { padding: "5px 15px 20px" }}
      >
        <div
          className={classes.dialogHead}
          style={{
            marginBottom: disableHeaderMargin ? "0px" : "10px",
          }}
        >
          <h4>{pageTitle}</h4>
          {hideCrossIcon === true ? (
            <div></div>
          ) : (
            <IconButton
              color="primary"
              onClick={() => {
                if (loading === undefined || loading === null) {
                  handleCloseShare();
                } else {
                  if (!loading) {
                    handleCloseShare();
                  }
                }
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {content}
      </DialogContent>
    </Dialog>
  );
}
