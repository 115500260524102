import { styled } from "@mui/material";
import MuiTextField from "@mui/material/TextField";

const TextField = styled(MuiTextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
});

export default TextField;
