import { makeStyles } from "@material-ui/core";
import { Box, Checkbox, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTableAStyles } from "../../../utils/style";

const TableContainer = ({
  columns,
  data,
  loading,
  children,
  enableSelectAll = false,
  selectedRows,
  setSelectedRows,
  tableStyle,
  boldTextEnabled = false,
  hideHeader = false,
}) => {
  let defaultTableStyle = useTableAStyles({ boldTextEnabled: boldTextEnabled });

  const classes = tableStyle ? tableStyle : defaultTableStyle;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Box>
      {isLoading ? (
        <Box
          width="100%"
          height="400px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "400px" }}>
          {!data || !Array.isArray(data) ? (
            <Box
              width="100%"
              height="400px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              No results found
            </Box>
          ) : (
            <table className={classes.tableSty}>
              {!hideHeader && (
                <tr
                  style={{
                    height: "40px",
                    borderBottom: "none",
                  }}
                >
                  {enableSelectAll && (
                    <th style={{ width: "80px" }}>
                      <Checkbox
                        disabled={data?.length === 0}
                        checked={
                          selectedRows?.length === data?.length &&
                          data?.length !== 0
                        }
                        onChange={(evt, checked) => {
                          if (checked) {
                            setSelectedRows([...data]);
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                      />
                    </th>
                  )}
                  {columns.map((col) => (
                    <th>{col}</th>
                  ))}
                </tr>
              )}
              {children}
            </table>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TableContainer;
