import React, { useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BsFacebook } from "react-icons/bs";
import { useMediaQuery } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { FaLinkedinIn } from "react-icons/fa";
import config from "../../../config/index";
import mainLogo from "../../../Assets/RealList-01.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "100px 180px 20px",
    backgroundColor: "#F8F8F6",
    [theme.breakpoints.down("lg")]: {
      padding: "100px 110px 20px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "100px 40px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 20px 20px",
    },
  },
  mainCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    marginBottom: "25px",
    paddingBottom: "45px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  leftSide: {
    "& div": {
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
    },
    "& h2": {
      fontSize: "40px",
      color: theme.palette.primary.main,
      fontWeight: "900",
      marginLeft: "5px",
    },
    "& h3": {
      fontSize: "31px",
      color: "black",
      fontWeight: "700",
    },
    "& h4": {
      fontSize: "33px",
      color: theme.palette.primary.main,
      fontWeight: "600",
    },
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        fontSize: "26px",
        fontWeight: "800",
      },
      "& h3": {
        fontSize: "22px",
        color: "black",
        fontWeight: "700",
      },
      "& h4": {
        fontSize: "22px",
        color: theme.palette.primary.main,
        fontWeight: "700",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
    },
  },
  midSide: {
    "& div": {
      display: "flex",
      flexDirection: "column",
    },
    "& h3": {
      fontSize: "25px",
      fontWeight: "800",
      marginBottom: "35px",
    },
    "& a": {
      textDecoration: "none",
      color: "black",
      fontSize: "15px",
      fontWeight: "500",
      marginBottom: "4px",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
      "& h3": {
        marginBottom: "15px",
      },
    },
  },
  rigthSide: {
    "& h3": {
      fontSize: "35px",
      color: theme.palette.primary.main,
      fontWeight: "800",
      marginBottom: "20px",
    },
    "& span": {
      color: "black",
    },
    "& div": {
      display: "flex",
      marginTop: "25px",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "27px",
      },
    },
  },
  emailSty: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    fontWeight: "510",
    margin: "15px 0px",
  },
  bottomText: {
    fontSize: "15px",
    fontWeight: "510",
    textAlign: "center",
  },
  reactIconSty: {
    fontSize: "37px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "33px",
    },
  },
  muiIconSty: {
    fontSize: "37px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "33px",
    },
  },
  ancherTag: {
    color: "#3c3b3b",
    textDecoration: "none",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "15px",
    },
  },
  logoTitleCont: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",
    "& img": {
      height: "42px",
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        height: "37px",
      },
    },
  },
}));

export default function Footer({ pageRef, positionRef }) {
  const classes = useStyles();
  const theme = useTheme();

  const isMdSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const scrollTo = (ref) => {
    if (!ref) return;
    ref.current.scrollIntoView({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className={classes.root} ref={pageRef} id="footer">
      <div className={classes.mainCont}>
        <div className={classes.leftSide} data-aos="fade-up">
          {!isMdSmall && (
            <div className={classes.logoTitleCont}>
              <img src={mainLogo} alt="logo" />
            </div>
          )}
          <h3>Let's get Started</h3>
        </div>

        <div className={classes.midSide} data-aos="fade-up">
          <h3>Company</h3>
          <div>
            <a
              onClick={() => {
                scrollTo(positionRef.homePageRef);
              }}
            >
              Home
            </a>
            <a target="_blank" href={`${config?.BASE_URL}terms`}>
              Terms of Service
            </a>
            <a target="_blank" href={`${config?.BASE_URL}terms`}>
              Privacy Policy
            </a>
          </div>
        </div>

        <div className={classes.rigthSide} data-aos="fade-up">
          <h3>
            Saying <span>Hello!</span>
          </h3>
          <a
            className={classes.emailSty}
            target="_blank"
            href={`mailto:${config?.landingPageMailID}`}
          >
            team@reallist.ai
          </a>
          <div>
            <a
              className={classes.ancherTag}
              target="_blank"
              href="https://www.facebook.com/reallistai"
            >
              <BsFacebook
                className={classes.reactIconSty}
                style={{ color: theme.palette.primary.main }}
              />
            </a>
            <a
              className={classes.ancherTag}
              target="_blank"
              href="https://twitter.com/RealListAi"
            >
              <TwitterIcon className={classes.muiIconSty} />
            </a>
            <a
              className={classes.ancherTag}
              target="_blank"
              href="https://www.youtube.com/@RealListAi"
            >
              <YouTubeIcon className={classes.muiIconSty} />
            </a>
            <a
              className={classes.ancherTag}
              target="_blank"
              href="https://www.linkedin.com/company/reallistnetwork/about/"
            >
              <FaLinkedinIn
                className={classes.reactIconSty}
                style={{ margin: "0px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <p className={classes.bottomText}>
        Copyright © 2023 | All Rights Reserved{" "}
      </p>
    </section>
  );
}
