import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import PalForm from "../../../AddMember/newPalForm";
import NormalDialog from "../../../styled/CommonComponents/NormalDialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Slide } from "@mui/material";
import PalFormDrawer from "../../../AddMember/newPalFormDrawer";

export default function CreatePal(props) {
  const { tx, setTx, updateApi, findOrAddRel, handleClose, setLoadingBool } =
    props;

  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClosePal = () => {
    setOpen(false);
  };

  const newOnSelect = async (newPalData) => {
    setOpen(false);
    setLoadingBool(true);

    if (newPalData) {
      await updateApi({
        _id: tx?._id,
        secondParty: newPalData?.profile?._id,
        secondPartyWallet: newPalData?.wallet?._id,
      });

      const newTx = {
        ...tx,
        secondParty: newPalData?.profile,
        secondPartyWallet: newPalData?.wallet,
      };

      findOrAddRel(newPalData?.profile);
      setTx(newTx);
      handleClose();
    }
    setLoadingBool(false);
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        color="primary"
        style={{ border: "none" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {tx?.type === "Bill" ? "Vendor" : "Recipient"}
      </Button>

      <PalFormDrawer
        open={open}
        setOpen={setOpen}
        text={text}
        onSelect={newOnSelect}
        setLoading={setLoading}
        relationType={tx?.type === "Bill" ? "Vendor" : "Recipient"}
      />

      {/* <NormalDialog
        openDialog={open}
        loading={loading}
        handleCloseShare={handleClosePal}
        pageTitle={`Add New ${tx?.type === "Bill" ? "Vendor" : "Recipient"}`}
        content={
          <>
            <div style={{ height: "5px" }}>{loading && <LinearProgress />}</div>
            <PalForm
              text={text}
              onSelect={newOnSelect}
              setLoading={setLoading}
            />
          </>
        }
      /> */}
    </>
  );
}
