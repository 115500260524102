import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    optionsSingle: {
        padding: "10px 0px",
        cursor: "pointer",
        fontSize: "15px",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    }
}));

export default function AutocompleteOption({
    optionData, paddingLeft, searchStr, onSelect,
    keyField, isSearching, setSearchStr, selectedValue,
    setShowBool, setIsSearching
}) {
    const classes = useStyles();
    const theme = useTheme();

    const [isMatch, setIsMatch] = useState(true)

    useEffect(() => {
        if (isSearching) {
            if (searchStr && searchStr.length > 0) {
                const patt = new RegExp(searchStr, "i");
                const res = patt.test(optionData?.name);
                if (res) {
                    setIsMatch(true)
                } else {
                    setIsMatch(false)
                }
            } else {
                setIsMatch(true)
            }
        } else {
            setIsMatch(true)
        }
    }, [searchStr, isSearching])

    return (<>
        {isMatch && (
            <div
                className={classes.optionsSingle}
                style={selectedValue?._id == optionData?._id ? { paddingLeft: `${paddingLeft}px`, backgroundColor: "#ececec" } : { paddingLeft: `${paddingLeft}px` }}
                onClick={() => {
                    onSelect(optionData)
                    setSearchStr(optionData?.[keyField])
                    setShowBool(false)
                    setIsSearching(false)
                }}
            >
                {optionData?.[keyField]}
            </div>
        )}
        {optionData.children && optionData.children.map((optionDatak) => (
            <AutocompleteOption
                optionData={optionDatak}
                paddingLeft={paddingLeft + 10}
                searchStr={searchStr}
                onSelect={onSelect}
                isSearching={isSearching}
                keyField={keyField}
                setSearchStr={setSearchStr}
                selectedValue={selectedValue}
                setShowBool={setShowBool}
                setIsSearching={setIsSearching}
            />
        ))}
    </>
    );
}