import axios from "axios";
import config from "../config/index";
import firebase from "firebase/app";
import "firebase/auth";

const getCurrentUserToken = async () => {
	try {
		const user = firebase.auth().currentUser;
		if (user) {
			// Get the Firebase ID token for the current user
			const token = await user.getIdToken();

			// const expiresIn = 60 * 60 * 24; // 24 hours in seconds
			// const token = firebase.auth().createCustomToken(user.uid, { expiresIn })
			// .then((customToken) => {
			//     // use the custom token
			// }).catch((error) => {
			//     console.error(error);
			// });
			console.log("Firebase token:", token);
			// You can use the token for various purposes, such as sending it to a backend server for authentication
			return token;
		} else {
			console.log("No user is currently signed in.");
			return null;
		}
	} catch (error) {
		console.error("Failed to get Firebase token:", error);
		return null;
	}
};

let Api = null;
Api = axios.create({ baseURL: config.apiUrl });
// todo: setup interceptors
const activeUserId = localStorage.getItem("activeUserId");
const activeUserProfileId = localStorage.getItem("activeUserProfileId");
Api.interceptors.request.use(
	async function (config) {
		// console.log(process.env,' is process env')
		// const token = await getCurrentUserToken();
		const token = localStorage.getItem("token");
		// console.log("tokennnn = ", token);
		const loggedInFirebaseUId = localStorage.getItem("loggedInFirebaseUId");
		// const token = await  Auth.currentUser.getIdToken(true)
		config.data = {
			...(config.data || {}),
			token: token,
			loggedInFirebaseUId,
			activeUserId: activeUserId,
			activeUserProfileId: activeUserProfileId,
		};
		return config;
	},
	(error) => Promise.reject(error)
);

Api.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// console.log({ error });
		return false;
	}
);

export default Api;
