import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "4px"
    }
}))

const AllCommonField = ({
    type, value, lable, row, width, fieldData, onChange
}) => {
    const classes = useStyles();
    let CommonComponent = null

    const [locValue, setLocValue] = useState()

    useEffect(() => {
        setLocValue(value)
    }, [value])

    useDebounce(() => {
        if (onChange && fieldData) {
            onChange(fieldData, locValue)
        }
    }, 1000, [locValue])

    switch (type) {
        case "textField":
            CommonComponent = <TextField
                placeholder={`Enter ${lable}`}
                value={locValue}
                type="text"
                onChange={(e) => {
                    setLocValue(e.target.value);
                }}
                size="small"
                variant="outlined"
                fullWidth
            />
            break;
        case "numberField":
            CommonComponent = <TextField
                placeholder={`Enter ${lable}`}
                value={locValue}
                type="number"
                onChange={(e) => {
                    setLocValue(e.target.value);
                }}
                size="small"
                variant="outlined"
                fullWidth
            />
            break;
        case "dateField":
            CommonComponent = <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    value={locValue}
                    fullWidth
                    margin="normal"
                    id="due-date-picker"
                    format="MM/dd/yyyy"
                    variant="outlined"
                    onChange={(date) => {
                        setLocValue(date);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                />
            </MuiPickersUtilsProvider>
            break;
        case "textArea":
            CommonComponent = <TextField
                id="outlined-multiline-static"
                multiline
                rows={row}
                placeholder={`Enter ${lable}`}
                fullWidth
                value={locValue}
                onChange={(e) => {
                    setLocValue(e.target.value)
                }}
                variant="outlined"
                size="small"
            />
            break;
    }

    return (
        <div style={{ width: width, padding: "10px 5px" }} >
            <p className={classes.lableSty} >{lable}</p>
            {CommonComponent}
        </div>
    );
};

export default AllCommonField;