import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Avatar } from "@material-ui/core";
import SeeMoreSeeLess from "../../styled/CommonComponents/SeeMore.SeeLess";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "385px",
        width: "385px",
        padding: "0px 30px 30px",
        marginRight: "20px",
        borderRadius: "5px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        "& h3": {
            fontSize: "65px",
            color: theme.palette.primary.main
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: "500"
        },
        "& h5": {
            fontSize: "14px",
            fontWeight: "500",
            color: "gray"
        },
        "& p": {
            fontSize: "18px"
        },
        "&:hover":{
            transform: "scale(1.04)",
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "250px",
            width: "250px",
            padding: "0px 15px 15px",
            "& h3": {
                fontSize: "45px"
            },
            "& h4": {
                fontSize: "14px",
                fontWeight: "500"
            },
            "& h5": {
                fontSize: "12px",
                fontWeight: "450",
                color: "gray"
            },
            "& p": {
                fontSize: "16px"
            },
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "200px",
            width: "200px",
            padding: "5px 10px 10px",
            "& h3": {
                fontSize: "40px"
            },
            "& h4": {
                fontSize: "14px",
            },
            "& h5": {
                fontSize: "12px",
            },
            "& p": {
                fontSize: "13px"
            },
        }
    },
    photoNameCont: {
        display: "flex",
        alignItems: "center",
        "& div": {
            marginRight: "10px"
        },
        "& img": {
            width: "40px",
            height: "auto"
        }
    },
    mainTextCont: {
        height: "260px",
        overflowY: "auto",
        marginBottom: "20px",
        padding: "5px 0px",
        [theme.breakpoints.down("sm")]: {
            height: "300px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "210px",
        }
    }
}));

export default function Card({ text, name, role, img }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <div className={classes.root} >
            <h3>“</h3>

            <div className={classes.mainTextCont} >
                <p>
                    <SeeMoreSeeLess
                        text={text || ""}
                        limit={300}
                    />
                </p>
            </div>


            <div className={classes.photoNameCont} >
                <Avatar
                    src={img}
                />
                <div>
                    <h4>{name}</h4>
                    <h5>{role}</h5>
                </div>
            </div>
        </div>
    );
}