import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Slide from "@material-ui/core/Slide";
import mainLogo from "../../../Assets/RealList-01.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 180px 0px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
        [theme.breakpoints.down("lg")]: {
            padding: "16px 110px 0px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "15px 25px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "6px 15px 0px",
        },
    },
    logoTitleCont: {
        display: "flex",
        alignItems: "center",
        "& img": {
            height: "42px"
        },
        [theme.breakpoints.down("sm")]: {
            "& img": {
                height: "37px",
            }
        }
    },
    optionsCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: "600",
            margin: "0px 5px"
        },
        "& .MuiButton-root": {
            padding: "0px 12px"
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    signInLoginCont: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    loginBtn: {
        marginRight: "10px",
        "& .MuiButton-label": {
            fontSize: "16px",
            fontWeight: "600",
        }
    },
    menuCont: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    drawerPaper: {
        width: "250px",
        height: "calc(100vh - 60px)",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        paddingTop: "25px",
        paddingLeft: "25px",
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        "& .MuiButton-root": {
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "16px",
            marginBottom: "8px"
        }
    },
    subCont: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        paddingLeft: "15px",
        marginTop: "-6px",
        "& .MuiButton-root": {
            paddingTop: "4px",
            paddingBottom: "4px",
            marginBottom: "6px"
        },
        "& .MuiButton-label": {
            color: "gray",
            fontSize: "15px",
        }
    },
    collapseCont: {
        position: "absolute",
        top: "52px",
        width: "210px",
        backgroundColor: "white",
        left: "0px",
    },
    sliderCont: {
        position: "absolute",
        top: "60px",
        left: "0px",
        zIndex: "20px"
    }
}));

export default function TopBar({ positionRef }) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const [currentSection, setCurrentSection] = useState("homePage")
    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [openDrawer, setOpenDrawer] = useState(false)

    const animate = () => {
        const current = window.scrollY
        const homePageEndPoint = positionRef.homePageRef.current?.offsetTop - 100
        const advantageEndPoint = positionRef.advantageRef.current?.offsetTop - 100
        const investorsEndPoint = positionRef.investorsRef.current?.offsetTop - 100
        const goalEndPoint = positionRef.goalRef.current?.offsetTop - 100
        const devEndPoint = positionRef.devRef.current?.offsetTop - 100
        const reviewEndPoint = positionRef.reviewRef.current?.offsetTop - 100
        const footerEndPoint = positionRef.footerRef.current?.offsetTop - 550

        if (current > homePageEndPoint && current < advantageEndPoint) {
            setCurrentSection("homePage")
        } else if (current > advantageEndPoint && current < investorsEndPoint) {
            setCurrentSection("developer")
        } else if (current > investorsEndPoint && current < devEndPoint) {
            setCurrentSection("investors")
        } else if (current > devEndPoint && current < reviewEndPoint) {
            setCurrentSection("developersWorkWith")
        } else if (current > reviewEndPoint && current < footerEndPoint) {
            setCurrentSection("reviews")
        } else if (current > footerEndPoint) {
            setCurrentSection("contactUs")
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", animate, { passive: true });
    }, []);

    const scrollTo = (ref) => {
        if (!ref) return;
        ref.current.scrollIntoView({
            top: ref.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    };


    return (
        <div className={classes.root}>
            <div className={classes.logoTitleCont} >
                <img src={mainLogo} alt="logo" />
            </div>

            <div className={classes.optionsCont} >
                <Button style={currentSection === "homePage" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.homePageRef);
                    }}
                >Home</Button>
                <Button style={currentSection === "developer" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.advantageRef);
                    }}
                >Developer</Button>
                <Button style={currentSection === "investors" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.investorsRef);
                    }}
                >Investors</Button>
                <Button style={currentSection === "contactUs" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.footerRef);
                    }}
                >Contact Us</Button>
            </div>

            <div className={classes.signInLoginCont} >
                <Button
                    className={classes.loginBtn}
                    onClick={() => {
                        const data = { type: "signin" }
                        const searchParams = new URLSearchParams(data)
                        const url = "login/?" + searchParams;
                        history.push(url)
                    }}
                >Sign in</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const data = { type: "signup" }
                        const searchParams = new URLSearchParams(data)
                        const url = "signup/?" + searchParams;
                        history.push(url)
                    }}
                >Sign up</Button>
            </div>

            <div className={classes.sliderCont} >
                <Slide
                    direction="right"
                    in={openDrawer}
                    mountOnEnter
                    unmountOnExit
                >
                    <div className={classes.drawerPaper} >
                        <Button style={currentSection === "homePage" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.homePageRef);
                                setOpenDrawer(false)
                            }}
                        >Home</Button>
                        <Button style={currentSection === "developer" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.advantageRef);
                                setOpenDrawer(false)
                            }}
                        >Developer</Button>
                        <Button style={currentSection === "investors" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.investorsRef);
                                setOpenDrawer(false)
                            }}
                        >Investors</Button>
                        <Button style={currentSection === "contactUs" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.footerRef);
                                setOpenDrawer(false)
                            }}
                        >Contact Us</Button>



                        <Button
                            onClick={() => {
                                const data = { type: "signin" }
                                const searchParams = new URLSearchParams(data)
                                const url = "login/?" + searchParams;
                                history.push(url)
                            }}
                        >Sign in</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ padding: "1px 15px" }}
                            onClick={() => {
                                const data = { type: "signup" }
                                const searchParams = new URLSearchParams(data)
                                const url = "signup/?" + searchParams;
                                history.push(url)
                            }}
                        >Sign up</Button>
                    </div>
                </Slide>
            </div>

            <IconButton
                className={classes.menuCont}
                onClick={() => { setOpenDrawer(!openDrawer) }}
            >
                {openDrawer ? (<CloseIcon />) : (<MenuIcon />)}
            </IconButton>
        </div>
    );
}