import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
    Button,
    TextField as InputText,
    MenuItem,
    Step,
    StepLabel,
    Stepper
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

const MaterialStatus = [
    "Draft",
    "Submitted for Approval",
    "Submitted",
    "Reviewed",
    "Approved",
    "Rejected",
    "Closed"
];
const BOQstatus = [
    "Draft",
    "Submitted for Approval",
    "Submitted",
    "Evaluated",
    "Approved",
    "Rejected",
    "Closed"
];
const Orderstatus = [
    "Draft",
    "Submitted for Approval",
    "Approved",
    "Rejected",
    "Issued",
    "Acknowledged",
    "Partially Fulfilled",
    "Fully Fulfilled",
    "Closed",
    "Cancelled"
];
const Invoicestatus = [
    "Draft",
    "Submitted",
    "Under Review",
    "Approved for Payment",
    "Rejected",
    "Partially Paid",
    "Paid in Full",
    "Closed"
];

const OpsOrderStatus = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        activeOpsType,
        setActiveOpsType,
        opsOrderStatus,
        setOpsOrderStatus
        // handleMaterialRequest,
        // handlePrint
    } = props;


    return (
        <div className={classes.root}>
            {activeOpsType === "Material Request" &&
                <>
                    {opsOrderStatus === "Draft" ?
                        <MenuItem
                            onClick={() => {
                                setOpsOrderStatus(MaterialStatus[1]);
                                // handleClose();
                            }}
                        >
                            {MaterialStatus[1]}
                        </MenuItem>
                        :
                        (opsOrderStatus === "Submitted for Approval" ?
                            <MenuItem
                                onClick={() => {
                                    setOpsOrderStatus(MaterialStatus[2]);
                                    // handleClose();
                                }}
                            >
                                {MaterialStatus[2]}
                            </MenuItem>

                            :
                            (opsOrderStatus === "Submitted" ?
                                <MenuItem
                                    onClick={() => {
                                        setOpsOrderStatus(MaterialStatus[3]);
                                        // handleClose();
                                    }}
                                >
                                    {MaterialStatus[3]}
                                </MenuItem>

                                :
                                (opsOrderStatus === "Reviewed" ?
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(MaterialStatus[4]);
                                                // handleClose();
                                            }}
                                        >
                                            {MaterialStatus[4]}
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(MaterialStatus[5]);
                                                // handleClose();
                                            }}
                                        >
                                            {MaterialStatus[5]}
                                        </MenuItem>
                                    </>

                                    :
                                    (opsOrderStatus === "Approved" &&
                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(MaterialStatus[6]);
                                                // handleClose();
                                            }}

                                        >
                                            {MaterialStatus[6]}
                                        </MenuItem>
                                    )
                                )
                            )
                        )
                    }
                </>
            }


            {activeOpsType === "Bill Of Quantities" &&
                <>
                    {opsOrderStatus === "Draft" ?
                        <MenuItem
                            onClick={() => {
                                setOpsOrderStatus(BOQstatus[1]);
                                // handleClose();
                            }}
                        >
                            {BOQstatus[1]}
                        </MenuItem>
                        :
                        (opsOrderStatus === "Submitted for Approval" ?
                            <MenuItem
                                onClick={() => {
                                    setOpsOrderStatus(BOQstatus[2]);
                                    // handleClose();
                                }}
                            >
                                {BOQstatus[2]}
                            </MenuItem>

                            :
                            (opsOrderStatus === "Submitted" ?
                                <MenuItem
                                    onClick={() => {
                                        setOpsOrderStatus(BOQstatus[3]);
                                        // handleClose();
                                    }}
                                >
                                    {BOQstatus[3]}
                                </MenuItem>

                                :
                                (opsOrderStatus === "Evaluated" ?
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(BOQstatus[4]);
                                                // handleClose();
                                            }}
                                        >
                                            {BOQstatus[4]}
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(BOQstatus[5]);
                                                // handleClose();
                                            }}
                                        >
                                            {BOQstatus[5]}
                                        </MenuItem>
                                    </>

                                    :
                                    (opsOrderStatus === "Approved" &&
                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(BOQstatus[6]);
                                                // handleClose();
                                            }}

                                        >
                                            {BOQstatus[6]}
                                        </MenuItem>
                                    )
                                )
                            )
                        )
                    }
                </>
            }


            {(activeOpsType === "Purchase Order" || activeOpsType === "Work Order") &&
                <>
                    {opsOrderStatus === "Draft" ?
                        <MenuItem
                            onClick={() => {
                                setOpsOrderStatus(Orderstatus[1]);
                                // handleClose();
                            }}
                        >
                            {Orderstatus[1]}
                        </MenuItem>
                        :
                        (opsOrderStatus === "Submitted for Approval" ?
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setOpsOrderStatus(Orderstatus[2]);
                                        // handleClose();
                                    }}
                                >
                                    {Orderstatus[2]}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        setOpsOrderStatus(Orderstatus[3]);
                                        // handleClose();
                                    }}
                                >
                                    {Orderstatus[3]}
                                </MenuItem>
                            </>


                            :
                            (opsOrderStatus === "Approved" ?
                                <MenuItem
                                    onClick={() => {
                                        setOpsOrderStatus(Orderstatus[4]);
                                        // handleClose();
                                    }}
                                >
                                    {Orderstatus[4]}
                                </MenuItem>

                                :

                                (opsOrderStatus === "Issued" ?
                                    <MenuItem
                                        onClick={() => {
                                            setOpsOrderStatus(Orderstatus[5]);
                                            // handleClose();
                                        }}
                                    >
                                        {Orderstatus[5]}
                                    </MenuItem>

                                    :
                                    (opsOrderStatus === "Acknowledged" ?
                                        <>
                                            <MenuItem
                                                onClick={() => {
                                                    setOpsOrderStatus(Orderstatus[6]);
                                                    // handleClose();
                                                }}
                                            >
                                                {Orderstatus[6]}
                                            </MenuItem>

                                            <MenuItem
                                                onClick={() => {
                                                    setOpsOrderStatus(Orderstatus[7]);
                                                    // handleClose();
                                                }}
                                            >
                                                {Orderstatus[7]}
                                            </MenuItem>
                                        </>

                                        :

                                        (opsOrderStatus === "Partially Fulfilled" ?
                                            <MenuItem
                                                onClick={() => {
                                                    setOpsOrderStatus(Orderstatus[8]);
                                                    // handleClose();
                                                }}

                                            >
                                                {Orderstatus[8]}
                                            </MenuItem>

                                            :

                                            (opsOrderStatus === "Fully Fulfilled" &&
                                                <MenuItem
                                                    onClick={() => {
                                                        setOpsOrderStatus(Orderstatus[9]);
                                                        // handleClose();
                                                    }}

                                                >
                                                    {Orderstatus[9]}
                                                </MenuItem>
                                            )
                                        )

                                    )
                                )
                            )
                        )
                    }
                </>
            }


            {activeOpsType === "Invoice" &&
                <>
                    {opsOrderStatus === "Draft" ?
                        <MenuItem
                            onClick={() => {
                                setOpsOrderStatus(Invoicestatus[1]);
                                // handleClose();
                            }}
                        >
                            {Invoicestatus[1]}
                        </MenuItem>
                        :
                        (opsOrderStatus === "Submitted" ?
                            <MenuItem
                                onClick={() => {
                                    setOpsOrderStatus(Invoicestatus[2]);
                                    // handleClose();
                                }}
                            >
                                {Invoicestatus[2]}
                            </MenuItem>

                            :

                            (opsOrderStatus === "Under Review" ?
                                <>
                                    <MenuItem
                                        onClick={() => {
                                            setOpsOrderStatus(Invoicestatus[3]);
                                            // handleClose();
                                        }}
                                    >
                                        {Invoicestatus[3]}
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => {
                                            setOpsOrderStatus(Invoicestatus[4]);
                                            // handleClose();
                                        }}
                                    >
                                        {Invoicestatus[4]}
                                    </MenuItem>
                                </>

                                :
                                (opsOrderStatus === "Approved for Payment" ?
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(Invoicestatus[5]);
                                                // handleClose();
                                            }}
                                        >
                                            {Invoicestatus[5]}
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(Invoicestatus[6]);
                                                // handleClose();
                                            }}
                                        >
                                            {Invoicestatus[6]}
                                        </MenuItem>
                                    </>

                                    :
                                    (opsOrderStatus === "Partially Paid" ?
                                        <MenuItem
                                            onClick={() => {
                                                setOpsOrderStatus(Invoicestatus[6]);
                                                // handleClose();
                                            }}

                                        >
                                            {Invoicestatus[6]}
                                        </MenuItem>
                                        :
                                        (opsOrderStatus === "Paid in Full" &&
                                            <MenuItem
                                                onClick={() => {
                                                    setOpsOrderStatus(Invoicestatus[7]);
                                                    // handleClose();
                                                }}

                                            >
                                                {Invoicestatus[7]}
                                            </MenuItem>
                                        )
                                    )
                                )
                            )
                        )
                    }
                </>
            }

        </div>
    )
}
export default OpsOrderStatus;