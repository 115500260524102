import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { createUpdateDeleteBudgetUpdate, getBudgetUpdateByGivenFilter, getDeepPopulatedBudgetCost, getSubheadAndWorkitem } from "./api.call";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { useDebounce } from "react-use";
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeView from '@material-ui/lab/TreeView';
import emptyFile from "../../Assets/emptyData.svg"
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import emptyfolder from "../../Assets/FileIcon/emptyfolder.png"
import Skeleton from '@material-ui/lab/Skeleton';
import BudgetUpdateSingleItem from "./BudgetUpdateSingleItem";
import BudgetUpdateHeadBody from "./BudgetUpdateHeadBody";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

const useStyles = makeStyles((theme) => ({
    mainBodyCont: {
        width: "100%",
        marginBottom: "30px",
        padding: "30px 25px 50px",
        backgroundColor: "white"
    },
    mainHeader: {
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid gray",
        "& h3": {
            fontSize: "20px",
            color: "#00345D",
            fontWeight: "600"
        }
    },
    updateBodyCont: {
        width: "100%",
        padding: "25px 0px",
        position: "relative",
    },
    updateBodyContLoaderCont: {
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        zIndex: "999",
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bodyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        overflowY: "auto",
        overflowX: "hidden",
        "& .MuiAccordionDetails-root": {
            padding: "0px",
            display: "block"
        },
        "& .MuiPaper-elevation1": {
            boxShadow: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "0px",
            height: "60px",
        }
    },
    accordionmainCont: {
        marginBottom: "5px"
    },
    treeCont: {
        width: "100%",
    },
    totalCont: {
        borderTop: "1px solid #dbdbdb",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "10px",
        marginTop: "10px",
        "& p": {
            fontSize: "16px",
            fontWeight: "500"
        }
    },
    drawerLeftCont: {
        width: "calc(40% - 1px)",
        height: "100%",
        padding: "0px 10px",
        overflow: "hidden",
        "& .MuiChip-root": {
            height: "auto",
            padding: "5px",
            marginBottom: "10px"
        },
        "& .MuiChip-label": {
            whiteSpace: "wrap"
        }
    },
    drawerMidLine: {
        height: "95%",
        width: "1px",
        borderLeft: "1px solid #dbdbdb"
    },
    drawerRightCont: {
        width: "60%",
        height: "100%",
        overflow: "hidden",
    },
    loaderCont: {
        height: "5px",
        width: "100%"
    },
    allChipsCont: {
        width: "100%",
        height: "calc(100% - 45px)",
        padding: "15px 0px 0px",
        overflowY: "auto",
    },
    selectedHeaderCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 7px",
        height: "40px",
        borderBottom: "1px solid #dbdbdb",
        "& h3": {
            fontWeight: "500",
            fontSize: "16px",
            color: "#00345D"
        }
    },
    emptyLeftSideCont: {
        width: "100%",
        height: "calc(100% - 45px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "35%",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
    skeletonCont: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    searchSkeleton: {
        width: "70%",
        height: "40px",
        borderRadius: "5px",
        margin: "15px 0px 20px"
    },
    budgetCostSkeleton: {
        width: "100%",
        height: "50px",
        marginBottom: "10px"
    },
    saveBtnCont: {
        width: "100%",
        display: "flex",
        align: "center",
        justifyContent: "flex-end",
        marginTop: "20px"
    }
}));

const BudgetUpdate = forwardRef((props, ref) => {
    const {
        openUpdateBudget, setOpenUpdateBudget, projectProfileId,
        selectedBudgetId, setSelectedBudgetId, opsOrderId,
        budegtUpdateItemType, filterToGetBudgetUpdateItems, pageLoader,
        projectId, organizationId, projectBlockId, txId, curParentStatus,
        addNewSelectedItems
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [headSubheadWorkitemData, setHeadSubheadWorkitemData] = useState([])
    const [parentChildrenMap, setParentChildrenMap] = useState({})
    const [idTypeMap, setIdTypeMap] = useState({})
    const [selectedItemsData, setSelectedItemsData] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [amountChangeFlag, setAmountChangeFlag] = useState(false)
    const [allNodeIdStr, setAllNodeIdStr] = useState([])
    const [allItemsData, setAllItemsData] = useState([])
    const [dataLoader, setDataLoader] = useState(false)
    const [idBudgetItemMap, setIdBudgetItemMap] = useState({})
    const [allBudgetCostGetLodaer, setAllBudgetCostGetLodaer] = useState(true)
    const [allBudgetUpdateItems, setAllBudgetUpdateItems] = useState([])
    const [allBudgetUpdateItemIds, setAllBudgetUpdateItemIds] = useState([])
    const [budgetUpdateItemIdObjMap, setBudgetUpdateItemIdObjMap] = useState(null)
    const [idAmountMap, setIdAmountMap] = useState(null)

    useEffect(() => {
        pageLoader(true)
    }, [])

    function buildNestedArray(arr1, parentId = null) {
        const result = [];
        for (const item of arr1) {
            if (item.parent == parentId) {
                const newItem = { ...item };
                const children = buildNestedArray(arr1, item._id);
                if (children?.length > 0) {
                    newItem.children = children;
                }
                result.push(newItem);
            }
        }
        return result.length > 0 ? result : [];
    }

    const buildHierarchy = (items, parent = null) => {
        const result = [];
        for (const item of items) {
            if (item.parent == parent) {
                const children = buildHierarchy(items, item._id);
                if (children.length > 0) {
                    item.children = children;
                }
                if (item.numberOfChildren === 0) {
                    result.push({
                        ...item,
                    })
                } else {
                    result.push(item);
                }
            }
        }
        return result;
    }

    const getBudgetUpdatedRealtedData = async () => {
        await getBudgetUpdateByGivenFilter({ givenFilter: filterToGetBudgetUpdateItems })
            .then((data) => {
                console.log(data)
                let budgetItemIdDataObjMap = {}
                let locIdAmountMap = {}
                let budgetItemIdsArr = []
                let locTotal = 0
                data.map((budgetItem) => {
                    budgetItemIdDataObjMap[budgetItem?.budgetItem] = budgetItem;
                    budgetItemIdsArr.push(budgetItem?.budgetItem);
                    locIdAmountMap[budgetItem?.budgetItem] = budgetItem?.amount || 0
                    locTotal = locTotal + budgetItem?.amount
                })
                setTotalAmount(locTotal)
                setIdAmountMap(locIdAmountMap)
                setAllBudgetUpdateItems(data)
                setAllBudgetUpdateItemIds(budgetItemIdsArr)
                setBudgetUpdateItemIdObjMap(budgetItemIdDataObjMap)
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "Unable to fetch ops order",
                    },
                });
            })
    }

    const getDeepPopulatedData = async () => {
        setAllBudgetCostGetLodaer(true)
        setAmountChangeFlag(!amountChangeFlag)
        await getDeepPopulatedBudgetCost({ profileId: projectProfileId })
            .then(async (data) => {
                let updatedData = [...data]
                let objParentChildren = {}
                let objIdType = {}
                data.map((budgetCost, i) => {
                    budgetCost.budgetHead.map((budgetHead, j) => {
                        budgetHead.items.map((subhead, k) => {
                            let allWorkItem = subhead?.items || []
                            let workitemIds = allWorkItem.map((obj) => {
                                objIdType[obj?._id] = "workitem"
                                return obj?._id;
                            })
                            let parentChildMap = new Map();
                            allWorkItem.forEach(item => {
                                item.ancestors.forEach(parentId => {
                                    if (!parentChildMap.has(parentId)) {
                                        parentChildMap.set(parentId, []);
                                    }
                                    parentChildMap.get(parentId).push(item._id);
                                });
                            });
                            for (const [key, value] of parentChildMap) {
                                objParentChildren[key] = value
                            }
                            workitemIds.map((id) => {
                                if (!objParentChildren[id]) {
                                    objParentChildren[id] = []
                                }
                            })
                            objParentChildren[subhead?._id] = workitemIds
                            objIdType[subhead?._id] = "subhead"
                            let stuctedWorkItems = buildHierarchy(allWorkItem)
                            updatedData[i].budgetHead[j].items[k].items = stuctedWorkItems;
                        })
                    })
                })
                setIdTypeMap(objIdType)
                setParentChildrenMap(objParentChildren)
                setHeadSubheadWorkitemData(updatedData)
                await getBudgetUpdatedRealtedData();
                setAllBudgetCostGetLodaer(false)
                setAmountChangeFlag(!amountChangeFlag)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (projectProfileId) {
            getDeepPopulatedData()
        }
    }, [projectProfileId])

    const selectHandel = (selectedIds) => {
        let arr = [...selectedIds]
        let flag = false;
        Object.keys(parentChildrenMap).forEach(key => {
            let parentId = key;
            let children = parentChildrenMap[key];
            let selected = children.filter((id) => arr.includes(id))
            if (!arr.includes(parentId) && children.length === selected.length && children.length > 0) {
                arr.push(parentId)
                flag = true;
            }
        });
        if (flag) {
            arr = selectHandel(arr)
        }
        return arr;
    }

    const unSelectHandle = (selectedIds) => {
        let arr = [...selectedIds]
        let flag = false;
        Object.keys(parentChildrenMap).forEach(key => {
            let parentId = key;
            let children = parentChildrenMap[key];
            let selected = children.filter((id) => selectedIds.includes(id))
            if (selectedIds.includes(parentId) && children.length > selected.length && children.length > 0 && selectedIds.includes(parentId)) {
                arr = arr.filter((id) => id != parentId)
                flag = true;
            }
        });
        if (flag) {
            arr = unSelectHandle(arr)
        }
        return arr
    }

    const handleCheck = (curId, isChecked) => {
        pageLoader(true)

        let arr = []
        let objMap = {}
        let allChildren = parentChildrenMap[curId]
        let allIds = [...allChildren, curId]
        if (isChecked) {
            const setArr = new Set([...allIds, ...selectedBudgetId])
            arr = selectHandel([...setArr])
        } else {
            arr = selectedBudgetId.filter(item => !allIds.includes(item));
            arr = unSelectHandle(arr)
        }
        setSelectedBudgetId(arr)
        arr.map((id) => {
            if (idAmountMap && id && idAmountMap[id] && idAmountMap[id] != 0 && idAmountMap[id] != null && idAmountMap[id] != "") {
                objMap[id] = idAmountMap[id]
            } else {
                objMap[id] = 0
            }
        })
        setIdAmountMap(objMap)
    }

    useDebounce(async () => {
        console.log(selectedBudgetId)
        if (idTypeMap && selectedBudgetId && selectedBudgetId.length > 0) {
            console.log(selectedBudgetId)
            let workitemIds = []
            let subheadIds = []
            setDataLoader(true)
            selectedBudgetId.map((id) => {
                if (idTypeMap[id] === "subhead") {
                    subheadIds.push(id)
                }
                if (idTypeMap[id] === "workitem") {
                    workitemIds.push(id)
                }
            })
            pageLoader(true)
            await getSubheadAndWorkitem({
                subheadIds,
                workitemIds
            })
                .then(async (data) => {
                    console.log(data)
                    const subheadResults = data?.subheadResults || []
                    const workitemResults = data?.workitemResults || []
                    const allItems = [...subheadResults, ...workitemResults]
                    setAllItemsData(allItems)
                    let updatedAllItems = []
                    let allNodeIds = []
                    let idBudgetObjMap = {};
                    allItems.map((item, index) => {
                        allNodeIds.push(`${index + 1}`)
                        if (item?.usingAs && item?.usingAs === "asSubheadChild") {
                            let ancestorsArr = item?.ancestors || [];
                            if (selectedBudgetId.includes(item?.subworkhead)) {
                                ancestorsArr.push(item?.subworkhead)
                            }
                            if (item?.parent) {
                                const itemObj = {
                                    ...item,
                                    nodeId: `${index + 1}`,
                                    parent: selectedBudgetId.includes(item?.parent) ? item?.parent : null,
                                    ancestors: ancestorsArr
                                }
                                updatedAllItems.push(itemObj);
                                idBudgetObjMap[item?._id] = itemObj;
                            } else {
                                const itemObj = {
                                    ...item,
                                    nodeId: `${index + 1}`,
                                    parent: selectedBudgetId.includes(item?.subworkhead) ? item?.subworkhead : null,
                                    ancestors: ancestorsArr
                                }
                                updatedAllItems.push(itemObj)
                                idBudgetObjMap[item?._id] = itemObj;
                            }
                        } else {
                            const itemObj = {
                                ...item,
                                nodeId: `${index + 1}`,
                                parent: null,
                                ancestors: []
                            }
                            updatedAllItems.push(itemObj)
                            idBudgetObjMap[item?._id] = itemObj;
                        }
                    })
                    setIdBudgetItemMap(idBudgetObjMap);
                    setAllNodeIdStr(allNodeIds)
                    const itemsInHierarchy = buildNestedArray(updatedAllItems)
                    setSelectedItemsData(itemsInHierarchy)
                    setDataLoader(false)
                    pageLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            pageLoader(false)
        }
    }, 2000, [selectedBudgetId, idTypeMap]);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useDebounce(() => {
        let locAmount = 0;
        console.log(idAmountMap)
        if (idAmountMap) {
            Object.keys(idAmountMap).forEach(id => {
                locAmount = parseFloat(locAmount) + parseFloat(idAmountMap[id] ? idAmountMap[id] : 0)
            })
            console.log(locAmount)
            setTotalAmount(locAmount)
        }
    }, 1000, [idAmountMap, amountChangeFlag])

    useImperativeHandle(ref, () => ({
        callBudgetUpdateUpdateFun(curStatus) {
            console.log(curStatus)
            updateBudgetUpdateitems(curStatus)
        }
    }));

    const updateBudgetUpdateitems = async (curStatus) => {
        if (idAmountMap && selectedBudgetId && selectedBudgetId.length > 0) {
            pageLoader(true)
            let budgetUpdateItemArr = [];
            let budgetUpdateItemUpdateObjArr = []
            let deleteAbleBudgetUpdateIds = []

            Object.keys(idAmountMap).forEach(id => {
                let currentAmount = idAmountMap[id] || 0;
                let currentBudgetData = idBudgetItemMap[id];
                if (allBudgetUpdateItemIds.includes(id)) {
                    if (currentAmount == 0) {
                        //in this case we will delete budgetUpdateItem, as amount reduced to zero
                        deleteAbleBudgetUpdateIds.push(budgetUpdateItemIdObjMap[id]?._id)
                    } else if (currentAmount === budgetUpdateItemIdObjMap[id].amount) {
                        //in this case we will update the status only as amount is remain same
                        budgetUpdateItemUpdateObjArr.push({
                            updateOne: {
                                filter: { _id: budgetUpdateItemIdObjMap[id]?._id },
                                update: { status: curStatus }
                            }
                        })
                    } else {
                        //in this case we will update status & amount
                        budgetUpdateItemUpdateObjArr.push({
                            updateOne: {
                                filter: { _id: budgetUpdateItemIdObjMap[id]?._id },
                                update: { status: curStatus, amount: currentAmount }
                            }
                        })
                    }
                } else {
                    //here we will create new budgetUpdateItem
                    if (currentAmount > 0) {
                        let currentObj = {
                            budgetItem: currentBudgetData?._id,
                            type: budegtUpdateItemType,
                            status: curStatus,
                            amount: currentAmount
                        }

                        if (opsOrderId) {
                            currentObj.opsOrder = opsOrderId
                        }
                        if (txId) {
                            currentObj.tx = txId
                        }
                        if (projectId) {
                            currentObj.project = projectId
                        }
                        if (organizationId) {
                            currentObj.organization = organizationId
                        }
                        if (projectBlockId) {
                            currentObj.projectBlock = projectBlockId
                        }
                        if (idTypeMap[id] === "subhead") {
                            currentObj.budgetItemType = "BudgetWorkSubhead"
                            currentObj.subworkhead = currentBudgetData?._id
                            if (currentBudgetData?.budgetCostHead) {
                                currentObj.workhead = currentBudgetData?.budgetCostHead
                            }
                        }
                        if (idTypeMap[id] === "workitem") {
                            currentObj.budgetItemType = "BudgetWorkItem"
                            currentObj.workitem = currentBudgetData?._id
                            if (currentBudgetData.subworkhead) {
                                currentObj.subworkhead = currentBudgetData.subworkhead
                            }
                            if (currentBudgetData.workhead) {
                                currentObj.workhead = currentBudgetData.workhead
                            }
                        }
                        if (currentBudgetData?.budgetCost) {
                            currentObj.budgetCost = currentBudgetData.budgetCost
                        }
                        budgetUpdateItemArr.push(currentObj)
                    }
                }
            })

            const apiObj = {
                deleteAbleBudgetUpdateIds,
                budgetUpdateItemUpdateObjArr,
                newBudgetUpdateItemArr: budgetUpdateItemArr,
                selectedBudgetItemIds: selectedBudgetId
            }
            if (opsOrderId) {
                apiObj.opsOrderId = opsOrderId
            }
            if (txId) {
                apiObj.txId = txId
            }

            await createUpdateDeleteBudgetUpdate(apiObj)
                .then(async (data) => {
                    console.log(data)
                    await getBudgetUpdatedRealtedData()
                    pageLoader(false)
                    addNewSelectedItems(selectedBudgetId)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (<>
        {selectedItemsData && selectedItemsData.length > 0 ? (
            <div className={classes.mainBodyCont} >
                <div className={classes.mainHeader} >
                    <h3>Update Budget</h3>
                    <div></div>
                </div>
                <div className={classes.updateBodyCont} >
                    {selectedItemsData && selectedItemsData.length > 0 ? (<>
                        <TreeView
                            className={classes.treeCont}
                            expanded={[...allNodeIdStr]}
                            defaultCollapseIcon={<MinusSquare />}
                            defaultExpandIcon={<PlusSquare />}
                            defaultEndIcon={<CloseSquare />}
                        >
                            {selectedItemsData && selectedItemsData.map((item, i) => (
                                <BudgetUpdateSingleItem
                                    item={item}
                                    key={i}
                                    selectedBudgetId={selectedBudgetId}
                                    handleCheck={handleCheck}
                                    idAmountMap={idAmountMap}
                                    setIdAmountMap={setIdAmountMap}
                                    amountChangeFlag={amountChangeFlag}
                                    setAmountChangeFlag={setAmountChangeFlag}
                                    parentChildrenMap={parentChildrenMap}
                                    idBudgetItemMap={idBudgetItemMap}
                                />
                            ))}
                        </TreeView>
                        <div className={classes.totalCont} >
                            <h3>Total Amount</h3>
                            <p>₹{numberWithCommas((totalAmount || 0))}</p>
                        </div>
                        <div className={classes.saveBtnCont} >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    updateBudgetUpdateitems(curParentStatus);
                                }}
                                style={{ marginRight: "10px" }}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </>) : (<div className={classes.emptyLeftSideCont} style={{ marginTop: "50px" }} >
                        <img src={emptyFile} />
                        <div>
                            <p>No budget updated item still selected
                                <a onClick={() => { setOpenUpdateBudget(true) }} >
                                    click here
                                </a> to select item(s).</p>
                        </div>
                    </div>)}
                </div>
            </div>
        ) : null}
        <NormalDrawer
            openDrawer={openUpdateBudget}
            setOpenDrawer={setOpenUpdateBudget}
            anchor={"right"}
            title={"Update Budget"}
            width={"100vw"}
            content={<>
                <div className={classes.bodyCont} >
                    <div className={classes.drawerLeftCont} >
                        <div className={classes.selectedHeaderCont} >
                            <h3>Selected Items</h3>
                        </div>
                        <div className={classes.loaderCont} >
                            {dataLoader && (<LinearProgress />)}
                        </div>
                        <div className={classes.allChipsCont} >
                            {allItemsData && allItemsData.length > 0 ? allItemsData.map((item, i) => (
                                <Chip
                                    key={i}
                                    label={item.name}
                                    onDelete={() => { handleCheck(item?._id, false) }}
                                />
                            )) : (<div className={classes.emptyLeftSideCont} >
                                <img src={emptyfolder} />
                                <div>
                                    <p>No item is selected!</p>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div className={classes.drawerMidLine} ></div>
                    <div className={classes.drawerRightCont} >
                        {allBudgetCostGetLodaer ? (<div className={classes.skeletonCont} >
                            <Skeleton variant="rect" className={classes.searchSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                            <Skeleton variant="rect" className={classes.budgetCostSkeleton} />
                        </div>) : (<>
                            {headSubheadWorkitemData && headSubheadWorkitemData.length > 0 ? (
                                <BudgetUpdateHeadBody
                                    projectProfileId={projectProfileId}
                                    headSubheadWorkitemData={headSubheadWorkitemData}
                                    parentChildrenMap={parentChildrenMap}
                                    selectedBudgetId={selectedBudgetId}
                                    setSelectedBudgetId={setSelectedBudgetId}
                                    handleCheck={handleCheck}
                                />
                            ) : (<div className={classes.emptyLeftSideCont} >
                                <img src={emptyFile} />
                                <div>
                                    <p>No budget item is available.
                                        <a
                                            onClick={() => {
                                                history.push(`/budget-analysis/budget-costs/${projectProfileId}`)
                                            }}
                                        >
                                            click here
                                        </a> to create new budget item.</p>
                                </div>
                            </div>)}
                        </>)}
                    </div>
                </div>
            </>}
        />
    </>);
});

export default BudgetUpdate;