import _ from "lodash";

const parseContacts = (contacts) => {
  let filteredByType = [];
  for (let i = 0; i < contacts?.length; i++) {
    let item = contacts[i];
    if (
      filteredByType.find(
        (c) =>
          c?.profile?.username === item?.profile?.username &&
          c.type === item.type
      )
    ) {
      // NO-OP
    } else {
      filteredByType.push(item);
    }
  }

  let parsedByType = [];
  for (let i = 0; i < filteredByType?.length; i++) {
    let item = filteredByType[i];
    let itemIndex = parsedByType?.findIndex(
      (parsed) => parsed?.profile?.username === item?.profile?.username
    );

    if (itemIndex !== -1) {
      let newType = String(parsedByType[itemIndex]?.type);
      newType += `, ${item?.type}`;
      parsedByType[itemIndex].type = newType;
    } else {
      parsedByType.push(item);
    }
  }

  console.log("ParsedArr: ", parsedByType);
  console.log("ItemArr: ", filteredByType);
  return parsedByType;
};

const mainRoles = ["Owner", "Admin", "Viewer", "Prospect", "Customer", "Employee", "Contractor", "Vendor", "Investor", "Lender", "Tenant", "Worker", "Other"]

const roleMapForContact = {
  Owner: "Owner",
  Admin: "Admin",
  Viewer: "Viewer",
  Customer: "Vendor",
  Employee: "Employer",
  Contractor: "Client",
  Worker: "Client",
  Vendor: "Customer",
  Investor: "Venture",
  Lender: "Borrower",
  Tenant: "Landlord",
  Other: "Other"
}

export { parseContacts, mainRoles, roleMapForContact };
