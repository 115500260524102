import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { visuallyHidden } from "@mui/utils";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import arrayToReducer from "../../../helpers/arrayToReducer";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "react-use";
import CreateTaxModal from "./CreateTaxModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Edit } from "@material-ui/icons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "label",
    label: "Label On Invoices",
  },
  {
    id: "action",
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.label === "Action" ? "right" : "left"}
            padding="normal"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function TaxPicker({ open, setOpen, callback }) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const location = useLocation();
  const url = location.pathname;
  let segments = url.split("/");
  let orgId = segments[segments.length - 1];

  const getDefaultTaxes = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/procurement/ops/taxes/default", {
        organizationId: orgId,
      });
      if (rows) {
        setRows(data);
        const { newDict } = arrayToReducer(data);
        setDict(newDict);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to fetch taxes",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch taxes",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDefaultTaxes();
  }, []);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dict, setDict] = useState({});
  const [loading, setLoading] = useState(false);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleCallback = () => {
    let selectedIds = selected;
    let items = [];

    selectedIds.forEach((id) => {
      console.log("Tax dict Selected: ", dict[id]);
      items.push(dict[id]);
    });

    callback(items);
    setSelected([]);
    handleClose();
  };

  const searchTaxes = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/procurement/ops/taxes/search", {
        searchStr: searchQuery,
        organizationId: orgId,
      });
      if (data) {
        setRows(data);
        const { newDict } = arrayToReducer(data);
        setDict(newDict);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to search taxes",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to search taxes",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useDebounce(
    () => {
      if (searchQuery?.length >= 3 && String(searchQuery).trim() !== "") {
        searchTaxes();
      }
    },
    500,
    [searchQuery]
  );

  const [showCreateTaxModal, setShowCreateTaxModal] = useState(false);
  const [editableTax, setEditableTax] = useState();

  const handleEdit = (row) => {
    setEditableTax(row);
    setShowCreateTaxModal(true);
  };

  return (
    <div>
      <CreateTaxModal
        open={showCreateTaxModal}
        setOpen={setShowCreateTaxModal}
        callback={() => {
          setSearchQuery("");
          getDefaultTaxes();
        }}
        editableTax={editableTax}
        setEditableTax={setEditableTax}
        name={searchQuery}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Taxes {selected?.length > 0 ? "(" + selected?.length + ")" : ""}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ width: "100%" }}>
            <Box>
              <TextField
                fullWidth
                placeholder="Search for taxes"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {searchQuery && searchQuery.length > 0 && (
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowCreateTaxModal(true)}
                >
                  Create item {searchQuery}?
                </Typography>
              </Box>
            )}
            <Paper elevation={0} sx={{ width: "100%", mb: 2 }}>
              {loading ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px"
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <EnhancedTableHead
                      numSelected={selected?.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={rows?.length}
                    />
                    <TableBody>
                      {rows.map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row._id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row?.name || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.description || "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row?.invoiceLabel || "-"}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="Edit"
                                onClick={() => handleEdit(row)}
                                disabled={row?.default === true}
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              autoFocus
              onClick={handleClose}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              autoFocus
              onClick={() => handleCallback()}
              color="primary"
              variant="contained"
            >
              Select
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
