import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import jwt_decode from "jwt-decode";
import AOS from "aos";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import sha256 from "../../helpers/sha256";
import config from "../../config/index";
import { firebaseLoginHelper } from "../auth/auth.utils";
import Api from "../../helpers/Api";
import Footer from "./footer/Index";
import Advantage from "./advantage";
import Investors from "./investors";
import Goal from "./goal";
import Dev from "./devlopers";
import Review from "./review";
import TopBar from "./topBar";
import HomePage from "./homePage";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  topBar: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "70px",
    zIndex: "9999",
    backgroundColor: "white",
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
  },
  bodyCont: {
    marginTop: "70px",
    width: "100%",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
}));

export default function Landing() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const homePageRef = useRef(null);
  const advantageRef = useRef(null);
  const investorsRef = useRef(null);
  const goalRef = useRef(null);
  const devRef = useRef(null);
  const reviewRef = useRef(null);
  const footerRef = useRef(null);

  const [positionRef, setPositionRef] = useState({
    homePageRef,
    advantageRef,
    investorsRef,
    goalRef,
    devRef,
    reviewRef,
    footerRef,
  });

  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: async (response) => {
      const email = response.email;
      const displayName = response.name;

      const saltfromEnv = process.env.GOOGLE_ONE_TAP_CUSTOM_SALT;
      const password = await sha256(response.email + saltfromEnv);
      const imageUrl = response.picture;
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {
          result.photoURL = imageUrl;
          result.displayName = displayName;
          firebaseLoginHelper(result, dispatch);
        })
        .catch((e) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
              result.photoURL = imageUrl;
            })
            .catch((e) => {
              console.log(e, " is the firebase error while login");
            });
          console.log(e, " is the firebase error while signup");
        });
    },
    googleAccountConfigs: {
      client_id: config?.googleOneTapClientId,
      callback: async (res) => {
        var googletoken = res.credential;
        var response = jwt_decode(googletoken);
        const email = response.email;
        const displayName = response.name;
        const saltfromEnv = process.env.GOOGLE_ONE_TAP_CUSTOM_SALT;
        const password = await sha256(response.email + saltfromEnv);
        const imageUrl = response.picture;

        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then((result) => {
            let firebaseUser = JSON.parse(JSON.stringify(result.user));
            firebaseUser.photoURL = imageUrl;
            firebaseUser.displayName = displayName;
            firebaseLoginHelper(firebaseUser, dispatch);
          })
          .catch(async (e) => {
            await Api.post("googleOneTapSignIn", { googletoken }).then(
              (res) => {
                console.log(res, " is the res");
                const data = res?.data;
                const user = data?.user;
                const firebaseToken = data?.firebaseToken;
                const uid = user?.uid;
                const activeUserId = user?._id;
                const activeUserProfileId = user?.profile;

                localStorage.setItem("token", firebaseToken);
                localStorage.setItem("activeUserId", activeUserId);
                localStorage.setItem(
                  "activeUserProfileId",
                  activeUserProfileId
                );
                localStorage.setItem("loggedInFirebaseUId", uid);
                const userId = user._id;
                const personDictionary = {};
                personDictionary[userId] = user;
                dispatch({
                  type: "SET_AUTH_USER",
                  user,
                });
              }
            );
          });
      },
    },
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
      once: false,
    });
  }, []);

  return (
    <div>
      <div className={classes.topBar}>
        <TopBar positionRef={positionRef} />
      </div>

      <div className={classes.bodyCont}>
        <HomePage pageRef={homePageRef} />
        <Advantage pageRef={advantageRef} />
        <Investors pageRef={investorsRef} />
        <Goal pageRef={goalRef} />
        <Dev pageRef={devRef} />
        <Review pageRef={reviewRef} />
        <Footer pageRef={footerRef} positionRef={positionRef} />
      </div>
    </div>
  );
}
