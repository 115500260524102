import React, { useEffect, useState } from "react";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CreateOffering from "../../finance2o/productsAndServices/create.offering.only";
import { useDebounce } from "react-use";
import { Delete } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "../../styled/generic/Autocomplete";
import TextField from "../../styled/generic/TextField";
import Select from "../../styled/generic/Select";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import StockTableContainer from "../../styled/generic/StockTableContainer";
import OfferingCreateDrawer from "../../OfferingsAndProducts/Offering.Create.Drawer";

const StocksMoveConfigDrawer = ({
  open,
  setOpen,
  selectedWarehouse,
  warehouses,
  stockMoveMode,
  setShouldRefreshStocks,
  defaultFrom,
  defaultTo,
  title,
  selectedOrganization,
  mainEntity, //project or org
  mainEntityProfileId, // warehouse.project.profile or warehouse.org.profile
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [productText, setProductText] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [offeringCreateOpen, setOfferingCreateOpen] = useState(false);
  const [offeringCreateLoading, setOfferingCreateLoading] = useState(false);
  const [generateGRN, setGenerateGRN] = useState(false);

  const [tx, setTx] = useState({ type: "Bill" });
  const statusOptions = ["INBOUND", "ONSITE", "USED", "RETURN", "WASTED"];
  const [fromStatusOptions, setFromStatusOptions] = useState([
    "INBOUND",
    "ONSITE",
    "USED",
    "RETURN",
    "WASTED",
  ]);
  const [toStatusOptions, setToStatusOptions] = useState([
    "INBOUND",
    "ONSITE",
    "USED",
    "RETURN",
    "WASTED",
  ]);

  const [stocks, setStocks] = useState([]); // They are stock moves

  useEffect(() => {
    if (open) {
      setProductText("");
      setGenerateGRN(false);
      setStocks([
        {
          product: null,
          quantity: 0,
          rate: 0,
          fromType: "INBOUND",
          toType: "INBOUND",
          fromWarehouse:
            stockMoveMode === "CREATE_NEW" || stockMoveMode === "CHANGE_STATUS"
              ? null
              : selectedWarehouse,
          toWarehouse:
            stockMoveMode === "CREATE_NEW" || stockMoveMode === "CHANGE_STATUS"
              ? selectedWarehouse
              : null,
        },
      ]);
    }
  }, [open]);

  const searchProducts = async () => {
    try {
      setLoadingProducts(true);
      const { data } = await Api.post("/wallet/offering/search-item", {
        profileId: mainEntityProfileId,
        searchStr: productText,
      });
      if (data) {
        setProducts(data);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleProductSearch = async () => {
    if (productText && productText?.length >= 3) {
      await searchProducts();
    }
  };

  useDebounce(
    () => {
      handleProductSearch();
    },
    500,
    [productText]
  );

  const addNewStockMove = async () => {
    setStocks([
      ...stocks,
      {
        product: null,
        quantity: 0,
        rate: 0,
        fromType: "INBOUND",
        toType: "INBOUND",
        fromWarehouse:
          stockMoveMode === "CREATE_NEW" || stockMoveMode === "CHANGE_STATUS"
            ? null
            : selectedWarehouse,
        toWarehouse:
          stockMoveMode === "CREATE_NEW" || stockMoveMode === "CHANGE_STATUS"
            ? selectedWarehouse
            : null,
      },
    ]);
  };

  const createStockMoves = async () => {
    if (stocks?.length <= 0) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please add atleast one stock move",
        },
      });
      return;
    }
    try {
      setLoading(true);
      let stockList = [];
      let shouldContinue = true;

      // stocks.forEach((item, index) => {
      //   if (
      //     item?.product?._id &&
      //     item?.product?._id &&
      //     item?.quantity &&
      //     item?.cost
      //   ) {
      //     stockList.push({
      //       product: item?.product?._id,
      //       warehouse: selectedWarehouse?._id,
      //       quantity: item?.quantity,
      //       cost: item?.cost,
      //     });
      //   } else {
      //     shouldContinue = false;
      //     dispatch({
      //       type: "AddApiAlert",
      //       payload: {
      //         success: false,
      //         message: `Please fill all fields for stock at position ${
      //           index + 1
      //         }`,
      //       },
      //     });
      //     return;
      //   }
      // });

      // if (!shouldContinue) {
      //   return; // Exit function
      // }

      const { results } = await Api.post("/inventory/handleStockMoves", {
        stockMoveList: stocks,
        mode: stockMoveMode,
        project: selectedWarehouse?.project,
        organization: selectedWarehouse?.organization,
        user: user?._id,
        generateGRN: generateGRN,
      });
      if (results) {
        const { success, data, message } = results;
        if (success) {
          setShouldRefreshStocks(true);
          setOpen(false);
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: message,
            },
          });
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: message,
            },
          });
        }
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create stocks",
          },
        });
      }
    } catch (err) {
      console.log("Error: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create stocks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGRNSelection = (chk) => {
    if (chk) {
      setGenerateGRN(true);
      let updatedStocks = [...stocks];
      updatedStocks.forEach((item) => {
        item.toType = "ONSITE";
        if (item.fromType === "ONSITE") {
          item.fromType = null;
        }
      });
      setStocks(updatedStocks);
      setToStatusOptions(["ONSITE"]);
    } else {
      setGenerateGRN(false);
      setToStatusOptions(statusOptions);
    }
  };

  useEffect(() => {
    console.log("Defaulttt: ", defaultFrom, defaultTo);
    if (defaultFrom) {
      setFromStatusOptions([defaultFrom]);
    } else {
      setFromStatusOptions(statusOptions);
    }
    if (defaultTo) {
      setToStatusOptions([defaultTo]);
    } else {
      setToStatusOptions(statusOptions);
    }
  }, [defaultFrom, defaultTo]);

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    switch (stockMoveMode) {
      case "CREATE_NEW":
        setColumns([
          "Product",
          "Rate",
          "Quantity",
          "To Warehouse",
          "To Status",
          "Actions",
        ]);
        break;

      case "CHANGE_STATUS":
        setColumns([
          "Product",
          "Rate",
          "Quantity",
          "To Warehouse",
          "From Status",
          "To Status",
        ]);
        break;

      case "TRANSFER_WAREHOUSE":
        setColumns([
          "Product",
          "Rate",
          "Quantity",
          "From Warehouse",
          "To Warehouse",
          "From Status",
          "To Status",
        ]);
        break;

      default:
        setColumns([]);
    }
  }, [stockMoveMode]);

  return (
    <DrawerContainer width="80vw" open={open} setOpen={setOpen} title={title}>
      <StockTableContainer columns={columns}>
        {stocks?.map((stock, index) => {
          return (
            <tr>
              <td>
                <Autocomplete
                  value={stock?.product}
                  onChange={(event, newValue) => {
                    console.log("Onchange: ", newValue);
                    let newStockAry = [...stocks];
                    newStockAry[index].product = newValue;
                    setStocks(newStockAry);
                  }}
                  options={products}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      placeholder="Search"
                      value={stock?.product?.name || productText}
                      onChange={(e) => {
                        setProductText(e.target.value);
                      }}
                    />
                  )}
                  loading={loadingProducts}
                  getOptionLabel={(option) => option?.name}
                  componentsProps={{
                    paper: {
                      sx: {
                        border: "1px solid lightgray",
                        marginLeft: 1,
                        width: 484,
                      },
                    },
                  }}
                  noOptionsText={
                    <Button
                      onClick={() => {
                        console.log("Clicked: ", tx);
                        setOfferingCreateOpen(true);
                      }}
                      size="small"
                      variant="text"
                      autoCapitalize="false"
                    >
                      Create New?
                    </Button>
                  }
                />
                <OfferingCreateDrawer
                  purchase={false}
                  selling={true}
                  wallet={mainEntity?.wallet}
                  library={mainEntity?.wallet?.defaultLibrary}
                  allCategories={[]}
                  profileId={mainEntityProfileId}
                  createOfferingDawer={offeringCreateOpen}
                  setCreateOfferingDrawer={setOfferingCreateOpen}
                  incomeChartAccounts={[]}
                  setIncomeChartAccounts={() => {}}
                  expenseChartAccounts={[]}
                  setExpenseChartAccounts={() => {}}
                  afterOfferingCreateOrEdit={(offeringRelation, isEdit) => {
                    setSelectedProduct(offeringRelation);
                    console.log("New offeringRelation:", offeringRelation);
                  }}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  placeholder="Rate"
                  value={stock?.rate}
                  fullWidth
                  onChange={(e) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].rate = e.target.value;
                    setStocks(newStockAry);
                  }}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  placeholder="Quantity"
                  value={stock?.quantity}
                  fullWidth
                  onChange={(e) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].quantity = e.target.value;
                    setStocks(newStockAry);
                  }}
                />
              </td>
              {stockMoveMode === "TRANSFER_WAREHOUSE" && (
                <td>
                  <Select
                    fullWidth
                    value={stock?.fromWarehouse ? stock?.fromWarehouse._id : ""}
                    onChange={(evt) => {
                      let val = warehouses.find(
                        (obj) => obj._id === evt.target.value
                      );
                      let newStockAry = [...stocks];
                      newStockAry[index].fromWarehouse = val;
                      setStocks(newStockAry);
                    }}
                  >
                    {warehouses?.length > 0 &&
                      warehouses.map((obj) => (
                        <MenuItem key={obj._id} value={obj._id}>
                          {obj?.name}{" "}
                          {obj?.belongsToOrg
                            ? "(ORG)"
                            : obj?.project?.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </td>
              )}
              <td>
                <Select
                  fullWidth
                  value={stock?.toWarehouse ? stock?.toWarehouse._id : ""}
                  onChange={(evt) => {
                    let val = warehouses.find(
                      (obj) => obj._id === evt.target.value
                    );
                    let newStockAry = [...stocks];
                    newStockAry[index].toWarehouse = val;
                    setStocks(newStockAry);
                  }}
                >
                  {warehouses?.length > 0 &&
                    warehouses.map((obj) => (
                      <MenuItem key={obj._id} value={obj._id}>
                        {obj?.name}{" "}
                        {obj?.belongsToOrg
                          ? "(ORG)"
                          : obj?.project?.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </td>
              {(stockMoveMode === "CHANGE_STATUS" ||
                stockMoveMode === "TRANSFER_WAREHOUSE") && (
                <td>
                  <Select
                    fullWidth
                    value={stock?.fromType}
                    onChange={(evt) => {
                      let newStockAry = [...stocks];
                      newStockAry[index].fromType = evt.target.value;
                      setStocks(newStockAry);
                    }}
                  >
                    {fromStatusOptions.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              )}
              <td>
                <Select
                  fullWidth
                  value={stock?.toType}
                  onChange={(evt) => {
                    let newStockAry = [...stocks];
                    newStockAry[index].toType = evt.target.value;
                    setStocks(newStockAry);
                  }}
                >
                  {toStatusOptions.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              <td>
                <IconButton
                  aria-placeholder="delete"
                  size="small"
                  onClick={() => {
                    let newStockAry = [...stocks];
                    newStockAry.splice(index, 1);
                    setStocks(newStockAry);
                  }}
                >
                  <Delete />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </StockTableContainer>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ marginTop: "10px" }}
      >
        <Button
          variant="outlined"
          onClick={() => addNewStockMove()}
          style={{ marginRight: "5px" }}
        >
          Add New
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => createStockMoves()}
          style={{ marginLeft: "5px" }}
          loading={loading}
        >
          Create
        </LoadingButton>
      </Box>

      {/* <Box>
        {defaultFrom === "ONSITE" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={generateGRN}
                onChange={(e, chk) => handleGRNSelection(chk)}
              />
            }
            placeholder="Generate GRN?"
            style={{ margin: "10px 0px" }}
          />
        )}
        <Box sx={{ minHeight: "50vh" }}>
          {stocks?.map((stock, index) => {
            return (
              <Box key={index} sx={{ marginBottom: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Autocomplete
                      value={stock?.product}
                      onChange={(event, newValue) => {
                        console.log("Onchange: ", newValue);
                        let newStockAry = [...stocks];
                        newStockAry[index].product = newValue;
                        setStocks(newStockAry);
                      }}
                      options={products}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="text"
                          placeholder="Enter product title"
                          value={stock?.product?.name || productText}
                          onChange={(e) => {
                            setProductText(e.target.value);
                          }}
                        />
                      )}
                      loading={loadingProducts}
                      getOptionLabel={(option) => option?.name}
                      componentsProps={{
                        paper: {
                          sx: {
                            border: "1px solid lightgray",
                            marginLeft: 1,
                            width: 484,
                          },
                        },
                      }}
                      noOptionsText={
                        <Button
                          onClick={() => {
                            console.log("Clicked: ", tx);
                            setOfferingCreateOpen(true);
                          }}
                          size="small"
                          variant="text"
                          autoCapitalize="false"
                        >
                          Create New?
                        </Button>
                      }
                    />
                    <NormalDialog
                      openDialog={offeringCreateOpen}
                      handleCloseShare={() => {
                        setOfferingCreateOpen(false);
                      }}
                      pageTitle={"Add New"}
                      content={
                        <div>
                          <CreateOffering
                            text={productText || ""}
                            setOpen={setOfferingCreateOpen}
                            setLoading={setOfferingCreateLoading}
                            onSelect={(data) => {
                              setSelectedProduct(data);
                              if (stocks?.length > 0) {
                                stocks[stocks.length - 1].product = data;
                              }
                              console.log("Selected product: ", data);
                            }}
                            tx={tx}
                            orgId={selectedWarehouse?.organization}
                          />
                          <div style={{ width: "100%", height: "4px" }}>
                            {offeringCreateLoading && <LinearProgress />}
                          </div>
                        </div>
                      }
                    />
                  </Grid>
                  {stockMoveMode === "CREATE_NEW" && (
                    <Grid item xs>
                      <TextField
                        type="number"
                        placeholder="Rate"
                        value={stock?.rate}
                        fullWidth
                        onChange={(e) => {
                          let newStockAry = [...stocks];
                          newStockAry[index].rate = e.target.value;
                          setStocks(newStockAry);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs>
                    <TextField
                      type="number"
                      placeholder="Quantity"
                      value={stock?.quantity}
                      fullWidth
                      onChange={(e) => {
                        let newStockAry = [...stocks];
                        newStockAry[index].quantity = e.target.value;
                        setStocks(newStockAry);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  {stockMoveMode === "TRANSFER_WAREHOUSE" && (
                    <Grid item xs>
                      <Select
                        fullWidth
                        value={
                          stock?.fromWarehouse ? stock?.fromWarehouse._id : ""
                        }
                        onChange={(evt) => {
                          let val = warehouses.find(
                            (obj) => obj._id === evt.target.value
                          );
                          let newStockAry = [...stocks];
                          newStockAry[index].fromWarehouse = val;
                          setStocks(newStockAry);
                        }}
                        label={"From Warehouse"}
                      >
                        {warehouses?.length > 0 &&
                          warehouses.map((obj) => (
                            <MenuItem key={obj._id} value={obj._id}>
                              {obj?.name}{" "}
                              {obj?.belongsToOrg
                                ? "(ORG)"
                                : obj?.project?.displayName}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  )}

                  <Grid item xs>
                    <Select
                      fullWidth
                      value={stock?.toWarehouse ? stock?.toWarehouse._id : ""}
                      onChange={(evt) => {
                        let val = warehouses.find(
                          (obj) => obj._id === evt.target.value
                        );
                        let newStockAry = [...stocks];
                        newStockAry[index].toWarehouse = val;
                        setStocks(newStockAry);
                      }}
                      label={"To Warehouse"}
                    >
                      {warehouses?.length > 0 &&
                        warehouses.map((obj) => (
                          <MenuItem key={obj._id} value={obj._id}>
                            {obj?.name}{" "}
                            {obj?.belongsToOrg
                              ? "(ORG)"
                              : obj?.project?.displayName}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>

                  {(stockMoveMode === "CHANGE_STATUS" ||
                    stockMoveMode === "TRANSFER_WAREHOUSE") && (
                    <Grid item xs>
                      <Select
                        fullWidth
                        value={stock?.fromType ? stock?.fromType : ""}
                        onChange={(evt) => {
                          let newStockAry = [...stocks];
                          newStockAry[index].fromType = evt.target.value;
                          setStocks(newStockAry);
                        }}
                        label={"From Status"}
                      >
                        {fromStatusOptions.map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}

                  <Grid item xs>
                    <Select
                      fullWidth
                      value={stock?.toType ? stock?.toType : ""}
                      onChange={(evt) => {
                        let newStockAry = [...stocks];
                        newStockAry[index].toType = evt.target.value;
                        setStocks(newStockAry);
                      }}
                      label={"To Status"}
                    >
                      {toStatusOptions.map((item, i) => (
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-placeholder="delete"
                      size="small"
                      onClick={() => {
                        let newStockAry = [...stocks];
                        newStockAry.splice(index, 1);
                        setStocks(newStockAry);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          style={{ marginTop: "10px" }}
        >
          <Button
            variant="outlined"
            onClick={() => addNewStockMove()}
            style={{ marginRight: "5px" }}
          >
            Add New
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => createStockMoves()}
            style={{ marginLeft: "5px" }}
            loading={loading}
          >
            Create
          </LoadingButton>
        </Box>
      </Box> */}
    </DrawerContainer>
  );
};

export default StocksMoveConfigDrawer;
