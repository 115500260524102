import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Button, useMediaQuery } from "@material-ui/core";
import cabin from "../../../Assets/cabin.svg";
import apartment from "../../../Assets/apartment.svg";
import avg_pace from "../../../Assets/avg_pace.svg";
import monitoring from "../../../Assets/monitoring.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        padding: "0px 180px 50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            padding: "0px 120px 50px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0px 20px 50px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0px 35px 50px",
            height: "auto",
            marginBottom: "100px"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 25px 50px"
        },
    },
    mainCont: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    iconsCont: {
        width: "25vw",
        [theme.breakpoints.down("md")]: {
            width: "35vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "400px",
            marginBottom: "50px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "50px"
        },
    },
    twoCardCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "calc( 25vw - 290px )",
        [theme.breakpoints.down("md")]: {
            marginBottom: "calc( 35vw - 290px )",
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "80px",
        },
    },
    textContent: {
        width: "41%",
        marginLeft: "100px",
        "& h3": {
            fontSize: "35px",
            marginBottom: "30px",
        },
        "& p": {
            fontSize: "24px",
            fontWeight: "490"
        },
        "& .MuiButton-root": {
            marginTop: "50px",
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "20px"
        },
        [theme.breakpoints.down("sm")]: {
            width: "80%",
            marginLeft: "0px",
            "& h3": {
                fontSize: "32px",
            },
            "& p": {
                fontSize: "20px",
            },
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            "& h3": {
                fontSize: "30px",
            },
            "& p": {
                fontSize: "16px",
            },
            "& .MuiButton-label": {
                fontSize: "14px"
            },
        }
    },
    imageTextCont: {
        width: "145px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        "& img": {
            width: "100px"
        },
        "& h4": {
            fontSize: "17px",
            textAlign: "center",
        },
        [theme.breakpoints.down("xs")]: {
            width: "120px",
            "& img": {
                width: "70px"
            },
            "& h4": {
                fontSize: "15px",
            },
        }
    },
}));

export default function Investors({ pageRef }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();


    return (
        <section ref={pageRef} className={classes.root} >
            <div className={classes.mainCont} >
                <div className={classes.iconsCont} data-aos="fade-right" >
                    <div className={classes.twoCardCont} >
                        <div className={classes.imageTextCont} >
                            <img src={cabin} />
                            <h4>Small apartment projects</h4>
                        </div>

                        <div className={classes.imageTextCont} >
                            <img src={apartment} />
                            <h4>Seasoned Developers</h4>
                        </div>
                    </div>

                    <div className={classes.twoCardCont} style={{ marginBottom: "0px" }} >
                        <div className={classes.imageTextCont} >
                            <img src={avg_pace} />
                            <h4>Quick turnaround time</h4>
                        </div>

                        <div className={classes.imageTextCont} >
                            <img src={monitoring} />
                            <h4>Project monitoring</h4>
                        </div>
                    </div>
                </div>

                <div className={classes.textContent} data-aos="fade-left" >
                    <h3>RealList Advantage for <span style={{ fontSize: "42px", color: theme.palette.primary.main, borderBottom: `4px solid ${theme.palette.primary.main}` }} >Investors</span></h3>
                    <p> Trust and transparency are at the forefront of our mission, and we exclusively partner with honest developers. Let us help you achieve your real estate investment goals.</p>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const data = { type: "signup" }
                            const searchParams = new URLSearchParams(data)
                            const url = "signup/?" + searchParams;
                            history.push(url)
                        }}
                    >
                        Join our network now
                    </Button>
                </div>
            </div>
        </section>
    );
}