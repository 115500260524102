import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Autocomplete,
  InputLabel,
  ListItemButton,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
  TextField,
  Checkbox,
  Tooltip,
  Avatar,
  ListItemText,
  ListItem,
  ListItemAvatar,
  FormControlLabel,
} from "@mui/material";

import { useDebounce } from "react-use";
import { searchUsers } from "../../contacts/api.call";
import Api from "../../../helpers/Api";
import moment from "moment";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import PalFormDrawer from "../../AddMember/newPalFormDrawer";

const WorkScheduleConfigModal = ({ open, setOpen }) => {
  const [entities, setEntities] = useState([]);
  const [clockPeriods, setClockPeriods] = useState([]);
  let dayArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [days, setDays] = useState(["Mon", "Tue"]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [text, setText] = useState("");
  const [reminderPeriodValue, setReminderPeriodValue] = useState(5);

  const { teamId } = useParams();

  const handleDays = (event, newDays) => {
    setDays(newDays);
  };

  const addPeriod = () => {
    const today = moment();
    const start = today
      .clone()
      .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
      .toDate();
    const end = today
      .clone()
      .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
      .toDate();

    const newPeriod = {
      id: new Date().toISOString(),
      start: start,
      end: end,
    };

    setClockPeriods([...clockPeriods, newPeriod]);
  };

  const deletePeriod = (periodId) => {
    setClockPeriods(clockPeriods.filter((item) => item.id !== periodId));
  };

  const updatePeriod = (periodId, start, end) => {
    let updatedPeriod = {};

    if (start !== null && end === null) {
      updatedPeriod = { start: start };
    }
    if (start === null && end !== null) {
      updatedPeriod = { end: end };
    }
    if (start !== null && end !== null) {
      updatedPeriod = { start: start, end: end };
    }

    const updatedClockPeriods = clockPeriods.map((item) =>
      item.id === periodId ? { ...item, ...updatedPeriod } : item
    );
    setClockPeriods(updatedClockPeriods);
  };

  const sendQuery = async () => {
    await searchUsers({ name: text })
      .then(({ data }) => {
        console.log("Entities agyi: ", data);

        // If you select a user in autocomplete and then change search text,
        // New options will be loaded and if they contain old user in response,
        // Old user will not be selected by default
        // This means client can add same user twice when creating work schedule
        // To prevent this, perform a filter operation when fetching users

        setEntities(
          data.filter(
            (userA) =>
              !selectedEntities.some((userB) => userA._id === userB._id)
          ) || []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(
    () => {
      if (text && text.length > 2) {
        sendQuery();
      } else if (text && text.length === 0) {
        setEntities([]);
      }
    },
    1200,
    [text]
  );

  const dispatch = useDispatch();

  const daySort = (a, b) => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
  };

  const [creating, setCreating] = useState(false);

  const createSchedule = async () => {
    try {
      setCreating(true);
      let parsedPeriods = clockPeriods.map((item) => {
        return {
          startTime: String(item.start),
          finishTime: String(item.end),
        };
      });

      // In backend, to calculate nextDate of scheduler, days of week should be in order Eg. Mon,Tue....
      // But if a user first checks Sunday toggle button and then monday, then friday and so on, order becomes wrong
      // So we need to convert week days in order
      let orderedDays = days.sort(daySort);

      const data = await Api.post("/workschedule/create", {
        daysOfWeek: orderedDays,
        workers: selectedEntities,
        organizationTeamId: teamId,
        projectId: selectedProject?._id,
        periods: parsedPeriods,
        notificationTime: reminderPeriodValue,
        userId: user?._id,
      });

      if (!data) {
        throw new Error("Unable to create work schedule");
      }

      //console.log("Dataaa: ", data);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Work schedule created successfully",
        },
      });
      setOpen(false);
    } catch (err) {
      console.log("Err: ", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create work schedule",
        },
      });
    } finally {
      setCreating(false);
    }
  };

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const getProjects = async () => {
    const { data } = await Api.post("/projects/by-org-team", {
      organizationTeamId: teamId,
    });
    let projectResults = data?.projects;
    if (projectResults?.length > 0) {
      setProjects(projectResults);
    } else {
      // dispatch({
      //   type: "AddApiAlert",
      //   payload: {
      //     success: false,
      //     message: "This project is not associated with any organization",
      //   },
      // });
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Work Schedule</DialogTitle>
      <DialogContent dividers={true}>
        <Box
          style={{
            bgcolor: "background.paper",
            width: "100%",
            height: "60vh",
          }}
        >
          <Box style={{ padding: "0px 0px 10px 0px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Project</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={selectedProject}
                onChange={(evt) => setSelectedProject(evt.target.value)}
                variant="outlined"
                fullWidth
                label="Project"
              >
                <MenuItem value={null}>None</MenuItem>
                {projects?.map((project) => (
                  <MenuItem key={project?._id} value={project}>
                    {project?.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box style={{ padding: "0px 0px 10px 0px" }}>
            <Autocomplete
              multiple
              options={entities}
              defaultValue={selectedEntities}
              onChange={(evt, newEntities) => setSelectedEntities(newEntities)}
              disableCloseOnSelect
              getOptionLabel={(option) => option?.displayName}
              renderOption={(props, option, { selected }) => {
                return (
                  <ListItemButton {...props} selected={selected}>
                    <ListItemAvatar>
                      <Avatar src={option?.displayPicture?.thumbUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={option?.displayName}
                      secondary={option?.email}
                    />
                  </ListItemButton>
                );
              }}
              // renderOption={(props, option, { selected }) => (
              //   <li {...props}>
              //     <Checkbox
              //       icon={icon}
              //       checkedIcon={checkedIcon}
              //       style={{ marginRight: 8 }}
              //       checked={selected}
              //       color="primary"
              //     />
              //     {option?.displayName}
              //   </li>
              // )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employees"
                  placeholder="Select employees"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  variant="outlined"
                />
              )}
            />
          </Box>

          <ToggleButtonGroup
            value={days}
            onChange={handleDays}
            color="primary"
            fullWidth
          >
            {dayArr.map((day, i) => (
              <ToggleButton value={day} key={i + day}>
                <Typography fontSize="0.9rem">{day}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: "10px" }}
          >
            <Typography variant="h6">Periods</Typography>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => addPeriod()}
            >
              New +
            </Button>
          </Box>
          {clockPeriods.length > 0 ? (
            clockPeriods.map((item, idx) => (
              <Box
                display="flex"
                flexDirection="column"
                style={{ marginTop: "15px" }}
                key={String(item.id) + idx}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography fontSize="0.9rem">
                    Send clock remainder:
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => deletePeriod(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box display="flex" style={{ marginTop: "15px" }}>
                    <TimePicker
                      label="From"
                      value={item.start}
                      onChange={(val) => updatePeriod(item.id, val, null)}
                      slotProps={{
                        textField: {
                          size: "small",
                          style: { marginRight: "5px" },
                        },
                      }}
                    />
                    <TimePicker
                      label="To"
                      value={item.end}
                      onChange={(val) => updatePeriod(item.id, null, val)}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            ))
          ) : (
            <Box
              style={{ padding: "20px 0px" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              No periods to show
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "15px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Reminder Period</Typography>
              <Tooltip
                arrow
                title="Send a notification reminder to employee some minutes before clockout period"
              >
                <IconButton color="primary" aria-label="delete" size="small">
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box display="flex" style={{ marginTop: "15px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                fullWidth
                onChange={(e) => setReminderPeriodValue(e.target.value)}
                defaultValue={5}
              >
                <MenuItem value={5}>05 minutes</MenuItem>
                <MenuItem value={10}>10 minutes</MenuItem>
                <MenuItem value={15}>15 minutes</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="primary"
          color="primary"
          loading={creating}
          onClick={() => createSchedule()}
        >
          <span>Create</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default WorkScheduleConfigModal;
