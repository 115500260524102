const initialState = {
    teamAccessMap: {},
    accessAbleTeams : [],
    accessAbleProjectTeams : [],
    accessAbleOrganizationTeams : [],
    accessAbleProfiles : [],
    accessAbleProjectProfiles : [],
    accessAbleOrganizationProfiles : [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'AddAccessControl':
            return {
                ...state,
                ...action.payload,
            };

        case 'AddAccessControlReset':
            return initialState;

        default:
            return state;
    }
};