import React from "react";
import { getColorByLabel } from "../../../helpers/colorHelper";

const StatusChip = ({ label, variant, styleProps }) => {
  const { statusTextColor, statusBackgroundColor } = getColorByLabel(variant);

  return (
    <span
      style={{
        color: statusTextColor,
        backgroundColor: statusBackgroundColor,
        padding: "5px 15px",
        borderRadius: "2px",
        fontWeight: "normal",
        ...styleProps
      }}
    >
      {label}
    </span>
  );
};

export default StatusChip;
