import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import MuiDrawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../Navbar/NavBar";
import { drawerWidthTheme } from "../../utils/theme.js";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import firebase from "firebase";

const drawerWidth = drawerWidthTheme?.width;
const foldedWidth = drawerWidthTheme?.smWidth;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    flexGrow: 0,
    [theme.breakpoints.down("sm")]: {
      width: foldedWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: foldedWidth,
      position: "relative",
      height: "100vh",
      zIndex: "1301",
    },
    borderRight: "1px solid #F5F7FA",
  },
  logoImg: {
    width: "2.4rem",
    height: "auto",
    marginTop: "20px",
    marginBottom: "50px",
    cursor: "pointer",
  },
  bottomProfile: {
    width: "7rem",
    position: "absolute",
    bottom: "10px",
    left: "0px",
    padding: "0px 10px",
  },
  innerBottomCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #c8c6c6",
    paddingTop: "10px",
  },
  logOutBtnCont: {
    position: "fixed",
    bottom: "25px",
    left: "7.2rem",
    zIndex: "9999",
    backgroundColor: "rgba(0,0,0,0)",
  },
  textIconCont: {
    backgroundColor: "white",
    position: "relative",
    width: "0px",
    borderRadius: "7px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  openTextIconCont: {
    backgroundColor: "white",
    position: "relative",
    width: "150px",
    borderRadius: "7px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    overflow: "hidden",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },
  onlyTextIconCont: {
    width: "150px",
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      marginLeft: "6px",
      fontSize: "17px",
      fontWeight: "510",
    },
  },
}));

export default function Drawer() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector((state) => state.auth);

  const [openSignOut, setOpenSignOut] = useState(false);

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({
          type: "TeamReset",
        });
        dispatch({
          type: "FeedReset",
        });
        dispatch({
          type: "FileReset",
        });
        dispatch({
          type: "IssueReset",
        });

        dispatch({
          type: "WalletReset",
        });
        dispatch({
          type: "PipelineReset",
        });
        dispatch({
          type: "DashboardReset",
        });
        dispatch({
          type: "CommentReset",
        });
        dispatch({
          type: "ProjectReset",
        });
        dispatch({
          type: "ChatReset",
        });
        dispatch({
          type: "REMOVE_AUTH_USER",
        });
        localStorage.removeItem("token");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <MuiDrawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
        docked: null,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          alt="Logo"
          className={classes.logoImg}
          onClick={() => {
            history.push("/");
          }}
          src={require("../../Assets/RealListLogo.svg")}
        />
      </Grid>

      <Navbar />

      <ClickAwayListener
        onClickAway={() => {
          setOpenSignOut(false);
        }}
      >
        <div className={classes.bottomProfile}>
          <div className={classes.innerBottomCont}>
            <IconButton
              onClick={() => {
                setOpenSignOut(!openSignOut);
              }}
            >
              <Avatar src={user?.displayPicture?.url} />
            </IconButton>
          </div>
          <div className={classes.logOutBtnCont}>
            <div
              className={
                openSignOut ? classes.openTextIconCont : classes.textIconCont
              }
            >
              <div className={classes.onlyTextIconCont} onClick={logout}>
                <PowerSettingsNewIcon style={{ fontSize: "19px" }} />
                <p>Sign out</p>
              </div>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </MuiDrawer>
  );
}
