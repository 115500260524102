import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import AppBar from "./AppBar";
import { BiLeftArrow } from "react-icons/bi";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StandardContainer = ({
  children,
  sx,
  showAppBar = false,
  appBarTitle = "",
  hideAppBarBackButton = false,
  onAppBarBackButtonClick,
  appBarActions,
  ...props
}) => {
  const theme = useTheme();
  const APP_BAR_HEIGHT = "60px";
  const history = useHistory();
  return (
    <>
      {showAppBar && (
        <AppBar
          sx={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: APP_BAR_HEIGHT,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!hideAppBarBackButton && (
              <Box
                sx={{
                  width: "70px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (onAppBarBackButtonClick) {
                      onAppBarBackButtonClick();
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <ArrowBackIosNewOutlined />
                </IconButton>
              </Box>
            )}
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {appBarTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              pr: "120px",
            }}
          >
            {appBarActions}
          </Box>
        </AppBar>
      )}
      <Box
        sx={{
          p: 2,
          backgroundColor: theme.palette.background.default,
          minHeight: "100vh",
          mt: showAppBar ? APP_BAR_HEIGHT : 0,
          ...sx,
        }}
        {...props}
      >
        {children}
      </Box>
    </>
  );
};

export default StandardContainer;
