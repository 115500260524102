import { Delete, MoreVert } from "@material-ui/icons";
import { Box } from "@mui/material";
import moment from "moment";
import React from "react";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PopupMenuList from "../../styled/generic/PopupMenuList";
import MoreButton from "../../styled/generic/MoreButton";
import TableContainer from "../../styled/generic/TableContainer";
import StatusChip from "../../styled/generic/StatusChip";

const useStyles = makeStyles((theme) => ({}));

const InventoryStockMoves = ({
  stockMoves,
  setShouldRefreshStocks,
  setShouldRefreshStockMoves,
  loading
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const deleteStockMove = async (id) => {
    try {
      const { results } = await Api.post("/inventory/deleteStockMove", {
        _id: id,
      });
      if (results) {
        setShouldRefreshStocks(true);
        setShouldRefreshStockMoves(true);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete stock move",
        },
      });
    }
  };

  return (
    <Box>
      <TableContainer
        columns={[
          "Name",
          "Total",
          "Quantity",
          "Rate",
          "Warehouse",
          "Status",
          "Move Date",
          "Actions",
        ]}
        data={stockMoves}
        loading={loading}
      >
        {stockMoves?.map((move, index) => (
          <tr>
            <td>{move?.product?.name}</td>
            <td>₹ {parseFloat(move?.rate * move?.quantity).toFixed(2)}</td>
            <td>{parseFloat(move?.quantity).toFixed(2)}</td>
            <td>₹ {parseFloat(move?.rate).toFixed(2)}</td>
            <td>{move?.toWarehouse?.name || "VENDOR"}</td>
            <td>
              <StatusChip
                label={move?.toType}
                variant={
                  ["INBOUND", "USED"].includes(move?.toType)
                    ? "Success"
                    : ["ONSITE"]?.includes(move?.toType)
                    ? "Info"
                    : ["OUTBOUND"]?.includes(move?.toType)
                    ? "Warning"
                    : "Error"
                }
              />
            </td>
            <td>
              {move?.moveDate
                ? moment(move.moveDate).format("DD MMM YYYY")
                : ""}
            </td>
            <td>
              <PopupMenuList
                appearContent={<MoreButton />}
                actions={[
                  {
                    title: "Delete",
                    action: () => deleteStockMove(move?._id),
                  },
                ]}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default InventoryStockMoves;
