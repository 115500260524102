const { default: Api } = require("../../helpers/Api");

export const getCommunityCategoryCount = async (obj) => {
    try {
        const res = await Api.post("community/get/category/count", obj);
        const result = res?.data;
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const getCommunityPostWithFilter = async (obj) => {
    try {
        const res = await Api.post("post/communities-posts/with-filter", obj);
        const result = res?.joinedCommunitiesPosts;
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const updateCommunityApi = async (obj) => {
    try {
        const res = await Api.post("community/update", obj);
        const result = res?.data;
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const getAllFiles = async (obj) => {
    try {
        const res = await Api.post("file/get", obj);
        const data = res?.result;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const joinOrLeaveCommunity = async (obj) => {
    try {
        const res = await Api.post("join/create", obj);
        const data = res;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getUserCommunities = async (obj) => {
    try {
        const res = await Api.post("community/get-joined", obj);
        const data = res?.communities;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getSuggestedCommunities = async (obj) => {
    try {
        const res = await Api.post("community/get-suggested", obj);
        const data = res?.communities;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const createCommunityApiHit = async (obj) => {
    try {
        const res = await Api.post("community/create", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getAllCommunitysPosts = async (obj) => {
    try {
        const res = await Api.post("post/get/for-user/community-feed", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};