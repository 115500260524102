import { Box } from "@mui/material";
import React from "react";

const TableActionsContainer = ({ left, right }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ my: 2 }}
    >
      <Box>{left}</Box>
      <Box>{right}</Box>
    </Box>
  );
};

export default TableActionsContainer;
