import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DocSvg from "../../../Assets/FileIcon/docIcon.svg";
import PdfIcon from "../../../Assets/FileIcon/pdfIcon.svg";
import XlsIcon from "../../../Assets/FileIcon/excelIcon.svg";
import PptIcon from "../../../Assets/FileIcon/pptIcon.svg";
import CsvIcon from "../../../Assets/FileIcon/csvIcon.svg";
import HtmlIcon from "../../../Assets/FileIcon/htmlIcon.svg";
import RtfIcon from "../../../Assets/FileIcon/rtfIcon.svg";
import TxtIcon from "../../../Assets/FileIcon/txtIcon.svg";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "0.5rem",
    width: "210px",
    height: "50px",
    padding: "0px 15px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "none",
    border: "1px solid #E7E7ED",
  },
  imgStyle: {
    width: "17px",
    height: "auto",
  },
  textRow: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    textWrap: "wrap",
    fontWeight: "600",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  deleteIcon: {
    position: "absolute",
    right: "0px",
    cursor: "pointer",
  },
});

const DocumentViewer = (props) => {
  const classes = useStyles();
  const { file, isDeletable, removeFun } = props;

  const arr = file?.name.split(".") || [];
  const fileExt = arr[arr.length - 1];
  let FileIcon = DocSvg;
  if (fileExt == "pdf") {
    FileIcon = PdfIcon;
  } else if (fileExt == "xls" || fileExt == "xlsx") {
    FileIcon = XlsIcon;
  } else if (fileExt == "ppt" || fileExt == "pptx") {
    FileIcon = PptIcon;
  } else if (fileExt == "plain") {
    FileIcon = TxtIcon;
  } else if (fileExt == "rtf") {
    FileIcon = RtfIcon;
  } else if (fileExt == "csv") {
    FileIcon = CsvIcon;
  } else if (fileExt == "html" || fileExt == "xhtml") {
    FileIcon = HtmlIcon;
  } else {
    FileIcon = DocSvg;
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const convertBase64 = async () => {
    let url = file?.url;
    let blob = await fetch(url).then((r) => r.blob());
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
    };
  };
  convertBase64();

  const showName = (str) => {
    let title = str.split(".")[0];
    if (title) {
      if (title.length > 25) {
        let dotName = title.slice(0, 22) + "...";

        return dotName;
      } else {
        return title;
      }
    } else {
      return "unknownFile";
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {isDeletable && (
        <CancelIcon
          className={classes.deleteIcon}
          onClick={() => {
            removeFun(file?._id);
          }}
        />
      )}
      <Paper
        className={classes.root}
        onClick={() => {
          if (file?.url) {
            openInNewTab(file.url);
          }
        }}
      >
        <img src={FileIcon} className={classes.imgStyle} />
        <Typography variant="body2" className={classes.textRow}>
          {showName(file?.name)}
        </Typography>
      </Paper>
    </div>
  );
};

export default DocumentViewer;
