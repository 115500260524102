import { KeyboardArrowDown } from "@material-ui/icons";
import { styled } from "@mui/material";
import MuiSelect from "@mui/material/Select";
import React from "react";

const StyledSelect = styled(MuiSelect)({
  borderRadius: "8px",
});

const Select = ({ children, ...props }) => {
  return (
    <StyledSelect {...props} IconComponent={KeyboardArrowDown}>
      {children}
    </StyledSelect>
  );
};

export default Select;
