import React from "react";
import ErrorImage from "../../Assets/404_current.svg";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function Error404() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img src={ErrorImage} style={{ height: "24rem", width: "35rem" }} />
			<Typography
				variant="h4"
				style={{ marginTop: "1rem", textAlign: "center" }}
			>
				Looking for something?
			</Typography>
			<Typography variant="h5" style={{ textAlign: "center" }}>
				We're sorry. The Web address you entered is not a functioning
				page on our site.
			</Typography>
			<Typography variant="subtitle1">
				<Link to="/">Go to RealList's Homepage</Link>
			</Typography>
		</div>
	);
}

export default Error404;
